// import React from 'react';
import { useState } from 'react';
import TopNavBar from '../../components/common/TopNavBar';
import MMBAHIS from '../../components/views/Basket/MMBAHIS';
import MMBAPRV from '../../components/views/Basket/MMBAPRV?ver=3';
import { MAP_DATA_EARLY } from './MapDataEarly';


const MMBAEAL = () => {
    const [content, setContent] = useState('certiPreview');

    const buttonValueSetting = e => {
      const { name } = e.target;
      setContent(name);
    };
  
    const selectComponent = {
      // certiHistory: <MMBAHIS /> ,
      certiPreview: <MMBAPRV />,

    };
    return (
        <div className ="customer-screen">
  <TopNavBar/>
  <MMBAPRV />
  </div>
);
};

export default MMBAEAL;