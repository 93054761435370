import React, { useCallback, useEffect, useState } from 'react';
import { IoIosCheckmark } from 'react-icons/io';


const MMBAKKO1 = ({basket, changeInfo}) => {
  const [deliveryInfo, setDeliveryInfo]  = useState({
    sendType      : 'K',
    paymentMethod : 'creditCard',
    receiver      : '',
    destination   : '',
    phone         : '',
    paymentAmount : "1,000"
  });

  // const handleDeliveryInfoChange = (event) => {
  //   const { name, value } = event.target;
  
  //   // 성함 입력 필드 처리 (한글과 영문만 허용)
  //   if (name === "receiver") {
  //     const regex = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣\s]*$/;
  //     if (!regex.test(value)) {
  //       // 특수문자나 숫자가 입력되었을 경우 처리 (팝업 메시지, 상태 업데이트 등)
  //       return;
  //     }
  //   }

  
  //   // 휴대폰 번호와 팩스 번호 처리 (숫자만 허용)
  //   if (name === "phone" || name === "destination") {
  //     const regex = /^[0-9]*$/;
  //     if (!regex.test(value)) {
  //       return;
  //     }
  //   }
  
  //   // 유효성 검사를 통과한 경우 해당 필드의 상태 업데이트
  //   setDeliveryInfo((deliveryInfo ) => ({
  //     ...deliveryInfo ,
  //     [name]: value,
  //   }));
  // };

  const handleDeliveryInfoChange = (event) => {
    const { name, value } = event.target;
    setDeliveryInfo({ ...deliveryInfo, [name]: value });
  };

  
  const receiverNumberRegex = /^[0-9]+$/;
    const phoneNumberRegex = /^[0-9]+$/;

  const isPhoneNumberValid = phoneNumberRegex.test(deliveryInfo.phone);
  const isRecValid = receiverNumberRegex.test(deliveryInfo.destination);

  const handlePaymentMethodChange = (method) => {
    setDeliveryInfo(deliveryInfo => ({
      ...deliveryInfo, paymentMethod : method
    }));
  }

  const handleEffect = useCallback(() => {
    changeInfo(deliveryInfo);
  }, [changeInfo, deliveryInfo]);

  useEffect(()=> {
    handleEffect();
  }, [handleEffect]);

  

  return (
    <div className="select-view">
    <div className="select-screen" id='no-margin3'>

      <div className="section-title">
          <h2 className='foam-title-s'>증명서 결제정보</h2>
      </div>
      <div className="info-box">
          <div className="info-row">
            <div className="info-label">증명서명</div>
            <div className="info-value">{basket.jobDisplayNe}</div>
          </div>
          <div className="info-row">
            <div className="info-label">통수</div>
            <div className="info-value">1 통</div>
          </div>
          <div className="info-row">
            <div className="info-label">총 금액</div>
            <div className="info-value">{deliveryInfo.paymentAmount} 원</div>
          </div>
          </div>
          <div className="section-title" id='flex-area'>
          <h2 className='foam-title-s' id='no-left-padding'>수신자 이름 입력</h2>
          <label htmlFor="input16" className='blind'>수신자 이름 입력 </label>

          <input 
          type="text" 
          id='input16'
          name="receiver" 
          onChange={handleDeliveryInfoChange} 
          className="dropdown-phone-input"
           placeholder='성함을 입력해주세요.'/>
      </div>
        <div className="section-title" id='flex-area'>
          <h2 className='foam-title-s' id='no-left-padding'>수신자 번호 입력</h2>
          <label htmlFor="input17" className='blind'> 수신자 번호 입력</label>

          <input 
          type="tel" 
          id='input17'
          name="destination" 
          onChange={handleDeliveryInfoChange} 
          className="dropdown-phone-input"
           placeholder='휴대폰 번호를 (-) 없이 입력해주세요.'/>
        {(!isRecValid && deliveryInfo.destination) && <p className="error-message">&nbsp; 숫자만 입력이 가능합니다.</p>}
      </div>
      <div className="section-title" id='flex-area'>
          <h2 className='foam-title-s' id='no-left-padding'>결제자 번호 입력</h2>
          <label htmlFor="input18" className='blind'>결제자 번호 입력 </label>

          <input type="tel" id='input18' name="phone" onChange={handleDeliveryInfoChange}  className="phone-input" placeholder='휴대폰 번호를 (-) 없이 입력해주세요.'/>
      </div>
      {(!isPhoneNumberValid && deliveryInfo.phone) && <p className="error-message">&nbsp; 숫자만 입력이 가능합니다.</p>}
      <p className='tip-text'  id='tip-txt'>* 본인 명의의 휴대폰만 가능합니다.</p>  

      <div className="section-title" id='flex-area'>
          <h2 className='foam-title-s'>결제방식</h2>
          <div className="select-btn-container">
          <button
            onClick={() => handlePaymentMethodChange('creditCard')}
            className={deliveryInfo.paymentMethod === 'creditCard' ? 'select-btn active' : 'select-btn'}
          >         
            <p className={deliveryInfo.paymentMethod === 'creditCard' ? 'select-round active' : 'select-round'}>

          {deliveryInfo.paymentMethod === 'creditCard' && <IoIosCheckmark />}
          </p>
          <p>신용카드</p></button>
          <button
            onClick={() => handlePaymentMethodChange('phoneNum')}
            className={deliveryInfo.paymentMethod === 'phoneNum' ? 'select-btn active' : 'select-btn'}
          >  <p className={deliveryInfo.paymentMethod === 'phoneNum' ? 'select-round active' : 'select-round'}>
          {deliveryInfo.paymentMethod === 'phoneNum' && <IoIosCheckmark />}
      </p>
      <p>휴대폰</p>
          </button>
        </div>
        </div>
        </div>
</div>
  );
};

export default MMBAKKO1;