import { useEffect } from "react";

function AuthRegister() {
    const params    = new URLSearchParams(window.location.search);
    const encData   = params.get("encData");
    const authType  = params.get("type");

    useEffect(() =>{
        document.form_auth.submit();
    },[]);

    if(authType === "IPIN"){
        return (
            <div>
                <form name="form_auth" method="post" action="https://cert.vno.co.kr/ipin.cb">
                    <input type="hidden" name="m" 			value="pubmain" />
                    <input type="hidden" name="enc_data"	value={encData} />
                </form>
            </div>
        )
    } else if(authType === "PHONE"){
        return (
            <div>
                <form name="form_auth" method="post" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb">
                    <input type="hidden" name="m" 			value="checkplusSerivce" />
                    <input type="hidden" name="EncodeData"	value={encData} />
                    <input type="hidden" name="param_r1" 	value="" />
                    <input type="hidden" name="param_r2" 	value="" />
                    <input type="hidden" name="param_r3" 	value="" />
                </form>
            </div>
        )
    }
}
export default AuthRegister;