import React, { useState } from 'react';
import ClickButtonBig from '../../common/Buttons/CllickButtonBig';
import { connectAxios } from '../../../module/connectAxios';
import { useNavigate } from 'react-router-dom';
import OneButtonPopup from '../../common/Popup/OneButtonPopup';

function MemberPasswordSearch() {
  const navigate                        = useNavigate();
  const [showPopup, setShowPopup]       = useState(false);
  const [id, setId]                     = useState(null);
  const [popupText, setPopupText]       = useState(null);
  const [searchInfo, setSearchInfo]     = useState({
    name      : '',
    birthdate : '',
    joinId    : ''
  });

  const handleSubmit = async (e) => {

    if (/[~!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]/.test(searchInfo.name)) {
      setPopupText('이름에 특수문자는 사용할 수 없습니다.');
      setShowPopup(true);
      return;
    }
    if (searchInfo.birthdate.length !== 6) {
      setPopupText('생년월일은 6자리를 입력해주세요.');
      setShowPopup(true);
      return;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(searchInfo.joinId)) {
      setPopupText('가입아이디 이메일을 입력해주세요.');
      setShowPopup(true);
      return;
    }

    const response = await connectAxios.get('/search-id-pw', {
      params : {
        type: 'P',
        userBirth: searchInfo.birthdate,
        userMail: searchInfo.joinId,
        userName: searchInfo.name
      }
    });

    const { resultCode } = response.data;

    if (resultCode === '200') {
      setId('Y');
      setPopupText('이메일로 임시 비밀번호를 전송하였습니다.');
    } else {
      setId('');
      setPopupText('일치하는 회원정보가 없습니다.');
    }

    setShowPopup(true);
  };

  const handleCancel = () => {
    if(id === "Y"){
      navigate('/login');
    }
    // 팝업창 닫기
    setShowPopup(false);
  };

  const isButtonDisabled = !searchInfo.name || !searchInfo.birthdate || !searchInfo.joinId;
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';


  // const handleSearchInfoChange = (e) => {
  //   setSearchInfo(searchInfo => ({...searchInfo, [e.target.name] : e.target.value}));
  const handleSearchInfoChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'birthdate') {
      // 생년월일 입력값에서 숫자 이외의 문자를 제거하고, 최대 6글자로 제한합니다.
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
      setSearchInfo((prevInfo) => ({ ...prevInfo, [name]: sanitizedValue }));

    } else {
      setSearchInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
    }
  };

  return (
    <div className="search-container">
      <label htmlFor="input6" >
        이름    </label>
        <input type="text" id='input6' name="name" value={searchInfo.name} onChange={(e) => handleSearchInfoChange(e)} placeholder='홍길동' />
  
      <label htmlFor="input7">
        생년월일      </label>
        <input type="tel" id='input7' name="birthdate" value={searchInfo.birthdate} onChange={(e) => handleSearchInfoChange(e)} placeholder='YYMMDD'/>

        <label htmlFor="input8" >
        가입아이디      </label>
        <input type="text" id='input8' name="joinId" value={searchInfo.joinId} onChange={(e) => handleSearchInfoChange(e)} placeholder='이메일' />

      <ClickButtonBig type="submit" onClick={() => handleSubmit()} buttonText="비밀번호 찾기"disabled={isButtonDisabled} 
        buttonId={buttonId} />

      {showPopup && (
      <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
      )}
    </div>
  );
}

export default MemberPasswordSearch;