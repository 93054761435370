import CryptoJS from 'crypto-js';

export function launchCrossPlatform(paymentMethod, phoneNum, userName, orderNo, jobName, priceAmount, prev, giwanNe){
    const payForm   = document.getElementById('LGD_PAYINFO');
    priceAmount     = priceAmount.replaceAll(",","");
    
    createInput("LGD_BUYERPHONE", phoneNum);

    let payCd = paymentMethod === "creditCard" ? 'SC0010' : 'SC0060';
    createInput("LGD_CUSTOM_FIRSTPAY", payCd);

    createInput("LGD_ENCODING", "utf-8");
    createInput("LGD_ENCODING_RETURNURL", "utf-8");
    createInput("LGD_ENCODING_NOTEURL", "utf-8");
    
    let platForm    = process.env.REACT_APP_PAY_PLATFORM;
    let mId         = "signgate1";
    createInput("CST_PLATFORM", platForm);
    createInput("CST_MID", mId);
    createInput("CST_WINDOW_TYPE", "submit");

    let lgd_mid         = platForm === "test" ? `t${mId}` : mId;
    let amount          = priceAmount; //FIXME 가격 책정
    let lgd_mertkey     = "a797d47628f9d9583c3f1cfd93aaa1c3"; //상점키
    let lgd_time        = currDateTime();
    let lgd_hashData    = createHashData(lgd_mid, orderNo, amount, lgd_time, lgd_mertkey);
    let jobNameFull     = prev === "send" ? jobName + " 전송" : jobName;
    jobNameFull         = giwanNe + "-" + jobNameFull;
 
    createInput("LGD_MID", lgd_mid);
    createInput("LGD_OID", orderNo);
    createInput("LGD_BUYER", userName);
    createInput("LGD_PRODUCTINFO", jobNameFull);
    createInput("LGD_AMOUNT", amount);
    createInput("LGD_BUYEREMAIL", "");
    createInput("LGD_CUSTOM_SKIN", "cyan");
    createInput("LGD_CUSTOM_PROCESSTYPE", "TWOTR");
    createInput("LGD_TIMESTAMP", lgd_time);
    createInput("LGD_HASHDATA", lgd_hashData);
    createInput("LGD_RETURNURL", `${process.env.REACT_APP_BACK_BASE_URL}/mobile/return-pay`);
    createInput("LGD_VERSION", "JSP_SmartXPay_1.0");

    // 안드로이드 에서 신용카드 적용  ISP(국민/BC)결제에만 적용 (선택)
    createInput("LGD_KVPMISPAUTOAPPYN", "A");

    // 가상계좌 결제 (미사용으로 주석처리)
    // createInput("LGD_CASNOTEURL", `${process.env.REACT_APP_FRONT_BASE_URL}`);

    createInput("LGD_RESPCODE", "");
    createInput("LGD_RESPMSG", "");
    createInput("LGD_PAYKEY", "");

    createInput("giwanNo", "020000");

    window.open_paymentwindow(document.getElementById('LGD_PAYINFO'), `${platForm}`, 'submit');
    document.getElementById("LGD_PAYMENTWINDOW_IFRAME").style.display 	= "block";
    document.getElementById("LGD_PAYMENTWINDOW_IFRAME").style.zIndex = "999999";
    // document.getElementById("pay-submit").style.display 	            = "none";
};

function createInput(name, value){
    const payForm   = document.getElementById('LGD_PAYINFO');

    let inputInfo   = document.createElement('input');
    inputInfo.type  = "hidden";
    inputInfo.name  = name;
    inputInfo.value = value;
    payForm.appendChild(inputInfo);
}

function createHashData(LGD_MID, LGD_OID, LGD_AMOUNT, LGD_TIMESTAMP, LGD_MERTKEY) {
    let sb = [];
    sb.push(LGD_MID);
    sb.push(LGD_OID);
    sb.push(LGD_AMOUNT);
    sb.push(LGD_TIMESTAMP);
    sb.push(LGD_MERTKEY);

    let bNoti       = sb.join('');
    let digest      = CryptoJS.MD5(bNoti);
    let hash_data   = CryptoJS.enc.Hex.stringify(digest);

    return hash_data;
}

function currDateTime(){
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}