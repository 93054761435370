import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 리소스 파일 로드
import koTranslation from '../message/download_ko.json';
import enTranslation from '../message/download_en.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ko: { translation: koTranslation },
      en: { translation: enTranslation },
    },
    lng: "ko", // 기본 언어 설정
    interpolation: {
      escapeValue: false, // React 컴포넌트 내에서 HTML 렌더링 허용
    },
  });

export default i18n;
