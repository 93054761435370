import React from 'react';
import TopNavBar from '../../components/common/TopNavBar';

const MemberSettingSecurity = () => {
  return (
    <div>
   
    <TopNavBar/>
    <div className="section-screen">
    <h2 className="foam-title" > 회원 정보 관리</h2>
  
    <div className="settings-container">

    <div className='member-join-form-list'>
       <p className='join-form-text'>비밀번호 입력</p>
       <label htmlFor="input255" className='blind'>기존 비밀번호 </label>
      <input 
      type="password" 
      value=""
      placeholder="기존 비밀번호를 입력해 주세요." 
      id='input255'
      onChange="" />

       </div>
       <ul className='guide-line-list' id='password-guide'>
            <li className='guide-line'><span>•</span><p>외부로부터 정보를 안전하게 보호하기 위해 비밀번호를 다시 한 번 확인 합니다.</p></li>
                <li className='guide-line'><span>•</span> <p>비밀번호는 타인에게 노출되지 않도록 주의해주세요.</p></li>
            </ul>


      </div>

    </div>
    </div>
  );
};

export default MemberSettingSecurity;