export const MAP_DATA_CUSTOMERSERVICE = [
  {
  id: 1,
  text: 'FAQ',
  name: 'faq',
  },
  {
  id: 2,
  text: '1:1 상담',
  name: 'consultation',
  },
  ];