import React, { useEffect, useState }  from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import { IoIosArrowForward } from 'react-icons/io';
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import Loadings from '../../components/common/Loading';


const MMBALST = () => {
  const  navigate                         = useNavigate();
  const { state }                         = useLocation();
  const [basketList, setBasketList]       = useState([]);
  const [totalCnt, setTotalCnt]           = useState('0');
  const [isPageVisible, setIsPageVisible] = useState(false);
  let   { hakbun, hakwi, prev }           = state || {};
  const [isLoading, setIsLoading]         = useState(false);

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  async function getHakjukChk() {
    //I-08. 학적여부 확인
    setIsLoading(true);

    let hakbunTemp = user.hakbun !== undefined ? user.hakbun : 
                     prev === 'send' ? user.hakbunTemp : hakbun;

    const response = await connectAxios.get('/hakjuk-chk', {
      params: {
        lgnQryType    : giwan.lgnQryType,
        giwanNo       : giwan.giwanNo,
        tpid          : user.tpid,
        userMail      : user.userMail,
        userId        : user.userId,
        hakbun        : hakbunTemp,
        userBirth     : user.userBirth,
        userName      : user.userName,
        hakwi         : hakwi
      }
    });
    return response;
  };

  async function getBasketList() {
    //tpid 새로 세팅되었을 수도 있으니 한번 더 가져옴
    const user      = JSON.parse(sessionStorage.getItem('userInfo'));
    const response  = await connectAxios.get('/basket', {
      params: {
        tpid          : user.tpid,
        lgnQryType    : giwan.lgnQryType,
        giwanNo       : giwan.giwanNo,
        userId        : user.userId,
        userBirth     : user.userBirth
      }
    });
    var result = response.data;

    if(result.resultCode === "200"){
      setIsLoading(false);

      setBasketList(result.basketList);
      setTotalCnt(result.totalCnt);
    }
    setIsPageVisible(true);
  }

  async function sampleTpidChk () {
    const response    = await getHakjukChk();
    const resultCode  = response.data.resultCode;

    hakbun = response.data.hakbun;

    if(resultCode === "200"){
      const newUser = { ...user, tpid : response.data.tpid, hakwi : hakwi, hakbunTemp : hakbun }
      setIsLoading(false);

      window.sessionStorage.setItem("userInfo",  JSON.stringify(newUser));

      getBasketList();
    } else if(resultCode === "201"){
      setIsLoading(false);
      navigate('/ec-select/fail');

    } else if(resultCode === "202"){
      setIsLoading(false);
      navigate('/ec-select/hakbun', { state : { prevPage : '/basket-list'}});
    
    } else if(resultCode === "203"){
      setIsLoading(false);
      navigate('/ec-select/fail', { state : { msg : response.data.resultMessage } });

    } else if(resultCode === "204"){
      setIsLoading(false);
      navigate('/ec-select/fail', { state : { msg : response.data.displayMessage } });
    }
  }

  //페이지 로딩시 한번 세팅
  useEffect(() => {
    sampleTpidChk();
  },[]);
  
  const handleCardClick = (page, {basket}) => {
    // if (basket.retYN === "Y") {
    // navigate(`/reject-certi`, { state : { basket : basket }});
    // }else {
      navigate(`/${page}`, { state : { basket : basket }});
    // }
  };

  const handleButtonClick = page => {
    navigate(`/${page}`);
  };


  return (
    <div className='basket'>
        {isLoading && (
        <React.Fragment>
          <Loadings
              loadingTxt="증명서 보관함을 "
              loadingTxt1="불러오는 중입니다."
          />
        </React.Fragment>
      )}
      
    {isPageVisible && (
      <React.Fragment>
        <TopNavBar/>
      <div className="basket-screen" id='basket-screen'>
    <h2 className='foam-title'>나의 증명서</h2>
      <p className='certi-count'>총 {totalCnt} 건</p>
    </div>
      <div className="section-title">    
        </div>
        <div className="pagetip">
      <p  id='left-line'>증명서는 발급일로부터 30일간 이용이 가능합니다.</p>
        {/* <p>증명서 전송은 1회 가능하며, 전송 후 다운로드는 제한 됩니다.</p> */}
        </div>
        <div className='card-section'>
      <div className='card-area'>
         {totalCnt > 0 ? (
          basketList && basketList.map((basket, index) => (
          <div
          key={index}
          onClick={() => handleCardClick("basket-early", {basket})}
          className={`certificate-card ${basket.dDay < 0 || basket.sendYN === 'Y' ? 'disabled' : ''}  ${index === basketList.length - 1 ? "last-card" : ""
          }`}
          >
            <div className="card-body">
            {basket.sendYN === 'Y' ? null : <IoIosArrowForward id='middle-arrow' />}
              <p className="card-tap-btn" >
              {basket.sendYN === 'Y'? '전송완료' : (basket.dDay < 0 ? '만료' : `D-${basket.dDay}`)}</p>
              {/* {(basket.retYN === "Y" ? <p className="card-tap-btn" id='red-tap'>반송</p> : "")} */}
              {(basket.sendYN === 'N' && basket.retYN !== 'Y' && basket.dDay >= 0) && <p className="card-tap-btn" id='brue-tap'>전송가능</p>}
              {(basket.sendYN === "Y" ? <p className="card-tap-btn" >{basket.sendMethod} 전송</p> : "")}
              {(basket.sendYN === "Y" ? <p className="card-tap-btn" >{basket.sendDate}</p> : "")}
            
              <p className="card-text-01">
                {basket.jobNe}
                </p>
                <div className="card-txt-box">
                <div className="card-txt-box-left">
              <p className="card-text-02">{giwan.giwanName}</p>
              <p className="card-text-02">{basket.minRegDate} 발급</p>
              </div>
                <div className="card-txt-box-right">
              <p className="card-text-02">{basket.receiver}</p>
              <p className="card-text-02">{basket.purpose}</p>
              </div>

              </div>
            </div>
          </div>
        ))
         ) : (
        <div className='history-tips'>
       발급받은 증명서가 없습니다.
        </div>
      )}
      </div>
      </div>
      <ClickButtonBig buttonId="zindex" onClick={() => handleButtonClick("history-list")} buttonText="통합 발급 내역" />
      </React.Fragment>
    )}
      {/* <button 
      onClick={() => handleButtonClick("history-list")}
      className='move-btn'>통합 발급 내역</button> */}
    </div>
  );
};
export default MMBALST;
