import React from "react";
import "./clickButtonBig.css?ver=1"

const ClickButtonSmall = ({ onClick, buttonText,buttonId }) => {
  return (
    <button 
    type="button"
    onClick={onClick} 
    className="click-button-s" 
    id={buttonId}>
    {buttonText}
    </button>
  );
};

export default ClickButtonSmall;

