export const MAP_DATA_Delivery = [
   {
    id: 1,
    text: '팩스',
    name: 'fax',
    },
 
  // {
  // id: 1,
  // text: '카카오톡',
  // name: 'kko',
  // },
  // {
  // id: 2,
  // text: '이메일',
  // name: 'eml',
  // },
  // {
  //   id: 3,
  //   text: '팩스',
  //   name: 'fax',
  //   },
  ];