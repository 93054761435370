import { useRef } from "react";
const ViewPDF = ({minNo, tpid})=>
{   
    const iframeRef = useRef(null);

    const baseUrl   = `https://link.webminwon.com:444/WMWebService/compile.do`;
    const params    = `?addr=${process.env.REACT_APP_EMS_IP}&minno=${minNo}&port=${process.env.REACT_APP_EMS_PORT}&tpid=${tpid}&mode=withHTML&qf=0.5`

    const class_style = {
        width: '140%',
        height: '100%'
    };

    return(
        <>
        <div style={class_style} className="iframe-container">
            <iframe
             ref={iframeRef} 
             name="view_mac" 
             src={baseUrl + params} 
             style={class_style}
             className="iframe-content"
             >
            </iframe>
        </div>
        </>
    )
} 
export default ViewPDF