export const MAP_DATA_MEMBERSEARCH = [
  {
  id: 1,
  text: '아이디 찾기',
  name: 'idsearch',
  },
  {
  id: 2,
  text: '비밀번호 찾기',
  name: 'passwardsearch',
  },
  ];