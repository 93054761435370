import React from "react";
import "./clickButtonBig.css?ver=1"

const ClickButtonBig = ({ disabled, onClick, buttonText, buttonId, backId }) => {
  return (
    <div className="click-big-wrap" id={backId}>
    <button 
    type="button"
    onClick={onClick} 
    disabled={disabled}
    className="click-big-button" 
    id={buttonId}>
    {buttonText}
    </button>
    </div>
  );
};

export default ClickButtonBig;

