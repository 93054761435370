import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';
import { getDecryptParams } from '../../module/MAES256';


function MemberRegister3() {
  const navigate                                = useNavigate();
  const [isIdValid, setIsIdValid]               = useState(false);
  const [isidAuthState, setisIdAuthState]       = useState("");
  const [isPasswordValid, setIsPasswordValid]   = useState("");
  const [showPopup, setShowPopup]               = useState(false);
  const [popupText, setPopupText]               = useState('');

  const authType  = window.sessionStorage.getItem("authType");
  const authInfo  = new URLSearchParams(window.location.search);
  const userInfo  = getDecryptParams(authInfo.get("data"));
  const decName   = decodeURIComponent(userInfo["userName"]);
  const userTel   = userInfo["userTel"];

  //필수 입력값
  const [newUser, setNewUser] = useState(
    {id             : '', 
    phone           : userTel !== undefined ? userTel : '', 
    password        : '', 
    passwordConfirm : ''});


  const handleIdChange = (e) => {
    const email = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsIdValid(emailRegex.test(email));
    setisIdAuthState(false);
    setNewUser({ ...newUser, id: email });
  };

  const handleCancel = () => {
    if(popupText === '회원가입이 완료되었습니다.' || 
    popupText === '이미 가입된 회원입니다.'){
      navigate('/login');
    }
    
    // 팝업창 닫기
    setShowPopup(false);
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 11);
    setNewUser({ ...newUser, phone: sanitizedPhoneNumber });
  };
  
  const handlePasswordChange = (e) => {
    const pw = e.target.value;
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;
    setIsPasswordValid(regex.test(pw)); 
    setNewUser({ ...newUser, password: pw });
  };

  const handlePasswordConfirmChange = (e) => {
    const pwConfirm = e.target.value;
    // if (pwConfirm !== newUser.password) {
    //   setIsPasswordValid(false);
    // } else {
    //   setIsPasswordValid(true);
    // }

    setNewUser({ ...newUser, passwordConfirm: pwConfirm });
  };

  const handleIdCheck = async (e) => {
    e.preventDefault();

    const data = {
      userMail: newUser.id,
    };

    if(isIdValid){
      const response = await connectAxios.get('/check-id', {params : data});
  
      if (response.data.idCheck === 'Y') {
        setShowPopup(true);
        setPopupText('사용 가능한 이메일입니다.');
        setisIdAuthState(true);
      } else if (response.data.idCheck === 'N') {
        setShowPopup(true);
        setPopupText('이미 가입된 이메일입니다.');
      }
    } else {
      setShowPopup(true);
      setPopupText('이메일 형식을 확인해주세요.');
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(isIdValid && isPasswordValid && isidAuthState){
      const data = {
        userMail: newUser.id,
        userTel: newUser.phone,
        userPassword: newUser.password,
        userBirth: userInfo["userBirth"].substring(2,8),
        userName: decName,
        userDiHash: userInfo["userDiHash"],
        userCiHash: userInfo["userCiHash"],
        userAuthType: authType,
      };

      const response = await connectAxios.post(`/user`, data);
  
      if (response.data.resultCode === "200") {
        setShowPopup(true);
        setPopupText('회원가입이 완료되었습니다.');
      } else if (response.data.resultCode === "201"){
        setShowPopup(true);
        setPopupText('비밀번호를 확인해주세요.');
      } else if (response.data.resultCode === "202"){
        setShowPopup(true);
        setPopupText('이미 가입된 회원입니다.');
      }
    } else if (!isIdValid){
      setShowPopup(true);
      setPopupText('이메일 형식을 확인해주세요.');
    } else if (!isidAuthState){
      setShowPopup(true);
      setPopupText('아이디 중복확인이 필요합니다.');
    } else if (!isPasswordValid){
      setShowPopup(true);
      setPopupText('비밀번호를 확인해주세요.');
    }
  };

  const isPasswordMatch = newUser.password !== '' && newUser.password === newUser.passwordConfirm;
  const passwordConfirmStyle = newUser.passwordConfirm === '' || isPasswordMatch
    ? { borderColor: newUser.passwordConfirm === '' ? '#C5C5C5' : 'green' }
    : { borderColor: 'red' };
  const isButtonDisabled = !(isIdValid && isidAuthState && isPasswordMatch);

  return (
    <div className='register-screen'>
      <TopNavBar />
      <div className="member-screen">
        <h2 className="top-title">웹민원센터 가입절차</h2>
        <h2 className="step-title">
            <div className='step-number'>3</div>      
            <span className="step-txt">서비스 이용을 위하여<br />회원가입 정보를 입력해 주세요.</span>
        </h2>
        <div className='member-join-container'>
        <div className="member-join-form">
            <p className="input-label">이름</p>
            <div className="id-form-container">
        <label htmlFor="input-id1" className='blind'>이름 </label>
        <input type="id-checked" id='input-id1' placeholder={decName} disabled={true}/>
          </div>

            <p className="input-label">아이디</p>
            <div className="id-form-container">
            <label htmlFor="input9" className='blind'> 아이디</label>
              <input
                type="id-check"
                id='input9'
                placeholder="사용하실 이메일을 입력해 주세요."
                onChange={(e) => handleIdChange(e)}
              />
              <button id="duplicate-check" onClick={(e) => handleIdCheck(e)}>
                중복
                <br />
                확인
              </button>
            </div>

            <p className="input-label">휴대폰 번호</p>
            <label htmlFor="input10" className='blind'>휴대폰 번호 </label>

            <input
              type="tel"
                id='input10'
              placeholder="휴대폰 번호를 (-) 없이 입력해 주세요."
              onChange={handlePhoneChange}
              value={newUser.phone}
              style={
                (newUser.phone.length > 0 && newUser.phone.length < 11)
                  ? { borderColor: 'red' }
      
                  : {}
                }
            />
             {newUser.phone.length > 0 && newUser.phone.length < 11 && (
    <p className="error-message" id='marginBottom05'>* 휴대폰 번호는 11자리를 입력해주세요.</p>
  )}
          <p className="input-label">비밀번호</p>
      <label htmlFor="input11" className='blind'>비밀번호</label>
      <input
  type="password"
  id='input11'
  placeholder="비밀번호를 입력해주세요."
  onChange={handlePasswordChange}
  style={isPasswordValid === true ? { borderColor: 'green' } : isPasswordValid === false ? { borderColor: 'red' } : {}}
/>
<p className='no-password' id={isPasswordValid ? 'green' : isPasswordValid === false ? 'red' : ''}>
  {isPasswordValid === true
    ? '* 사용 가능한 비밀번호입니다.'
    : isPasswordValid === false
    ? '* 8자리 이상의 숫자, 영문자, 특수문자를 조합하여 입력해주세요.'
    : newUser.password === '' // 새로운 부분: 비밀번호가 비어있을 때 메시지 표시
    ? '* 8자리 이상의 숫자, 영문자, 특수문자 조합으로 가능합니다.'
    : ''}
</p>
<p className="input-label" id='marginTop05'>비밀번호 확인</p>
<label htmlFor="input12" className='blind'>비밀번호 확인</label>
<input
  type="password"
  id='input12'
  placeholder="비밀번호를 다시 한번 입력해 주세요."
  onChange={handlePasswordConfirmChange}
  style={newUser.passwordConfirm === '' ? { borderColor: '#C5C5C5' } : isPasswordMatch ? { borderColor: 'green' } : { borderColor: 'red' }}
/>
{newUser.passwordConfirm !== '' && !isPasswordMatch && <p className='no-password' id="red">* 비밀번호가 일치하지 않습니다.</p>}
{newUser.passwordConfirm !== '' && isPasswordMatch && <p className='no-password' id="green">* 비밀번호가 일치합니다.</p>}
        </div>
        </div>
        </div>
      <ClickButtonBigA
      className="change-btn"
        onClick={handleSubmit}
        buttonText="가입하기"
        disabled={isButtonDisabled} 
        buttonId={isButtonDisabled ? 'buttonDisabled' : ''}
      />

      {showPopup && (
        <OneButtonPopup onClick={handleCancel} popupText={`${popupText}`} buttonText="확인"/>
      )}
    </div>
  );
}

export default MemberRegister3;