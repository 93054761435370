import React from "react";
import "./clickButtonBig.css?ver=1"

const ClickButtonBigA = ({ 
  className,
   disabled, 
   onClick, 
   buttonText,
   buttonId }) => {
  return (
    <button 
    type="button"
    disabled={disabled}
    onClick={onClick} 
    className={`click-big-button-A ${className}`}
    id={buttonId}>
    {buttonText}
    </button>
  );
};

export default ClickButtonBigA;

