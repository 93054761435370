import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import { IoIosCheckmark } from 'react-icons/io';
import { useInputLayout } from '../../hooks/useInputLayout';
import Loadings from '../../components/common/Loading';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';


const MMECSEL1 = () => {
    const navigate                                          = useNavigate();
    const { state }                                         = useLocation();
    const [selectedHakjuk, setSelectedHakjuk]               = useState('');
    const [selectedSangtae, setSelectedSangtae]             = useState(''); //광운대
    const [selectedLanguage, setSelectedLanguage]           = useState('국문증명서');
    const [hakjuk, setHakjuk]                               = useState([{}]); //학적정보
    const [hakjukText, setHakjukText]                       = useState({});
    const [isPageVisible, setIsPageVisible]                 = useState(false);
    const [isEngCert, setIsEngCert]                         = useState('N');
    const [showPopup, setShowPopup]                         = useState(false);
    const [showPopupText, setShowPopupText]                 = useState(false);
    let   { hakbun, hakwi }                                 = state || {};
    const [isLoading, setIsLoading]                         = useState(false);

    //Storage에서 가져오기
    const user    = JSON.parse(sessionStorage.getItem('userInfo'));
    const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

    const handleCancel = () => {
      if('022603' === giwan.giwanNo || '021150' === giwan.giwanNo || '111109' === giwan.giwanNo){
        let korYN = getKorYN();
        nextPage("ec-select2", korYN, 0);
      } else {
        // 팝업창 닫기
        setShowPopup(false);
      }
    };

    async function getHakjukChk() {
      //I-08. 학적여부 확인
      setIsLoading(true);
      const response = await connectAxios.get('/hakjuk-chk', {
        params: {
          lgnQryType    : giwan.lgnQryType,
          giwanNo       : giwan.giwanNo,
          tpid          : user.tpid,
          userMail      : user.userMail,
          userId        : user.userId,
          hakbun        : user.hakbun !== undefined ? user.hakbun : hakbun,
          userBirth     : user.userBirth,
          userName      : user.userName,
          hakwi         : hakwi
        }
      });
      return response;
    };

    async function getHakjuk() {
      //I-01. 학적정보 확인
      const response = await connectAxios.get('/hakjuk', {
        params: {
          lgnQryType    : giwan.lgnQryType,
          giwanNo       : giwan.giwanNo,
          hakbun        : user.hakbun !== undefined ? user.hakbun : hakbun,
          userBirth     : user.userBirth,
          userName      : user.userName,
          hakwi         : hakwi
        }
      });
      return response;
    };

    async function getEngMessage() {
      //I-10. 영문명 입력 문구 조회
      const response = await connectAxios.get('/ename-message', {
        params: {
          giwanNo    : giwan.giwanNo
        }
      });
      return response;
    };

    async function getMinapjaMessage() {
      const response = await connectAxios.get('/minapja-message', {
        params: {
          giwanNo    : giwan.giwanNo
        }
      });
      return response;
    }

    //페이지 랜더링시 실행
    useEffect(() => {
      const fetchData = async () => {
        const response    = await getHakjukChk();
        const resultCode  = response.data.resultCode;

        hakbun = response.data.hakbun;

        // hakjuk.engCertChk가 'N'이면 selectedLanguage를 '국문증명서'로 설정
        if (isEngCert === 'N') {
          setSelectedLanguage('국문증명서');
        }

        if(resultCode === "200"){
          const response2     = await getHakjuk();
          const resultCode2   = response2.data.resultCode;
          setIsLoading(false);
  
          if(resultCode2 === "200"){
            setHakjuk(response2.data.hakjukInfo);
            setHakjukText(response2.data.innerText);
            setIsEngCert(response2.data.engCertChk);
            //tpid 학적조회시 다시 세팅
            const newUser = { ...user, tpid : response.data.tpid }
            window.sessionStorage.setItem("userInfo",  JSON.stringify(newUser));
            setIsPageVisible(true);

            //학교별 공지
            if('022609' === giwan.giwanNo) {
              setShowPopupText('인터넷 증명발급으로는 성적증명서에 졸업석차가 기재되지 않습니다.<br>' +
              '졸업석차가 포함된 증명서가 필요하시면 051-890-3045로 문의하시기 바랍니다.');
              setShowPopup(true);
            }
          } else if(resultCode2 === "201"){
            navigate('/ec-select/fail', { state : { msg : response2.data.displayMessage } });
          }
        } else if(resultCode === "201"){
          navigate('/ec-select/fail');
          setIsLoading(false);

        } else if(resultCode === "202"){
          navigate('/ec-select/hakbun', { state : { prevPage : '/ec-select1'}});
          setIsLoading(false);
        
        } else if(resultCode === "203"){
          setIsLoading(false);
          navigate('/ec-select/fail', { state : { msg : response.data.resultMessage } });
  
        } else if(resultCode === "204"){
          setIsLoading(false);
          navigate('/ec-select/fail', { state : { msg : response.data.displayMessage } });
        }
      };
      fetchData();
    },[]);

    const handleHakjukChange = (event) => {
      if("021104" === giwan.giwanNo || "021192" === giwan.giwanNo) {
        setSelectedHakjuk(event.target.value);
      } else {
        setSelectedHakjuk(event.target.options[event.target.selectedIndex].getAttribute('data-key'));
      }
    };

    // 광운대
    const handleSangtaeChange = (event) => {
      setSelectedSangtae(event.target.value);
    };
  
    const handleLanguageChange = (language) => { 
      setSelectedLanguage(language);
    };

    function getKorYN () {
      let korYN = '';
      
      if(selectedLanguage === '국문증명서'){
        korYN = 'K';
      } else if(selectedLanguage === '영문증명서'){
        korYN = 'E';
      }

      return korYN;
    }

    const handleButtonClick = async (page) => {
      const selectElement = document.getElementById('select1');
      const keyToFind = selectedHakjuk;
      let index = 0;

      if(selectElement){
        for (let i = 0; i < selectElement.options.length; i++) {
          if (selectElement.options[i].getAttribute('data-key') === keyToFind) {
            index = i;
            break;
          }
        }
      }

      let korYN = getKorYN();

      if(hakjuk[index].ename !== undefined){
        let ename_tmp = hakjuk[index].ename.replace(/\s/g, "");

        //영문명 확인
        if ((korYN === 'E' && hakjuk[index].engCertChk === 'Y' && (!hakjuk[index].ename || ename_tmp===""))
            || (('022603' === giwan.giwanNo || '021150' === giwan.giwanNo || '111109' === giwan.giwanNo) && (!hakjuk[index].ename || ename_tmp===""))) {
          // 영문증명서 선택이면서 engCertChk가 Y이고 ename이 없는 경우
          // 팝업을 띄우고 아래로 이동하지 않음
          // 팝업을 띄우는 코드가 어떻게 동작하는지는 팝업 컴포넌트에 따라 다를 수 있음
          const response    = await getEngMessage();
          let msg           = "";
          
          if("111102" === giwan.giwanNo){
            const msg_tmp = response.data.enameMessage.split("@");
            if (hakjuk[index].hakgwa.includes("공인")){
              msg = msg_tmp[0];
            } else {
              msg = msg_tmp[1];
            }
          } else if ("021105" === giwan.giwanNo){
            const msg_tmp = response.data.enameMessage.split("@");
            if (hakjuk[index].jobSubGpCd === "09"){
              msg = msg_tmp[0];
            } else {
              msg = msg_tmp[1];
            }
          } else {
            msg = response.data.enameMessage;
          }
          setShowPopupText(msg);
          setShowPopup(true);// 팝업 띄우기 함수를 호출
          return;
        
        //미납 확인
        } else {
          //숙명여대, 한림대
          if(('021101' === giwan.giwanNo && 'Y' === hakjuk[index].minapja)
          || ('024201' === giwan.giwanNo && 'N' === hakjuk[index].minapja)) {
            const response    = await getMinapjaMessage();
            setShowPopupText(response.data.enameMessage);
            setShowPopup(true);
            return;
          }
        } 
      }
      nextPage(page, korYN, index);
    };

    const nextPage = (page, korYN, index) => {
      
      //예외학교 (광운대)
      if("021104" === giwan.giwanNo || "021192" === giwan.giwanNo) {
        navigate(`/${page}`, {
          state: {
            hakjuk: {
              ...hakjuk[index], // 여기서는 업데이트 이후의 값을 사용
              'jobSubGpCd': selectedHakjuk.substring(2, 4),
              'hakwi': selectedHakjuk.substring(5, 50),
              'sangtae': selectedSangtae,
            },
            korYN: korYN,
          },
        });
      } else {
        navigate(`/${page}`, { 
          state : { 
            hakjuk  : hakjuk[index],
            korYN   : korYN
          } 
        });
      }
    }

    useInputLayout();

    const isbuttonDisabled = '021104' === giwan.giwanNo ? !(selectedHakjuk !== '' && selectedSangtae !== '') : '021192' === giwan.giwanNo ? !(selectedSangtae !== '') :false;
    const buttonId = isbuttonDisabled ? 'buttonDisabled' : '';

    return (
      <div id='' >
        {isLoading && (
        <React.Fragment>
          <Loadings
              loadingTxt="학적 정보를 "
              loadingTxt1="조회하는 중입니다."
          />
        </React.Fragment>
      )}

        {isPageVisible && !isLoading && (
          <>
          <TopNavBar/> 
          <div className="select-screen">
            <div className="section-title">
            <h2 className='foam-title'>학적정보 확인</h2>
          </div>

          {hakjuk.map((values, index) => (
            <>
        <div className={`info-box ${index >= 1 ? 'hakwi-more' : ''}`}>

              <div className="info-row">
                <div className="info-label">학교명</div>
                <div className="info-value">{giwan.giwanName}</div>
              </div>

              { hakjukText.sTemp1 !== null && hakjukText.sTemp1 !== undefined && (
                <div className="info-row">
                <div className="info-label">{hakjukText.sTemp1}</div>
                <div className="info-value">{values.hakwi}</div>
                </div>
              )}

              { hakjukText.sTemp2 !== null && hakjukText.sTemp2 !== undefined && (
              <div className="info-row">
                <div className="info-label">{hakjukText.sTemp2}</div>
                <div className="info-value">{values.hakgwa}</div>
              </div>
              )}

              { hakjukText.sTemp4 !== null && hakjukText.sTemp4 !== undefined && (
              <div className="info-row">
                <div className="info-label">{hakjukText.sTemp4}</div>
                <div className="info-value">
                  {'024713' === giwan.giwanNo ? values.hakbun.substring(2,9) : values.hakbun}
                  </div>
              </div>
              )}

              <div className="info-row">
                <div className="info-label">이름</div>
                <div className="info-value">{values.kname}</div>
              </div>

              { hakjukText.sTemp3 !== null && hakjukText.sTemp3 !== undefined && (
              <div className="info-row">
                <div className="info-label">{hakjukText.sTemp3}</div>
                <div className="info-value">{values.ename}</div>
              </div>
              )}

              { hakjukText.sTemp5 !== null && hakjukText.sTemp5 !== undefined && (
              <div className="info-row">
                <div className="info-label">{hakjukText.sTemp5}</div>
                <div className="info-value">{values.sangtae.split("^")[0]}</div>
              </div>
              )}

              {/* 광운대 */}
              { values.hakwi.length === 1 && (
              <div className="info-row" >
                <div className="info-label">{hakjukText.sInHtml}</div>
                <div className="info-value">{values.hakwi[0].split("^")[2]}</div>
              </div>
              )}
              </div>
            </>
          ))}

          {/* 광운대일경우 셀렉트 박스 다름 */}
          {giwan.giwanNo !== '021104' && giwan.giwanNo !== '021192'? (
            <>
              {hakjukText.sInHtml && (
                <>
                <div className="section-title">
                <h2 className='foam-title'>{hakjukText.sInHtml.replace("선택","")} 선택</h2>
              </div>
              <label htmlFor="select1" className='blind'>학적선택</label>
              <select className="dropdown option-box" id='select1' onChange={handleHakjukChange}>
                {hakjuk.map((values) => (
                  <option data-key={values.hakjukPyogi} value={values.hakwi}>
                    {values.hakjukPyogi}               </option>
                ))}
              </select>
              </>
              )}
            </>
          ) : (
            <>
            {hakjukText.sInHtml !== undefined &&(
              <>
              {hakjuk[0].hakwi && (
                <>
                <div className="section-title">
                  <h2 className='foam-title'>{hakjukText.sInHtml} 선택</h2>
                </div>
                <label htmlFor="select1" className='blind'>학적선택</label>
                <select className="dropdown option-box" id='select1' value={selectedHakjuk}  onChange={handleHakjukChange}>
                  <option key="default" value="">선택해주세요.</option>
                  {hakjuk[0].hakwi.map((values) => (
                    <option key={values} value={values}>
                      {values.split("^")[2]}</option>
                ))}
                </select>
                </>
              )}
              </>
            )}
            {hakjuk[0].sangtae && (
              <>
              <div className="section-title">
                <h2 className='foam-title'>{hakjukText.sInHtml2} 선택</h2>
              </div>
              <label htmlFor="select1" className='blind'>학적/재직상태 선택</label>
              <select className="dropdown option-box" id='select2' value={selectedSangtae}  onChange={handleSangtaeChange}>
                <option key="default2" value="">선택해주세요.</option>
                {hakjuk[0].sangtae.map((values) => (
                <option key={values} value={`${values}`}>
                  {values}</option>
              ))}
            </select>
              </>
            )}
            </>
          )}

      {!("021150" === giwan.giwanNo || "022603" === giwan.giwanNo || "111109" === giwan.giwanNo || "111106" === giwan.giwanNo) && (
        <>
          <div className="section-title">
            <h2 className='foam-title'>증명서 언어 선택</h2>
          </div>
          <div className="select-btn-container">

         {/* 국문증명서 버튼은 항상 보이도록 설정 */}
          <button
            className={selectedLanguage === '국문증명서' ? 'select-btn active' : 'select-btn'}
            onClick={() => handleLanguageChange('국문증명서')}
          >
            <p className={selectedLanguage === '국문증명서' ? 'select-round active' : 'select-round'}>
              {selectedLanguage === '국문증명서' && <IoIosCheckmark />}
            </p>
            <p>국문증명서</p>
          </button>

          {/* 영문증명서 버튼은 engCertChk가 'Y'일 때만 보이도록 설정 */}
          {isEngCert === 'Y' && (
            <button
              className={selectedLanguage === '영문증명서' ? 'select-btn active' : 'select-btn'}
              onClick={() => handleLanguageChange('영문증명서')}
            >
              <p className={selectedLanguage === '영문증명서' ? 'select-round active' : 'select-round'}>
                {selectedLanguage === '영문증명서' && <IoIosCheckmark />}
              </p>
              <p>영문증명서</p>
            </button>
          )}
          </div>
        </>
      )}
          </div>
          <ClickButtonBigA 
            onClick={() => handleButtonClick("ec-select2")}
            buttonText="다음"
            disabled={isbuttonDisabled}
            buttonId={buttonId}
            className="change-btn" />
          </>
        )}
        {showPopup && (
        <OneButtonPopup onClick={handleCancel} popupText={showPopupText} buttonText="확인"/>
        )}
      </div>
    );    
  };
        
export default MMECSEL1;