import React, { useEffect, useState } from 'react';
import TopNavBar from '../../components/common/TopNavBar';
import { useScript } from '../../hooks/useScript';
import { IoIosArrowForward } from 'react-icons/io';

function MemberRegister2() {

  useScript("https://cdn.toss.im/cert/v1");

  //토스 인증 txid 얻기
  function getTxId() {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', `${process.env.REACT_APP_BACK_BASE_URL}/mobile/toss-ready`);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onload = function () {
        if (xhr.status !== 200 && xhr.status !== 201 && xhr.status !== 202) {
          reject({ status: xhr.status, statusText: xhr.statusText });
        } else {
          resolve(JSON.parse(xhr.response));
        }
      };
      xhr.send(JSON.stringify({ requestId: 'test' }));
    });
  }

  const authToss = () => {
    window.sessionStorage.setItem("authType", "T");
    let tossCert = window.TossCert();

    getTxId().then(function (response) {
      tossCert.start({
        type          : 'redirect',
        authUrl       : response.authUrl,
        txId          : response.txId,

        successUrl:`${process.env.REACT_APP_BACK_BASE_URL}/mobile/toss-check?access_token=${response.access_token}&tx_id=${response.txId}`,
        failUrl:`${process.env.REACT_APP_FRONT_BASE_URL}/member-register-fail`
      });
    });
  }

  const authPhone = () => {
    window.sessionStorage.setItem("authType", "M");
    window.location.href=`${process.env.REACT_APP_BACK_BASE_URL}/mobile/phone-check`;
  }

  const authIPIN = () => {
    window.sessionStorage.setItem("authType", "I");
    window.location.href=`${process.env.REACT_APP_BACK_BASE_URL}/mobile/ipin-check`;
  }

  return (
    <div>
    <TopNavBar/>
    <div className='member-screen'>
      <h2 className='top-title'>웹민원센터 가입절차</h2> 
        <h2 className='step-title'>
    
            <div className='step-number'>2</div>      <span className="step-txt">서비스 이용을 위하여 <br /> 본인 인증을 진행해 주세요.</span> </h2>
        <div className='certi-button-box'>
          <div>
          <button className='certification-button' onClick={() => authToss()}>
            토스 인증  <IoIosArrowForward /> </button>
          </div>

          <div>
          <button className='certification-button' onClick={() => authIPIN()}> 
            아이핀 인증    <IoIosArrowForward /> </button>
        </div>

        <div>
          <button className='certification-button' onClick={() => authPhone()}>
            휴대폰 인증  <IoIosArrowForward />  </button> 
          </div>
        </div>
        <div>
      </div>
    </div>
  </div>
  );
}

export default MemberRegister2;