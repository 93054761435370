const PATHNAME_TITLE_MAP = {
  "member-register": "회원가입",
  "member-register:agree-terms": "회원가입",
  "member-register:agree-policy": "회원가입",
  "member-register2": "회원가입",
  "member-register3": "회원가입",
  "member-search" : "회원정보 찾기",
  "my_page": "마이 페이지",
  "faq": "F A Q",
  "ask": "1:1 문의하기",
  "voice-eye": "증명서 음성확인",
  "ec-pay1" : "증명서 발급",
  "ec-pay2" : "증명서 발급",
  "ec-select1": "증명서 발급",
  "ec-select2": "증명서 발급",
  "ec-select2/sebu": "증명서 발급",
  "ec-select3": "증명서 발급",
  "ec-select/hakbun": "학번 정보 입력",
  "ec-select/hakbun2": "학번 정보 입력",
  "ec-select/fail": "증명서 발급",
  "basket-list": "증명서 보관함",
  "basket-early":"증명서 보관함",
  "reject-certi":"증명서 보관함",
  "basket-del":"증명서 전송",
  "history-list":"발급내역",
  "customer-support":"고객지원",
  "customer-consult":"고객지원",
  "guide":"이용 가이드",
  "notice":"공지사항",
  "notice-all":"공지사항",
  "setting" : "설정",
  "setting/policy-terms":"약관 및 방침",
  "setting/privacy":"약관 및 방침",
  "setting/terms":"약관 및 방침",
  "setting/member":"회원 정보 관리",
  "setting/member/password":"회원 정보 관리",
  "setting/member/password-change":"회원 정보 관리",
  "setting/member/phone-change":"회원 정보 관리",
  "setting/member/withdraw":"회원 정보 관리",
  "notification-settings":"설정",
  "aut" : "진위 확인",
  "aut/early" : "진위 확인"
  
};

export default PATHNAME_TITLE_MAP;
