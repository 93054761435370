import React from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import TopNavBar from '../TopNavBar';
import './board.css';
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useState } from 'react';
import { useEffect } from 'react';
import TopNavChat from '../../views/TopNavChat';

const BoardDetail = () => {
  const [noticeData, setNoticeData] = useState([]);
  const [prevNotice, setPrevNotice] = useState();
  const [nextNotice, setNextNotice] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const currentIndex = parseInt(id, 10) - 1;
  const prevIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;

  const navLink = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetch('/data/noticeInfo.json')
      .then((response) => response.json())
      .then((response) => setNoticeData(response));
  }, []);

  useEffect(() => {
    setPrevNotice(prevIndex >= 0 ? noticeData[prevIndex] : null);
    setNextNotice(nextIndex < noticeData.length ? noticeData[nextIndex] : null);
  }, [id, noticeData]);

  const moveNotice = (type) => {
    if(type === "prev"){
      navigate(`/notice-all/detail/${prevIndex + 1}`);
    } else if (type === "next") {
      navigate(`/notice-all/detail/${nextIndex + 1}`);
    }
  }
  return (
    <div>
      { noticeData.length > 0 && (
      <>
      <div className="navbar-container">
      <div className="navbar-inner">
        <div className="nav" onClick={navLink}>
          <span className="arrow-icon">
            <IoIosArrowBack />
          </span>
        </div>
        <p className="menu-title">공지사항</p>
        <TopNavChat />
        </div>
      </div>
      <div className="NoticeDetail" id="NoticeDetail">
        <div className="detail-container">
          <div>
          <p className="detail-container-header-title">{noticeData[currentIndex].title}</p>
         
         <div className="detail-container-header-content">
                    <p className="detail-container-header-author">
                     {noticeData[currentIndex].author}
                    </p>
                    <p className="detail-container-header-date">
                      {noticeData[currentIndex].date}
                    </p>
                  <div>
                    <p className="detail-container-header-view"></p>
                  </div>
         </div>
                <div className="detail-container-contents">
                  <div
                    className="detail-container-header-contents"
                    dangerouslySetInnerHTML={{
                      __html: noticeData[currentIndex].content,
                    }}
                  ></div>
                </div>
  
 
          </div>
        </div>
        <div className="prev-next-links">
                    {prevNotice && (
                      <div
                        className="prev-link"
                        onClick={()=>moveNotice('prev')}
                      >
                        <IoIosArrowUp  id='arrow-next'/>
                        <p> 이전 글 : {prevNotice.title}</p>
                      </div>
                    )}
                    {nextNotice && (
                      <div
                        className="next-link"
                        onClick={()=>moveNotice('next')}
                      >
                    <IoIosArrowDown id='arrow-next' />  
                     <p>다음 글 : {nextNotice.title}</p>
                      </div>
                    )}
                  </div>
        <Link to={'/notice-all'} className="detail-first-list-link">
          <button className="detail-first-list">목록</button>
        </Link>
      </div>
      </>
      )}
    </div>
  );
};

export default BoardDetail;
