import React from 'react';
import TopNavBar from '../../components/common/TopNavBar';
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import TopNavChat from '../../components/views/TopNavChat';


const MMECFAL = () => {
  const navigate      = useNavigate();
  let { msg }         = useLocation().state || {}; 

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  const handleButtonClick = (page) => {
    navigate(`/${page}`);
  };

  const navLink = () => {
      navigate('/main');
  };

  return (
    <div>
      <div className="navbar-container">
      <div className="navbar-inner">
      <div className="nav" onClick={navLink}>
          <IoIosArrowBack  className="arrow-icon"/>
      </div>
      <p className="menu-title" id='issue-top-menu'>본인 인증 정보</p>
        <TopNavChat/>
    
      </div>
 
    </div>
        <div className="section-screen">
        { msg !== null && msg !== undefined ? (
          <>
            <div className="customer-box" style={{marginTop : '30px', textAlign : 'center'}}>
              <h2 class="hakjuk-txt" dangerouslySetInnerHTML={{ __html: msg }}></h2>
            </div>
          </>
        ) : (
          <>
            <div className="section-title">
              <h2 className='foam-title'>학적 정보 확인</h2>
            </div>
            <div className="customer-box" >
              <h2 class="hakjuk-txt" >현재 고객님의 본인인증 정보와 일치하는 학적정보를&nbsp;
              <span class="hakjuk-txt" id='special'>
              {giwan.giwanName}
              </span> 
              에서 찾을 수 없습니다.</h2>
              <div className="info-box">
              <div className="info-row">
                  <div className="info-label">이름</div>
                  <div className="info-value">{user.userName}</div>
              </div>
              <div className="info-row">
                <div className="info-label">생년월일</div>
                <div className="info-value">{user.userBirth}</div>
              </div>
            </div>
        </div>
          </>
        )}
       
        </div>
        <ClickButtonBig onClick={() => handleButtonClick("main")} buttonText="홈으로 가기" />
        </div>
  );
};

export default MMECFAL;