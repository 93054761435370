import React from 'react';
import "./popup.css"

const TwoButtonPopupF = ({onClick, onClick2, popupText,popupText2,popupText3,popupText4,buttonText,buttonText2,buttonId,buttonId2}) => {
    return (

<div className="popup-container">
            <div className="popup-box-big">
            <div className="popup-in">
              <div className="popup-content" id='no-margin-bottom'>
                <h3> {popupText}</h3>
                <h3> {popupText2}</h3>
                <h3> {popupText3}</h3>
                <h3> {popupText4}</h3>
                </div>
              </div>
                <div className="popup-btn-box-big" id='margin-top20' >
                <button  onClick={onClick} className="popup-button-sm1" id={buttonId}>{buttonText}</button>
                <button  onClick={onClick2} className="popup-button-sm2" id={buttonId2}>{buttonText2}</button>
                </div>
            </div>
          </div>

    );
};

export default TwoButtonPopupF;