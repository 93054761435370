import React from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../../components/common/Buttons/CllickButtonBig';



const SplashScreen3 = () => {
    const navigate = useNavigate();

    const handleButtonClick = page => {
        navigate(`/${page}`);
      };

    return (
        <div className='splash-screen'>
            <div className="top-section">
                <div className="logo"><img src="img/common/logo_WEBMINONE.png" alt="logo" /></div>
                <div className="progress-bar">
                    <div className="progress-circle" ></div>
                    <div className="progress-circle" ></div>
                    <div className="progress-circle" id='progressing'></div>
                </div>
            </div>
            <div className="middle-section">
                <div className="splash-text-1">더 많은 서비스는 <br />웹사이트에서!</div>
                <div className="splash-img" id='splash-3'> 
                 <img src="img/Onboarding/splash3.png" alt="SPLASH" />
                </div>
                <div className="splash-text-2">웹사이트에서 증명서 출력, 번역 및 공증, <br />우편 배송 서비스를 제공하고 있어요.</div>
            </div>

<ClickButtonBig onClick={() => handleButtonClick("permission")} buttonText="시작하기" />
        </div>
    );
};

export default SplashScreen3;