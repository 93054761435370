import React, { useEffect, useState } from 'react';
import { connectAxios } from '../../module/connectAxios';
import TopNavBar from '../../components/common/TopNavBar';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const MMNOLST = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [isLoading, setIsLoading]   = useState(true);
  const [activeNoti, setActiveNoti] = useState(true);

  
  const [displayCount, setDisplayCount] = useState(10);

  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  const toggleNoti = (index) => {
    setActiveNoti(activeNoti === index ? null : index);
  };

  const loadMoreItems = () => {
    setDisplayCount(displayCount + 10);
  };

  useEffect(() => {
    async function getNoticeList() {
      const response = await connectAxios.get('/notice', {
        params: {            
          giwanNo: giwan.giwanNo,
          type: 'popup',
        }
      });
      const noticeList = response.data.noticeList;

      setNoticeList(noticeList);
      setIsLoading(false);
    }
    getNoticeList();

    setActiveNoti('0');
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div>
      <TopNavBar />
        <div className="pagetip" id='margin33'>
<p>{giwan.giwanName} 웹민원센터의 최근 소식을 알 수 있습니다.</p>
        </div>
<div className="customer-screens" id='no-margin3'>
      {/* <h2 className='foam-title'>공지사항</h2> */}

      {noticeList && noticeList.length > 0 ? (
  <div className='noti-screen'>
    {/* {noticeList.map((notice, index) => (
      <div key={index} className="noti-box">
        <div className="consultation-title">
          <p className='custom-date'>{notice.regDate}</p>
          <p className='custom-reply'>{notice.regContent}</p>
        </div>
      </div>
    ))} */}
  {/* {faqData[faqKey].map((item, subIndex) => ( */}
  {noticeList.slice(0, displayCount).map((notice, index) => (
                    <div key={index} className="customer-box">
                      <div className="faq-question" onClick={() => toggleNoti(`${index}`)}>
                        {activeNoti === `${index}` ? (
                          <IoIosArrowUp className="arrow-icons" />
                        ) : (
                          <IoIosArrowDown className="arrow-icons" />
                        )}
                {/* <p className='custom-title'
                 dangerouslySetInnerHTML={{ __html: ` ${notice.regContent}` }}
                 ></p> */}
                <p className='custom-date'>  {notice.regDate}</p>
            
                     
                      </div>
                      {activeNoti === `${index}` && (
                        <div
                          className="faq-answer"
                       
                          dangerouslySetInnerHTML={{ __html: ` ${notice.regContent}` }}  ></div>
                      )}
                    </div>
                  ))}
     {displayCount < noticeList.length && (
        <button className="click-big-button-A" onClick={() => setDisplayCount(displayCount + 10)}>
          더보기 ({displayCount}/{noticeList.length})
        </button>
          )}
  </div>
) : (
  <div className='no-reply' id='no-gonggi'>공지사항이 없습니다.</div>
)}
</div>
    </div>
  );
};

export default MMNOLST;