import React from 'react';
import { Link } from 'react-router-dom';
import TopNavBar from '../../components/common/TopNavBar';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

const MemberSetting = () => {
  return (
    <div>
   
    <TopNavBar/>
    <div className="section-screen">
    <h2 className="foam-title" > 내 정보 관리</h2>
  
    <div className="settings-container">

      <ul className="settings-list">
        {/* <li>
          <Link to="/notification-settings">
            <i className="fas fa-bell"></i> 알림 설정
          </Link>
        </li> */}
        <li id="member-set">
          <Link to="/setting/member/password-change"  className='setting-li'>
            <p className='setting-txt'>비밀번호 변경</p>
            <IoIosArrowForward />
          </Link>
          
        </li>
        <li id="member-set">
          <Link to="/setting/member/phone-change" className='setting-li'>
           <p  className='setting-txt'>휴대폰 번호 변경</p>          
           <IoIosArrowForward />
          </Link>

        </li>
        <li id="member-set">
          <Link to="/setting/member/withdraw" className='setting-li'>
          <p className='setting-txt'>회원탈퇴 </p>          
           <IoIosArrowForward />
          </Link>

        </li>
      </ul>
      </div>

    </div>
    </div>
  );
};

export default MemberSetting;