import { react } from "@babel/types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";


// 다음 화살표 함수

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // 다음 화살표 CSS를 핸들링 하는 코드
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
};


// 이전 화살표 함수
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // 이전 화살표 CSS를 핸들링 하는 코드
      style={{ ...style, display: "none", background: "black" }}
      onClick={onClick}
    />
  );
};

// 슬라이더 컴포넌트
const SliderComponent = () => {
    const navigate                                = useNavigate();
    
  // 슬라이더의 기능을 설정할 수 있는 코드
  const settings = {
    dots: true, // 점을 표시하는 옵션
    infinite: true, // 슬라이드가 끝까지 갔을때 반복하는 옵션
    slidesToShow: 1, // 한번에 보이는 슬라이드 갯수
    slidesToScroll: 1, // 한번에 넘어가는 슬라이드 갯수
    speed: 2000, // 슬라이드 넘어갈때 걸리는 시간
    autoplay: true, // 자동으로 슬라이드 넘기는 옵션
    autoplaySpeed: 3000, // 자동으로 슬라이드가 넘어가는 시간
    // nextArrow: <SampleNextArrow />, // 다음 화살표 옵션
    // prevArrow: <SamplePrevArrow />, // 이전 화살표 옵션
  };

  const handleClickCall = ()=>{
    document.location.href='tel:1644-2378'
  
  }

  const handleButtonClick = (page) => {
    navigate(`/${page}`);
  }

  const handleClickLink1 = () => {

window.location.href = 'https://www.medcerti.com';
  };
  const handleClickLink2 = () => {
  
window.location.href = 'https://blog.naver.com/webminwon_ct';
  };

  return (
    <div className="stSlide">
      <Slider {...settings}>
      <img src="img/Main/banner_kakao_2.png" alt="apple-kakao" className="slide-img" />
      <img src="img/Main/banner_kakao_3.png" alt="banner1" className="slide-img" />
      <img src="img/Main/banner7.png" alt="banner1" className="slide-img" onClick={handleClickCall}/>
      {/* <img src="img/Main/banner9.png" alt="banner1" className="slide-img"  onClick={handleClickLink2}/> */}
      {/* <img src="img/Main/banner2.png" alt="banner2" className="slide-img" onClick={() => handleButtonClick('aut')}/> */}
      {/* <img src="img/Main/banner4.png" alt="banner4" className="slide-img" onClick={() => handleButtonClick('guide')}/> */}

      </Slider>
    </div>
  );
};

// const StSlide = styled.div`
//   margin: auto;
//   padding-left: 50px;
//   padding-right: 50px;
//   border: 1px solid black;
// `;

export default SliderComponent;