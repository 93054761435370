export const MAP_DATA_GUIDE = [
  {
  id: 1,
  text: '신청 및 발급',
  name: 'certi',
  },
  {
  id: 2,
  text: '다운로드 및 전송',
  name: 'delivery',
  },
  ];