import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaExclamationCircle, FaXing } from "react-icons/fa";
import TopNavBar from '../../components/common/TopNavBar';

const MMECPAYF = () => {
  return (
    <div id='screen' className='finish-screen'>

      <React.Fragment>
        <div id='middle-top' className="middle-container">
        <div id='icon-container'>
        <FaExclamationCircle size={100} color="#328AE6" id='icon'/>
        </div>
        <h3 className="middle-txt">증명서 발급을 실패하였습니다.</h3>
        <p className="small-txt"> 일시적인 오류가 발생하였습니다. <br /> 잠시 후에 신청해주세요.</p>
        </div>
        <div className="home-button-container">
        <Link to='/main'><button className="home-btn">
              <span>홈으로 가기</span>
          </button></Link>
     
      
        </div>
      </React.Fragment>
    </div>
  );
}


export default MMECPAYF;
 