import React from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../../components/common/Buttons/CllickButtonBig';



const SplashScreen2 = () => {
    const navigate = useNavigate();


        const handleButtonClick = page => {
        navigate(`/${page}`);
      };

    return (
        <div className='splash-screen'>
            <div className="top-section">
                <div className="logo"><img src="img/common/logo_WEBMINONE.png" alt="logo" /></div>
                <div className="progress-bar">
                    <div className="progress-circle" ></div>
                    <div className="progress-circle" id='progressing'></div>
                    <div className="progress-circle"></div>
                </div>
            </div>
            <div className="middle-section">
                <div className="splash-text-1">증명서 보관부터 전송까지 <br />모바일로 간편하게 </div>
                <div className="splash-img"> 
                 <img src="img/Onboarding/splash2.png" alt="SPLASH" />
                </div>
                <div className="splash-text-2">모바일로 나의 증명서를 다운로드하고,<br /> 
                                                쉽게 제출까지 할 수 있어요.</div>
            </div>

    <ClickButtonBig onClick={() => handleButtonClick("splash3")} buttonText="다음" />

        </div>
    );
};

export default SplashScreen2;