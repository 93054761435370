import React from 'react';
import "./loading.css"

const Loadings = (
{loadingTxt, loadingTxt1}
) => {
    return (
        <div id='screen' className='finish-screen'>
            <div id='loading-container'>
                <img src='/img/common/loading.gif' alt='Loading' className='loading-gif' />
                <div className="loding-div">
            <p className='loading-info'>{loadingTxt}</p>
            <p className='loading-info'>{loadingTxt1}</p>
            </div>
            </div>
        </div>
    );
};

export default Loadings;  