import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { connectAxios } from '../../../module/connectAxios';
import ClickButtonBig from '../../common/Buttons/CllickButtonBig';
import TopNavBar from '../../common/TopNavBar';

const MMBAREJ = () => {
  const navigate      = useNavigate();
  const { state }     = useLocation();
  const { basket }    = state;
  const [basketList, setBasketList]       = useState([]);
  const [ isPageVisible, setIsPageVisible]= useState(true);

  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));
  
  async function getBasketList() {
    //tpid 새로 세팅되었을 수도 있으니 한번 더 가져옴
    const user      = JSON.parse(sessionStorage.getItem('userInfo'));
    const response  = await connectAxios.get('/basket', {
      params: {
        tpid          : user.tpid,
        lgnQryType    : giwan.lgnQryType,
        giwanNo       : giwan.giwanNo
      }
    });

    var result = response.data;
    if(result.resultCode === "200"){
      setBasketList(result.basketList);
    }
    setIsPageVisible(true);
  }


  const handleButtonClick = page => {
      navigate(`/${page}`);
  };

  return (
    <div className='basket'>
    <TopNavBar/>

    <React.Fragment>
    {isPageVisible && (<div className='history-screen'>
      <div>
        <h5 className='foam-title'>반송안내</h5>
        <div className="info-box">

<div className="info-row">
  <div className="info-label">증명서 명</div>
  <div className="info-value">{basket.jobDisplayNe}</div>
</div>

 
<div className="info-row">
  <div className="info-label">발급일자</div>
  <div className="info-value">{basket.minRegDate}</div>
</div>
<div className="info-row">
  <div className="info-label" >반송사유</div>
  <div className="info-value" id='reject-value'>{basket.retReason}</div>
</div>

</div>
      </div>
      <div className="guide-line-area">
          
          {/* <h2 className="guide-txt">[안내 사항] </h2> */}
      
          <ul className='guide-line-list'>
              <li className='guide-line'><span>•</span><p> 증명서 신청시 결제하신 내역은 자동취소 되었습니다.</p></li>
          </ul>
          </div>
          {/* <div className="popup-btn-box-big" id='marginTop' >
                <button onClick={() => handleButtonClick("basket-list")} className="popup-button-sm1" id="{buttonId}">뒤로가기</button>
                <button  onClick="" className="popup-button-sm2" id="{buttonId2}">반송확인</button>
                </div> */}
    </div>
    )}
        </React.Fragment>
               
    <ClickButtonBig onClick={() => handleButtonClick("basket-list")}  buttonText={"확인"}></ClickButtonBig>

    </div>
  );
};

export default MMBAREJ;