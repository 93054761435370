import React from "react";
import "./clickButtonBig.css?ver=1"

const BottomTwoButton = ({ onClick1, onClick2, buttonText1,buttonText2,buttonId }) => {
  return (

    <div className="bottom-two-container-1">
    <button type="button" onClick={onClick1} className="popup-button-m1" id={buttonId}>
      {buttonText1}
    </button>
    <button type="button" onClick={onClick2} className="popup-button-m2" id={buttonId}>
      {buttonText2}
    </button>
    </div>
  );
};

export default BottomTwoButton;

