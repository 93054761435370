import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';
import { PiEye, PiEyeSlash } from 'react-icons/pi';

const PasswordChange = () => {
  const navigate                              = useNavigate();
  const [newPassword, setNewPassword]         = useState('');
  const [password, setPassword]               = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [popupText, setPopupText]             = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState('');
  const [showPopup, setShowPopup]             = useState(false);
  const [user, setUser]                       = useState(false);
  const { changeYN }                          = useLocation().state || "N";
  const changeType  = 'P';
  const [pwType, setpwType] = useState({
    type: "password",
    visible: false,
  });

  const handlePasswordType = (e) => {
    setpwType(() => {
    // 만약 현재 pwType.visible이 false 라면
      if (!pwType.visible) {
        return { type: "text", visible: true };
  
  //현재 pwType.visible이 true 라면
      } else {
        return { type: "password", visible: false };
      }
    });
  };

  useEffect(()=>{
    setUser(JSON.parse(sessionStorage.getItem('userInfo')));
    if(changeYN === "Y"){
      sessionStorage.removeItem('userInfo');
    }
  }, []);
  
  const handleNewPasswordChange = (e) => {
    const pw = e.target.value;
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;
    
    // 기존 비밀번호와 새 비밀번호 비교
    if (pw === password) {
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(regex.test(pw));
    }
  
    setNewPassword(pw);
  };
  const handleCancel = () => {
    // 팝업창 닫기
    
    if (popupText === "새로운 비밀번호로 로그인해주세요.") {
      // 사용자 정보 초기화
      sessionStorage.removeItem("userInfo");
      // 페이지 이동
      setShowPopup(false);
      navigate('/login');
    } else if (popupText === "다시 로그인해주세요.") {
      sessionStorage.removeItem("userInfo");
      window.location.href=`${process.env.REACT_APP_FRONT_BASE_URL}/login`;
    } else {
      setShowPopup(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  
  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };
  
  const handleButtonClick = async () => {
    if (passwordConfirm && isPasswordValid){
      let response = await connectAxios.put('/user',{
        type        : changeType,
        userMail    : user.userMail,
        oldPassword : password,
        newPassword : newPassword,

      });
  
      let resultCode  = response.data.resultCode;
  
      if(resultCode === "200"){
        setShowPopup(true);
        setPopupText('새로운 비밀번호로 로그인해주세요.');
      } else if(resultCode === "203"){
        setShowPopup(true);
        setPopupText('기존 비밀번호가 일치하지 않습니다.');
      } else if(resultCode === "205"){
        setShowPopup(true);
        setPopupText('다시 로그인해주세요.');
      } else {
        setShowPopup(true);
        setPopupText('새로운 비밀번호를 확인해주세요.');
      }
    } else {
      setShowPopup(true);
      setPopupText('새로운 비밀번호를 확인해주세요.');
    }
  };
  

  const isPasswordMatch = newPassword === passwordConfirm;
  const passwordConfirmStyle = passwordConfirm === '' || isPasswordMatch
    ? { borderColor: passwordConfirm === '' ? '#C5C5C5' : 'green' }
    : { borderColor: 'red' };
    
    const isButtonDisabled = 
    !newPassword || 
    !password || 
    !isPasswordMatch || 
    !isPasswordValid;

    const buttonId = isButtonDisabled ? 'buttonDisabled' : '';
  
    return (
    <div >
        <TopNavBar/>
<div className="section-screen">
        <h2 className='foam-title'>비밀번호 변경</h2>
     <div className='password-forms'>
       <div className='member-join-form-list'>
        <p className='join-form-text'>아이디 확인</p>
        <div className="id-form-container">
        <label htmlFor="input-id3" className='blind'>아이디 확인 </label>
        <input type="id-checked" id='input-id3' placeholder={user.userMail} disabled={true}/>
        </div>
       </div>
       <div className='member-join-form-list'>
       <p className='join-form-text'>기존 비밀번호</p>
       <label htmlFor="input25" className='blind'>기존 비밀번호 </label>
      <input 
      type="password" 
      value={password}
      placeholder="기존 비밀번호를 입력해 주세요." 
      id='input25'
      onChange={handlePasswordChange} />

       </div>

       <div className='member-join-form-list'>
       <p className='join-form-text margin5'>새로운 비밀번호</p>
       <label htmlFor="input26" className='blind'>새로운 비밀번호 </label>
       <input
  type="password"
  id='input11'
  placeholder="비밀번호를 입력해주세요."
  onChange={handleNewPasswordChange}
  style={isPasswordValid === true ? { borderColor: 'green' } : isPasswordValid === false ? { borderColor: 'red'} : {}}
/>
<p className="no-password" id={isPasswordValid ? 'green' : isPasswordValid === false ? 'red' : newPassword === '' ? 'no' : ''}>
  {isPasswordValid === true
    ? '* 사용가능한 비밀번호입니다.'
    : isPasswordValid === false
    ? '* 8자리 이상의 숫자, 영문자, 특수문자를 조합하여 입력해주세요.'
    : newPassword === '' // 새로운 부분: 비밀번호가 비어있을 때 메시지 표시
    ? '* 8자리 이상의 숫자, 영문자, 특수문자 조합으로 가능합니다.'
    : ''}
</p>   
        {/* <input type="password" 
          value={newPassword}
             id='input26'
      placeholder="변경하실 비밀번호를 입력해 주세요." onChange={handleNewPasswordChange} />
        <p className='no-password'>* 8자리 이상의 숫자, 영문자, 특수문자 조합으로 가능합니다.</p> */}

       </div>
       <div className='member-join-form-list'>
       <p className='join-form-text' id="marginTop05">새로운 비밀번호 확인</p>
       <label htmlFor="input27" className='blind'>새로운 비밀번호 확인 </label>

        <input
          type="password"
          placeholder="비밀번호를 다시 한번 입력해 주세요."
          onChange={handlePasswordConfirmChange}
          style={passwordConfirmStyle}
          id='input27'
        />
               {/* <div className="eye-box" onClick={handlePasswordType}>
              {pwType.visible ? <PiEyeSlash /> : <PiEye /> }</div> */}
              
           {passwordConfirm !== '' && !isPasswordMatch && <p className='no-password' id='red'>* 비밀번호가 일치하지 않습니다.</p>}
           {passwordConfirm !== '' && isPasswordMatch && <p className='no-password' id='green'>* 비밀번호가 일치합니다.</p>}
        </div>

        <ClickButtonBigA
                    className="change-btn-1"
                     onClick={() => handleButtonClick()} buttonText="비밀번호 변경"
                     disabled={isButtonDisabled} 
                     buttonId={buttonId} />
        </div>
        {showPopup && (
        <OneButtonPopup onClick={handleCancel} popupText={`${popupText}`} buttonText="확인"/>
        )}
      </div>
      </div>
    );
};

export default PasswordChange;