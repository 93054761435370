import React from 'react';
import { Link } from 'react-router-dom';
import TopNavBar from '../../components/common/TopNavBar';
import {  IoIosArrowForward } from 'react-icons/io';
import Footer from '../../components/common/Footer';


const Settings = () => {
  const user    = JSON.parse(sessionStorage.getItem('userInfo'));

const handleClickCall = ()=>{
  document.location.href='tel:1644-2378'

}

  return (
    <div>
   
    <TopNavBar/>
    <div className="section-screen">
    <div className="settings-container">
      {user.userTel !== "" && (
        <>
      <h2 className="foam-title" > 나의 설정</h2>
    

      <ul className="settings-list">
      <li>
            <Link to="/setting/member" className='setting-li'>
            <p className='setting-txt'>회원 정보 관리</p>           
            <IoIosArrowForward />
            </Link>

          </li>
          {/* <li>
            <Link to="" className='setting-li'>
            <p className='setting-txt'> 버전 정보</p>  
              <span className="version-number">1.0.0</span>
            </Link>
          </li> */}
        </ul>
      </>
      ) }

<p className="setting-title" id={ user.userTel !== "" ? '' : 'marginTop07' }>약관 및 방침</p>

      <ul className="settings-list" id='setting-ul'>
        {/* <li>
          <Link to="/notification-settings">
            <i className="fas fa-bell"></i> 알림 설정
          </Link>
        </li> */}
              <li>
          <Link to="/setting/terms" className='setting-li'>
           <p className='setting-txt'>이용약관</p>          
           <IoIosArrowForward />
          </Link>

        </li>
        <li>
          <Link to="/setting/privacy" className='setting-li'>
          <p  className='setting-txt'>개인정보처리방침 </p>              
           <IoIosArrowForward />
          </Link>

        </li>
        <li>
          <button onClick={handleClickCall} className='setting-button'>
          <p className='setting-txt'>고객 센터   </p>                 
           <span className='setting-txt'>1644-2378</span>
          </button>

        </li>
</ul>
<p className='no-password' id='bottom-margin'>* 평         일 : 09:00 ~ 18:00 (유료) </p>
      </div>
      <div>
      {/* <address className='company-address'>
<p>( 03925) 서울시 마포구 월드컵북로 396,
누리꿈스퀘어 13층 <br />
대표 : 전정우 | 개인정보보호책임자 : 김현석 <br />
사업자등록번호 : 105-85-34215 <br />
통신판매업신고번호 : 제2012-서울구로-0542호</p>
      </address> */}
      <Footer
 />

      </div>
    </div>
    </div>
  );
};

export default Settings;