import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connectAxios } from '../../module/connectAxios';
import { IoClose, IoSearch } from 'react-icons/io5';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import { IoIosArrowForward } from 'react-icons/io';
import Loadings from '../../components/common/Loading';
import { ReactComponent as Logo } from "../../components/common/Svg/logo1.svg";


const SearchSchool = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [giwanNo, setGiwanNo] = useState('');
  const [giwanList, setGiwanList] = useState([]);
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState('');
  const [isLoading, setIsLoading]         = useState(false);

  const params = new URLSearchParams(window.location.search);


  const handleClickGiwan = async (giwanNe, giwanNo, giwanYN) => {
    setIsLoading(true);
    setSearchText(giwanNe);
    setGiwanNo(giwanNo);

    if (giwanYN === 'N') {
      // giwanYN 가 'N', URL 이동
      window.location.href = `https://uni.webminwon.com/servlet/MBINDEX?COMMAND=LOGIN&GIWANNO=${giwanNo}`;
    } else if (giwanYN === 'Y') {
      // giwanYN 가 'Y', 페이지이동
      
      const response = await connectAxios.get("/giwan-info", { params : {giwanNo : giwanNo}});
      const giwan = { giwanNo: giwanNo, giwanName: response.data.giwanNe };
      sessionStorage.setItem('giwanInfo', JSON.stringify(giwan));

      navigate('/login');
    } else if (giwanYN === 'X') {
      setIsLoading(false);
      // giwanYN 가 'X'일 경우, 팝업
      setShowPopup(true);
      setPopupText('모바일에서는 서비스 이용이 불가합니다. PC로 이용해주시기 바랍니다.');
    }
  };

  const goNotice = (giwanNo, giwanNe, giwanYN) => {
    const giwan = { giwanNo: giwanNo, giwanName: giwanNe, giwanYN: giwanYN };
    sessionStorage.setItem('giwanInfo', JSON.stringify(giwan));

    navigate('/login')
  };

  const handleBackButtonClick = () => {
    navigate('/intro');
  };

  useEffect(() => {
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('giwanInfo');

    async function getGiwanList() {
      const response = await connectAxios.get('/giwan');
      const giwanList = response.data.giwanList;
      setGiwanList(giwanList);
    }

    getGiwanList();
  }, []);

  useEffect(()=>{
    if(giwanList.length !== 0){
      //URL 직접 접속시
      if(params.get("giwanNo") !== null && params.get("giwanNo") !== '' && params.get("giwanNo").length === 6){
        const paramGiwanNo = params.get("giwanNo");

        //테스트용
        if("020000" === paramGiwanNo){
          goNotice("020000", "다몬대학교");
          return;
        }
        
        const foundGiwan = giwanList.find(item => item.giwanNo === paramGiwanNo);

        if (!foundGiwan) {
          setIsPageVisible(true);
        } else {
          goNotice(foundGiwan.giwanNo, foundGiwan.giwanNe);
        }
      } else {
        setIsPageVisible(true);
      }
    }
  }, [giwanList]);
  
  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
};

// const handleSearchInputChange = (e) => {
//   const inputValue = e.target.value;
//   const filteredText = inputValue.replace(/[()]/g,''); // 괄호를 제거
//   setSearchText(filteredText);
// };

const handleSearchInputChange = (e) => {
  const inputValue = e.target.value;
  // ()를 -로 치환
  const filteredText = inputValue.replace(/\(\)/g, '');
  setSearchText(filteredText);
};
const highlightSearchText = (text, searchText) => {
  if (!searchText) {
    return text;
  }

  // 괄호를 제거
  searchText = searchText.replace(/[()]/g, "");

  // 검색어와 일치하는 부분을 강조하기 위해 <span> 태그로 감쌀 수 있습니다.
  const pattern = new RegExp(`(${searchText})`, "gi");

  return text.split(pattern).map((part, index) =>
    part.match(pattern) ? (
      <span style={{ color: "#328AE6" }} key={index}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

  return (
    <React.Fragment>
    {isLoading && (
      <React.Fragment>
        <Loadings/>
      </React.Fragment>
    )}
    {isPageVisible && (
    <div className="search-school-screen">
      <div className="top-section">
      <div className="logo">
        <Logo />
          </div>
      <div className="logo-box-search">
      {/* <MainChat className="main-chat"/> */}
      <button className='goback' id='gobackgo' onClick={()=> handleBackButtonClick('search-school')}>
        <IoClose style={{ color : '#bbb' }} /></button>
     </div>
      </div>
      <div className="middle-section">
          <p className="text-3">증명서 서비스 이용을 원하시는 <br /> 학교를 먼저 검색해 주세요.</p>
      
        {/* <div className="img">
          <img src="img/Onboarding/image96.png" alt="logo" />
        </div> */}
      </div>
      <label htmlFor="input33" className='blind'>학교 찾기</label>

      <div className='search-results'>
      <div className="search-input-box" id="intro-search"
       style={{marginTop: "0"}}>
        <IoSearch style={{ color : '#328AE6' }} />
        <input
          value={searchText}
          type="search"
          name='searchText'
          onChange={handleSearchInputChange}
          placeholder="학교명을 입력해 주세요."
          id='input33'
          autoFocus 
          pattern="[^()]*"
          autoComplete='off'
          />
          </div>
          {giwanList.filter((giwan) => giwan.giwanNe.toLowerCase().includes(searchText.toLowerCase())).length > 0 && (
  <div className="search-results-wrapper">
    <ul className="select-results-container">
      {!searchText && giwanList.map((giwan) => (
        <li
          className="search-results-list"
          key={giwan.giwanNo}
          onClick={() => handleClickGiwan(giwan.giwanNe, giwan.giwanNo, giwan.giwanYN)}
        >
          <p className="search-results-list-p">{giwan.giwanNe} </p>
          <IoIosArrowForward style={{ color : '#328AE6' }} />
        </li>
      ))}
      {searchText && giwanList
        .filter((giwan) => giwan.giwanNe.toLowerCase().includes(searchText.toLowerCase()))
        .map((giwan) => (
          <li
            className="search-results-list"
            key={giwan.giwanNo}
            onClick={() => handleClickGiwan(giwan.giwanNe, giwan.giwanNo, giwan.giwanYN)}
          >
            <p className="search-results-list-p"> {highlightSearchText(giwan.giwanNe, searchText)} </p>
            <IoIosArrowForward style={{ color : '#328AE6' }} />
          </li>
        ))}
    </ul>
  </div>
)}

      </div>

      {showPopup && (
      <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
      )}
    </div>
    )}
    </React.Fragment>
  )
}

export default SearchSchool;