import React from 'react';
import { useNavigate } from "react-router-dom";
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';


function MemberRegisterFail() {
  const navigate                              = useNavigate();

  const handleCancel = () => {
    // 팝업창 닫기
    navigate('/login');
  };

  return ( 
      <>
      <OneButtonPopup onClick={handleCancel} popupText="가입된 회원정보가 존재합니다." buttonText="로그인"/>
      </>
  );
}

export default MemberRegisterFail;