import React, { useState } from 'react';
import TopNavBar from '../../components/common/TopNavBar';

const NotificationSettings = () => {
  const [pushNotification, setPushNotification] = useState(false);
  const [paymentNotification, setPaymentNotification] = useState(false);
  const [certificateNotification, setCertificateNotification] = useState(false);
  const [inquiryNotification, setInquiryNotification] = useState(false);

  const togglePushNotification = () => {
    setPushNotification(!pushNotification);
  };

  const togglePaymentNotification = () => {
    setPaymentNotification(!paymentNotification);
  };

  const toggleCertificateNotification = () => {
    setCertificateNotification(!certificateNotification);
  };

  const toggleInquiryNotification = () => {
    setInquiryNotification(!inquiryNotification);
  };

  return (
    <div className="notification-settings-container">
      <TopNavBar/>

      <h2 className="notification-settings-title">알림 설정</h2>
      <div className="notification-item">
        <div className="notification-item-icon" onClick={togglePushNotification}>
          {pushNotification ? (
            <i className="fas fa-check-circle"></i>
          ) : (
            <i className="far fa-circle"></i>
          )}
        </div>
        <div className="notification-item-label">모든 푸시 알림</div>
      </div>
      <div className="notification-item">
        <div className="notification-item-icon" onClick={togglePaymentNotification}>
          {paymentNotification ? (
            <i className="fas fa-check-circle"></i>
          ) : (
            <i className="far fa-circle"></i>
          )}
        </div>
        <div className="notification-item-label">결제 내역 알림</div>
      </div>
      <div className="notification-item">
        <div className="notification-item-icon" onClick={toggleCertificateNotification}>
          {certificateNotification ? (
            <i className="fas fa-check-circle"></i>
          ) : (
            <i className="far fa-circle"></i>
          )}
        </div>
        <div className="notification-item-label">증명서 발급 알림</div>
      </div>
      <div className="notification-item">
        <div className="notification-item-icon" onClick={toggleInquiryNotification}>
          {inquiryNotification ? (
            <i className="fas fa-check-circle"></i>
          ) : (
            <i className="far fa-circle"></i>
          )}
        </div>
        <div className="notification-item-label">1:1 문의 답변 알림</div>
      </div>
    </div>
  );
};

export default NotificationSettings;