import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


const MainIndex = () => {

  window.location.href=`${process.env.REACT_APP_FRONT_BASE_URL}/intro`

  return (
      <div>
        {/* <Link to ="/search-school">
          <button className='loading-button'>
          
            <div className="logo"><img src="img/common/logo_WEBMINONE.png" alt="logo" /></div>
            </button>
        </Link> */}
      </div>
  );
};

export default MainIndex;