import React from 'react';
import { Link } from 'react-router-dom';

const NoticeItem = ({ item }) => {
  return (
    <div >
        
      <Link to={'/notice-all/detail/' + item.id}  className='noti-wrap'>
        
         <div className='notice-intro-title'> {item.title}</div>
         <div className='notice-intro-date'>{item.date}</div>

      </Link>
    </div>
  );
};

export default NoticeItem;
