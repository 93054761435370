import React from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';

const PermissionAgreement = () => {
    const navigate = useNavigate();

    const handleButtonClick = page => {
        navigate(`/${page}`);
      };

    return (
        <div className="screen">
        <div className='permission-pop-up'>
            <div className="top-section">
             <p className='text-1'>서비스 이용을 위해 앱 접근 권한을 허용해 주세요. </p>
            </div>
            <div className="middle-section">
                <ul className="permission-list">
                    <li className="app-record">
                        <div className="permission-icon">
                            <img src="img/Onboarding/phone.png" alt="icon" />
                        </div>
                        <p className='text-2'>기기 및 앱 기록 <br /><span className='text-3'>서비스 개선 및 앱 버전 확인</span> </p>
                    </li>
                    <li className="app-file">
                        <div className="permission-icon">
                        <img src="img/Onboarding/folder.png" alt="icon" />
                        </div>
                        <p className='text-2'>파일 접근 <br /><span className='text-3'>증명서 내 파일 다운로드</span> </p>
                    </li>
                    <li className="app-camera">
                        <div className="permission-icon">
                        <img src="img/Onboarding/camera.png" alt="icon" />
                        </div>
                        <p className='text-2'>카메라 <span className='text-4'>(선택) </span> <br /><span className='text-3'>보이스아이 실행</span> </p>
                    </li>
                    <li className="app-recognization">
                        <div className="permission-icon">
                        <img src="img/Onboarding/faceid.png" alt="icon" />
                        </div>
                        <p className='text-2'>생체인식 <span className='text-4'>(선택) </span> <br /><span className='text-3'>Face ID, 지문인식 인증 (지원 단말기에 한함)</span> </p>
                    </li>
                    {/* <li className="app-alarm">
                        <div className="permission-icon">
                        <img src="img/Onboarding/alarm.png" alt="icon" />
                        </div>
                        <p className='text-2'>알림 <span className='text-4'>(선택) </span> <br /><span className='text-3'>푸시 알림 및 증명서 열람 안내</span> </p>
                    </li> */}
                </ul>
            </div> 
            <div className="container">
            <p className="text-5">* </p>
            <p className='text-5'>선택적 접근 권한을 허용하지 않아도 앱 사용은 가능하나, 
<br/>  일부 서비스 이용에 제한이 있을 수 있습니다. </p>
</div>    </div>
        <ClickButtonBig onClick={() => handleButtonClick("search-school")} buttonText="다음" />

        </div>
    );
};

export default PermissionAgreement;