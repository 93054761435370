import React from "react";
import "./modal.css?ver=1";
import { IoIosArrowForward } from "react-icons/io";
import { FaEnvelope, FaFax } from "react-icons/fa";

const ClickBottomModal = ({
  modalText,
  modalOpen,
  OnClickKKO,
  OnClickEml,
  OnClickFax,
  OnClickGov,
  modalClose,
  ecdwYN,
  downYN,
  className,
}) => {

  const handleOutsideClick = (event) => {
      modalClose()
      document.documentElement.style.overflow = 'auto';
  };

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));

  return (
    <>
      <div className={`modalContainer ${modalOpen && "modalView"}`}>
        <p className="text">{modalText}</p>
        <div className="buttonContainer">
          {/* <button onClick={OnClickKKO} id='basket-btn' >
            <img src="img/common/kakao.png" alt="" id='basket-btn-img' />
            <p className="modal-btn-txt">카카오톡 전송</p>
            <IoIosArrowForward size={"26px"} />
          </button>
          <button onClick={OnClickEml} id='basket-btn' >
            <FaEnvelope color="#328AE6" size={"26px"}/>
            <p className="modal-btn-txt">이메일 전송</p>
            <IoIosArrowForward size={"26px"} />
          </button> */}
          <button onClick={OnClickFax} id='basket-btn' >
            <FaFax color="#5AC56A" size={"26px"} />
            <p className="modal-btn-txt">팩스 전송</p>
            <IoIosArrowForward size={"24px"}/>
          </button>
          { user.userCi !== "" && (ecdwYN === "Y" && downYN === "N") && (
            <button onClick={OnClickGov} id='basket-btn' className={className} >
            <img src="img/common/gov.png" alt=""  id='basket-btn-img'  />
              <p className="modal-btn-txt">정부 전자문서지갑 전송</p>
              <IoIosArrowForward size={"26px"}/>
            </button>
          )}
        </div>
      </div>
      <div onClick={handleOutsideClick} className={`modalBackground ${modalOpen && "modalBackgroundView"}`} />
    </>
  );
};

export default ClickBottomModal;