import React from 'react';
import ToggleTab from '../../common/Tab/ToggleTab';


const MMGUDEL = () => {
    const guideDelivery = [
        {
          title: "Step 1",
          description:
            "증명서 보관함 메뉴에서 증명서 발급 내역을 확인할 수 있습니다.",
            img: "/img/guide/2_1.png",
        },
        {
          title: "Step 2",
          description:
            "원하는 증명서를 선택하면 해당 증명서의 미리보기 화면이 출력됩니다. 하단의 [다운로드] 버튼을 누르면 PDF 증명서 다운로드가 가능합니다.",
            img: "/img/guide/2_2.png",
     
          },
        {
          title: "Step 3",
          description:
            "하단의 [전송하기] 버튼을 누르면 팩스 또는 정부 전자문서지갑으로 증명서 전송이 가능합니다.",
            img: "/img/guide/2_3_3.png",

        },
        {
          title: "Step 4",
          description:
            "전송 방법을 선택한 후 결제정보 화면에 표시된 정보가 맞는지 확인하고, 수신자 및 결제자 정보를 입력합니다. 입력이 완료되면 카드 또는 휴대폰 결제 방식을 선택하고, [결제 및 전송하기] 버튼을 누릅니다.",
            img: "/img/guide/2_4_3.png",
        
          },
        {
          title: "Step 5",
          description:
            "선택한 결제수단으로 결제 후 증명서 전송이 완료됩니다.",
            img: "/img/guide/2_5_2.png",

        },

      ];
    

    return (
        <div className='guide-container'> 
              <div className="guide-box-container">
              <ToggleTab tabs={guideDelivery} />
  </div>
        </div>
    );
};

export default MMGUDEL;