import React from 'react';
import ToggleTab from '../../common/Tab/ToggleTab';


const MMGUCER = () => {
    const guideCerti = [
        {
          title: "Step 1",
          description:
            "로그인 후 메인화면 혹은 우측 사이드 메뉴바에서 [증명서 발급] 메뉴를 선택합니다.",
            img: "/img/guide/1_1.png",
        },
        {
          title: "Step 2",
          description:
            "표시된 정보가 맞는지 확인하고 증명서 언어를 선택한 뒤, [다음] 버튼을 누릅니다.",      
            img: "/img/guide/1_2.png",
        
          },
        {
          title: "Step 3",
          description:
            "PDF 발급을 원하는 증명서를 선택합니다.",
            img: "/img/guide/1_3.png",

        },
        {
          title: "Step 4",
          description:
            "표시된 정보가 맞는지 확인하고, 용도/제출처를 입력합니다. 입력이 완료되면 [신청하기] 버튼을 누릅니다.",
            img: "/img/guide/1_4.png",

        },
        {
          title: "Step 5",
          description:
            "표시된 정보가 맞는지 확인하고, 결제자 정보를 입력합니다. 입력이 완료되면 [결제하기] 버튼을 누릅니다.",
        img: "/img/guide/1_5.png",
        
      },
      {
        title: "Step 6",
        description:
          "결제 후 증명서 발급이 완료됩니다. 증명서 확인 및 다운로드/전송 신청은 증명서 보관함에서 가능합니다.",
      img: "/img/guide/1_6.png",
      
    },

      ];
    

    return (
        <div className='guide-container'> 
              <div className="guide-box-container">
              <ToggleTab tabs={guideCerti} />
  </div>
        </div>
    );
};

export default MMGUCER;