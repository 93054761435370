import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';

// 암호화 키와 초기화 벡터 설정
const key         = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY);
const iv          = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_IV);

export function getEncryptParams(params){
  // 원본 데이터를 문자열로 변환
  const entries       = Object.entries(params);
  const stringParams  = entries.map(([key, value]) => `\"${key}\":\"${value}\"`).join(', ');

  // 암호화 수행
  const encrypted = AES.encrypt("{"+stringParams+"}", key, { iv });
  const encString = encrypted.toString();

  const encryptParams = { "data" : encString};

  return encryptParams;
};

export function getDecryptParams(params){
    // 암호화된 데이터를 바이트 배열로 변환
  const encryptedBytes = CryptoJS.enc.Base64.parse(params);

  // 복호화 수행
  const decrypted = CryptoJS.AES.decrypt({ciphertext: encryptedBytes,},key,{ iv });

  // 복호화된 데이터를 문자열로 변환
  const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

  const entries   = decryptedData.split('&').reduce((obj, keyValue) => {
    // 첫 번째 '='만 분리하기 위한 정규식
    const regex = /=/;
    const param = keyValue.split(regex);

    const key = param[0];
    const value = param.slice(1).join('=');
    
    if (!obj) {
      obj = {}; 
    }
    obj[key] = value;

    return obj;
  }, {});

  return entries;
};
