import React from 'react';
import "./popup.css"

const OneButtonPopup = ({onClick, popupText,popupText2,buttonText,buttonId}) => {
    return (

<div className="popup-container">
            <div className="popup-box">
              <div className="popup-in">
              <div className="popup-content">
                <h3 dangerouslySetInnerHTML={{ __html: popupText }}></h3>
                <h3 dangerouslySetInnerHTML={{ __html: popupText2 }}></h3>
              </div>
                <button  onClick={onClick} className="popup-button" id={buttonId}>{buttonText}</button>
              </div>
            </div>
          </div>

    );
};

export default OneButtonPopup;