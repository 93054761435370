import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import "./footer.css"

const ToggleFooter =  ({ isOpen }) => {

    const [isContentOpen, setIsContentOpen] = useState(isOpen);
  
    const navigate                                = useNavigate();

    const handleButtonClick = (page) => {
        navigate(`/${page}`);
      }
    
    
    const toggleContent = () => {
      setIsContentOpen(!isContentOpen);
    };
    return (

        <div className="address-wrapper" >
            
            {/* <!-- 국문 --> */}
            <address className="address-container">
                         
             <div className="toggle">
             <div className="toggle-header"  style={{ marginLeft: '0px' }}>
             <div className='address-top'>
            <div className="text-4" onClick={() => handleButtonClick('setting/privacy')}>
            개인정보처리방침</div>
            <div className="text-4" onClick={() => handleButtonClick('setting/terms')}>
            이용약관</div>
            </div>     
            <div className='address-top' id='toggle-header'>
        <div className="text-4"  onClick={toggleContent}>
                <p>사업자 정보          </p>
            </div>   
          {isContentOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}

                </div>
        </div>
        {isContentOpen && (
          <div className="footer-toggle-body">
             <dl>
                    <dt>대표이사</dt>
                    <dd>조태묵</dd>
                    <dt>개인정보보호책임자</dt>
                    <dd>김윤규</dd>
                    </dl>
                <dl>
                    <dt>통신판매업신고</dt>
                    <dd>제2016-성남분당-0366호</dd>
                   
                </dl>
                <dl>
                <dt>사업자등록번호</dt>
                    <dd>110-81-41568</dd>
                </dl>
                <dl>
                <dt>주소</dt>
                <dd>13453 경기도 성남시 수정구 금토로 69, 6층<br/>(금토동, 다우디지털스퀘어)</dd>
                </dl>
                <dl>
                    <dt>고객지원센터</dt>
                    <dd>1644-2378(유료)</dd>
                    <dt>FAX</dt>
                    <dd>02-6008-3017</dd>
                 
                </dl>
                <dl>
                <dt>E-MAIL</dt>
                    <dd>cert@signgate.com</dd>
                </dl>
          </div>
        )}
        </div>
             
              <p>ⓒ 2023. KICA inc. All rights reserved.</p> 
            </address>


        </div>
    );
};

export default ToggleFooter;


