import React, { useEffect, useState } from 'react';
import TopNavBar from '../../components/common/TopNavBar';
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import { useNavigate } from 'react-router-dom';
import TwoButtonPopup from '../../components/common/Popup/TwoButtonPopup';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';


const Withdraw = () => {
    const navigate                          = useNavigate();
    const [popupText, setPopupText]         = useState('');
    const [showPopup, setShowPopup]         = useState(false);
    const [showTwoPopup, setShowTwoPopup]   = useState(false);
    const [password, setPassword]           = useState('');

    const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };

    const handleCancel = () => {
        // 팝업창 닫기
        setShowPopup(false);

        if(popupText === '회원 탈퇴 되었습니다.') {
          navigate('/login');
        };
    };
      
    const handleCancelNo = () => {
      // 팝업창 닫기
      setShowTwoPopup(false);
    };
    const handleYes = async () => {
      // 팝업창 닫기
      setShowPopup(false);

      let response = await connectAxios.delete('/user', {
        data : {
          userMail : user.userMail,
          password : password,
        }
      });
  
      let resultCode  = response.data.resultCode;
  
      setShowTwoPopup(false);
      
      if(resultCode === "200"){
        setShowPopup(true);
        setPopupText('회원 탈퇴 되었습니다.');
        sessionStorage.removeItem("userInfo");
      } else if(resultCode === "201"){
        setShowPopup(true);
        setPopupText('입력한 비밀번호가 일치하지 않습니다.');
      } else if(resultCode === "202"){
      setShowPopup(true);
      setPopupText('회원 탈퇴에 실패했습니다.');
      } 
    };

    const handleButtonClick = () => {
      if (setPassword){
        setShowTwoPopup(true);
      };
    }
    const isButtonDisabled =  !password ;
    const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

    return (
        <div>
        <TopNavBar />
        <div className="section-screen">
        <h2 className='foam-title'>회원 탈퇴</h2>
  
        <div className="section-containers">
          <p className="section-titles">비밀번호 입력</p>          
          <label htmlFor="input30" className='blind'> </label>
          <input type="password" 
          onChange={handlePasswordChange}  
          value={password}
          id='input30'
          placeholder="기존 비밀 번호를 입력해주세요." />
          <div className="guide-line-area">
      <h2 className="guide-txt">[유의사항] </h2>
  
      <ul className='guide-line-list'>
          <li className='guide-line'><span>•</span><p>회원 탈퇴는  비밀번호 입력 후 가능합니다.</p></li>
          <li className='guide-line'><span>•</span> <p>회원 탈퇴 시, 등록된 게시물은 자동으로 삭제됩니다.</p></li>
      </ul>
      </div>
          <ClickButtonBig 
          onClick={() => handleButtonClick()}  
          buttonText="탈퇴하기"
          buttonId={buttonId}
          disabled={isButtonDisabled}/>

        </div>
        </div>

        {showPopup && (
        <OneButtonPopup 
        onClick={handleCancel} 
        popupText={`${popupText}`} 
        buttonText="확인"/>
        )}
        
        {showTwoPopup && (
        <TwoButtonPopup  
         onClick={handleYes}  
         onClick2={handleCancelNo}
         popupText="회원 탈퇴를 하시겠습니까?" 
         popupText2="" 
         buttonText="예" 
         buttonText2="아니요"/>
        )}

      </div>
    );
};

export default Withdraw;