import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import PATHNAME_TITLE_MAP from "../../../constans/pathnameTitleMap";
import "./topNavBar.css?ver=2"

const LogoTop = () => {
  const pathname = useLocation().pathname.slice(1);
  const navigate = useNavigate();

  const navLink = () => {
    navigate("/");
  };

  return (
    <div className="logobar-container">
   
                <div className="logo-top"><img src="/img/common/logo_WEBMINONE.png" alt="logo" /></div>
      
    </div>
  );
};

export default LogoTop;
