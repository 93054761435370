import React, { useEffect, useState, useRef } from "react";
import "./modal.css?version=2";
import ButtomButtonModal from "../Buttons/ButtomButtonModal";

const ClickBottomOption = ({
  modalOpen,
  OnClickOption,
  optionValues,
  certificate,
  modalClose,
}) => {
  const handleOutsideClick = (event) => {
    modalClose()
    document.documentElement.style.overflow = 'auto';
};
  const [selectOptValues, setSelectOptValues] = useState([]);
  const [selectOptType, setSelectOptType]     = useState([]);
  const [optHidden, setOptHidden]             = useState([]);
  const [error, setError]                     = useState([]);
  const [optCustomTxt, setOptCustomTxt]       = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const selectOptRef = useRef();

  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  const OnClickOptionChk = () => {

    //형태 바뀌면 안됨! 수정 금지. 직접입력
    if(optCustomTxt !== null){
      if(giwan.giwanNo !== '021101'){
        if(optCustomTxt.length !== 4){
          setError({isDisplay: true, message: '* 4자리를 입력해주세요.'});
          return;
        }
      } else {
        if(optCustomTxt.length !== 5){
          setError({isDisplay: true, message: '* 5자리를 입력해주세요.'});
          return;
        }
      }
    }

    //셀렉 & 체크박스 (동국대)
    if(giwan.giwanNo === "021132" && selectOptValues.length !== 0 && selectedCheckboxes.length !== 0){
      const joinOpt = setSelectOptValue();
      const checkedOptValues = setCheckOptValue();
      OnClickOption(joinOpt + "@" + checkedOptValues[checkedOptValues.length-1]);   
      return;
    }

    //체크박스
    if(selectedCheckboxes.length !== 0){
      const checkedOptValues = setCheckOptValue();
      OnClickOption(checkedOptValues[0]);   
      return;
    };
    
    //셀렉박스
    if(selectOptValues.length !== 0){ 
      const joinOpt = setSelectOptValue();
      OnClickOption(joinOpt); 
      return;
    }
  }

  const setSelectOptValue = () => {
    //값 저장
    setOptValue();

    const sortedSelectOptValues = Object.keys(selectOptValues)
    .sort((a, b) => parseInt(a.replace('opt', '')) - parseInt(b.replace('opt', '')))
    .reduce((obj, key) => {
      if(selectOptValues[key] === 'N') {
        obj[key] = '';
      } else {
        obj[key] = selectOptValues[key];
      }
      return obj;
    }, {});
    
    const joinOpt = Object.values(sortedSelectOptValues).join('@');

    return joinOpt;
  }

  const setCheckOptValue = () => {
      const checkedOptValues = optionValues.reduce((acc, values) => {
        const checkedValues = values.optList
        .filter((list, subIndex) => selectedCheckboxes.includes(`checkbox${subIndex + 1}`))
        .map((checkedList) => checkedList.certOptVal);

      if (checkedValues.length > 0) {
        const concatenatedValue = checkedValues.join('|');
        return [...acc, concatenatedValue];
      } else {
        return acc;
      }
    } ,[]);

    return checkedOptValues;
  }

  //값 저장
  const setOptValue = () =>{
    for(let i = 0; i < optionValues.length; i ++){
      try{
        let values = document.getElementsByName(`opt${i}`)[0].value.split("@");
        
        //국민대 값 저장일 경우
        if("021110" === giwan.giwanNo){
          const result = optionValues.reduce((acc, obj, index) => {
            if (obj.optList && obj.optList.find(item => item['certOptType'] === '3')) {
              acc.foundObject = obj;
              acc.index = index;
            }
            return acc;
          }, { foundObject: null, index: -1 });

          if(result.foundObject && selectedCheckboxes.length === 0){
            setSelectOptValues(selectOptValues => ({
              ...selectOptValues,
              [`opt${i}`] : values[0],
              [`opt${result.index-1}`] : "NONE"
            }));
          } else {
            setSelectOptValues(selectOptValues => ({
              ...selectOptValues,
              [`opt${i}`] : values[0],
              [`opt${result.index-1}`] : optionValues[result.index-1].optList[0].certOptVal
            }));
          }
        } else {
          setSelectOptValues(selectOptValues => ({
            ...selectOptValues,
            [`opt${i}`] : values[0]
          }))
        }
      } catch {
      }
    }
  }

  const handleOptSelect = (e) => {
    setOptValue();
    
    let values = e.target.value.split("@");
   
    setSelectOptType(selectOptType => ({
      ...selectOptType,
      [e.target.name] : values[1]
    }));

    let firstOpt = e.target.name + '-select';
    document.getElementById(firstOpt).style.display = "none";
  }

  // 직접입력 유효성 처리
  const handleCustomOptSelect = (e) => {
    const inputValue = e.target.value;
    const index = `opt${e.target.name.replace("custom", "")}`;
  
    //숫자만 입력 가능
    const regex = /^[0-9]+$/;
    let sanitizedValue = "";
  
    if (!regex.test(inputValue)) {
      sanitizedValue = inputValue.replace(/[^0-9]/g, '');
    } else {
      sanitizedValue = inputValue;
    }
    setOptCustomTxt(sanitizedValue);
    // 수정된 값으로 state 업데이트
    setSelectOptValues(selectOptValues => ({
      ...selectOptValues,
      [index] : sanitizedValue
    }));
  };

  const handleCheckboxChange = (checkboxId) => {
    const index = selectedCheckboxes.indexOf(checkboxId);
    
    if (index === -1) {
      setSelectedCheckboxes([...selectedCheckboxes, checkboxId]);
    } else {
      const newCheckboxes = [...selectedCheckboxes];
      newCheckboxes.splice(index, 1);
      setSelectedCheckboxes(newCheckboxes);
    }
  };

  useEffect(()=>{
    if("021110" === giwan.giwanNo){ //셀렉박스도 있으므로 세팅
      setOptValue();
    }
  }, [selectedCheckboxes])

  //스크립트 option 값 넘기기
  useEffect(() => {
    if (optionValues.length !== 0){
      if (giwan.giwanNo === "022609" && 
      optionValues.some(function (optValue, index) {if(optValue.optDisplayText === "년도/학기선택") {
        setOptHidden(optHidden => ({...optHidden, [index] : false}));
        return true
      } 
      setOptHidden(optHidden => ({...optHidden, [index] : true }));
      return false
      })){
        setSelectOptValues({'opt0' : ''});
      } else {
        optionValues.some(function (optValue, index) {
          setOptHidden(optHidden => ({...optHidden, [index] : true}));
        })
      }
    }

  }, [optionValues, certificate]);

  useEffect(()=>{
    if (selectOptRef.current != selectOptValues) {
      if (giwan.giwanNo === "022609" && certificate.jobNo === "10109" && selectOptValues['opt0'] === "3"){
        setOptHidden(optHidden => ({...optHidden, [1] : true }));
      } else if (giwan.giwanNo === "022609" && certificate.jobNo === "10109" && selectOptValues['opt0'] === ''){
        setOptHidden(optHidden => ({...optHidden, [1] : false }));
      } else if (giwan.giwanNo === "022609" && certificate.jobNo === "10109" && selectOptValues['opt0'] !== "3"){
        setOptHidden(optHidden => ({...optHidden, [1] : false }));
        setSelectOptValues(selectOptValues => ({
          ...selectOptValues,
          [`opt1`] : 'N'
        }))
      }
      
      selectOptRef.current = selectOptValues;
    }

  },[selectOptValues]);

  const getPlaceHolderText = () => {
    let text = '';

    if(giwan.giwanNo === "024306" && (certificate.jobNo === "10109" || certificate.jobNo === "20109" || certificate.jobNo === "30109")) {
      text = '년도 입력(예:2012)';
    } else if (giwan.giwanNo === "024414" && (certificate.jobNo === "20109" || certificate.jobNo === "20110" || certificate.jobNo === "20111" || certificate.jobNo === "20112" || certificate.jobNo === "20209" || certificate.jobNo === "20210" || certificate.jobNo === "20211" || certificate.jobNo === "30109" || certificate.jobNo === "30110" || certificate.jobNo === "30111" || certificate.jobNo === "30209" || certificate.jobNo === "40112" || certificate.jobNo === "40212")) {
      text = '예) 학교제출용, 금융기관제출용';
    } else if(giwan.giwanNo === "021101" && (certificate.jobNo === "10102" || certificate.jobNo === "10116" || certificate.jobNo === "20116" || certificate.jobNo === "40116" || certificate.jobNo === "17116")) {
      text = '2012년1학기=>20121';
    } else {
      text = '년도 입력(예:2012)';
    }
    
    return text;
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // 스크롤 이벤트 추가
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      overflow-y: hidden;
      width: 100%;
      bottom: ${scrollY}px;`;
    
    return () => {
      document.body.style.cssText = '';
    };
  }, [scrollY]);
  
  //셀렉박스 분리
  let selectOptionValues = optionValues.filter(option =>
    option.optList.length > 0 && option.optList[0].certOptType !== '3'
  );
  //체크박스 분리
  let chkOptionValues = optionValues.filter(option =>
    option.optList.length > 0 && option.optList[0].certOptType === '3'
  );

  let areAllOptionsSelected = selectOptionValues.every((values, index) => selectOptValues[`opt${index}`] !== '' && selectOptValues[`opt${index}`] !== undefined);
  let areAllCheckSelected = "021110" === giwan.giwanNo ? areAllOptionsSelected : selectedCheckboxes.length !== 0;
  
  //예외처리 (동의대 교육비 납입 증명서)
  areAllOptionsSelected = "022609" === giwan.giwanNo && certificate.jobNo === "10109" && 
                          ((selectOptValues['opt0'] === "3" && selectOptValues['opt1'] === "N") || (selectOptValues['opt0'] === "")) ? false : areAllOptionsSelected;

  //총합
  let areAllSelected = 
  selectOptionValues.length !== 0 && chkOptionValues.length !== 0 ? areAllOptionsSelected && areAllCheckSelected : 
  selectOptionValues.length !== 0 ? areAllOptionsSelected :
  chkOptionValues.length !== 0 ? areAllCheckSelected : 
  false;

  const isButtonDisabled = !areAllSelected;

  // 버튼 활성화 여부를 결정하는 함수
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

  return (
    <>
      <div className={`modalContainer ${modalOpen && "modalView"}`}>
        <div className="buttonContainer" id="option-modal">
          <div className="modal-inner">
          {giwan.giwanNo === "024201" && (certificate.jobNo === "10107" || certificate.jobNo === "10207") && (
        <div>
        <h2 className='foam-title'  style={{ marginTop: 0 }}>석차 / 백분율 선택  <p className='tip-text'>(석차 선택은 졸업생만 가능)</p></h2>
       
        </div>
)}
          {optionValues.map((values, index) => (
            <React.Fragment key={`optContainer${index}`}>
            { optHidden[index] && values.optDisplayText !== '' ? (
              <>
              <div className="text-label" key={`optDiv${index}`}>
                {values.optDisplayText}
              </div>

              <label htmlFor="option-select" className='blind'>증명서 옵션</label>
              {/* 직접입력 옵션만 띄우기 */}
              {values.optList[0].certOptType === '2' ? (
                <>
                    <input type="text" 
                    name={`custom${index}`}
                    id='input1312'
                    value={optCustomTxt} 
                    placeholder={getPlaceHolderText()}
                    onChange={handleCustomOptSelect}
                    maxLength={giwan.giwanNo === "021101" ? 5 : 4} />
                    {error.isDisplay && (
                    <p className="error-message-option">{error.message}</p>)
                  }
                </>
              ) : values.optList[0].certOptType === '3' ? (
                // 체크박스 유형 (숙명여대, 인제대, 동국대)
                <>
                  {values.optList.map((list, subIndex) => (
                    <>
                      <div className="check-list">
                      <input
                        type="checkbox"
                        className="ec-sel-checkbox"
                        id={`checkbox${subIndex+1}`}
                        checked={selectedCheckboxes.includes(`checkbox${subIndex + 1}`)}
                        onChange={() => handleCheckboxChange(`checkbox${subIndex+1}`)}
                        value={list.certOptVal}
                      />
                      <label htmlFor={`checkbox${subIndex+1}`}>{list.certOptTxt}</label>
                      </div>
                    </>
                  ))}
                  </>
              ) : (
                //셀렉박스 유형
                <select className={`dropdown ${index === optionValues.length-1 ? 'option-box-bottom' : 'option-box'}`}
                key={`select${index}`} 
                name={`opt${index}`} onChange={handleOptSelect} id='option-select'>
                  <option id={`opt${index}-select`} value="">선택해주세요.</option>
                  {values.optList.map((list, subIndex) => (
                    <option key={`optval${index}${subIndex}`} value={list.certOptVal+ "@" + list.certOptType}>{list.certOptTxt}</option>              
                  ))}          
                </select>
              )}
              {/* 셀렉박스에서 직접입력 옵션시 따로 생성 */}
              {selectOptType[`opt${index}`] === '2' && (
                <>
                    <input type="text" 
                    name={`custom${index}`}
                    id='input1312'
                    value={optCustomTxt} 
                    placeholder={getPlaceHolderText}
                    onChange={handleCustomOptSelect}
                    maxLength={giwan.giwanNo === "021101" ? 5 : 4} />
                    {error.isDisplay && (
                    <p className="error-message-option">{error.message}</p>)
                  }
                </>
                )}
                </>

              //빈값
              ) : values.optDisplayText === '' && (
                <select className={`dropdown ${index === optionValues.length-1 ? 'option-box-bottom' : 'option-box'}`} style={{display : "none"}}
                key={`select${index}`} 
                name={`opt${index}`} onChange={handleOptSelect} id='option-select' >
                  <option id={`opt${index}-select`} value="NONE"></option>
                </select>
              )
              }
            </React.Fragment>
          ))}
        </div>
        <ButtomButtonModal 
          onClick ={() => OnClickOptionChk()} 
          buttonText="선택완료" 
          buttonId={buttonId} 
          disabled={isButtonDisabled} />
        </div>
      </div>
      <div onClick={handleOutsideClick} className={`modalBackground ${modalOpen && "modalBackgroundView"}`} />
    </>
  );
};

export default ClickBottomOption;