import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';

const PhoneChange = () => {
  const navigate                              = useNavigate();
  const [newUserTel, setNewUserTel]           = useState('');
  const [isValidate, setIsValidate]           = useState(false);
  const [password, setPassword]               = useState('');
  const [popupText, setPopupText]             = useState('');
  const [showPopup, setShowPopup]             = useState(false);

  let user          = JSON.parse(sessionStorage.getItem('userInfo'));
  const changeType  = 'M'

  const handleNewPhoneChange = (e) => {
    const phoneNumber = e.target.value;
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 11);
    setNewUserTel(sanitizedPhoneNumber);
  };

  useEffect(()=>{
    if (user.userTel !== newUserTel && newUserTel.length === 11 && password.length > 0){
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }
  }, [newUserTel, password]);

  // const isButtonDisabled = !password ||!newUserTel;
  const buttonId = isValidate ? '' : 'buttonDisabled';

  const handleCancel = () => {
    // 팝업창 닫기
    
    if (popupText === "회원 정보 변경이 완료되었습니다.") {
      // 페이지 이동
      setShowPopup(false);
      navigate(-1);
    } else if (popupText === "다시 로그인해주세요.") {
      sessionStorage.removeItem("userInfo");
      window.location.href=`${process.env.REACT_APP_FRONT_BASE_URL}/login`;
    }else{
      setShowPopup(false);
    }
  };
 
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  
  
  const handleButtonClick = async () => {

    if (password){
      let response = await connectAxios.put('/user',{
        type        : changeType,
        userMail    : user.userMail,
        oldPassword : password,
        newUserTel  : newUserTel,
      });
      let resultCode  = response.data.resultCode;
  
      if(resultCode === "200"){
        setShowPopup(true);
        setPopupText('회원 정보 변경이 완료되었습니다.');
        user.userTel = newUserTel;
        window.sessionStorage.setItem("userInfo",  JSON.stringify(user));
      } else if(resultCode === "203"){
        setShowPopup(true);
        setPopupText('기존 비밀번호가 일치하지 않습니다.');
      } else if(resultCode === "205"){
        setShowPopup(true);
        setPopupText('다시 로그인 해주세요.');
      } 
    }
  };

  return (
    <div >
        <TopNavBar/>
<div className="section-screen">
        <h2 className='foam-title'>휴대폰 변경</h2>
     <div className='password-forms'>
       <div className='member-join-form-list'>
        <p className='join-form-text'>아이디 확인</p>
        <div className="id-form-container">
        <label fhtmlFor="input-id2" className='blind'> </label>
        <input type="id-checked" 
        id='input-id2'

        placeholder={user.userMail} 
        disabled={true}/>
        </div>
       </div>
       <div className='member-join-form-list'>
       <p className='join-form-text'>휴대폰 번호 확인</p>
       <label htmlFor="input-id4" className='blind'> </label>
       <input type="id-checked" 
        placeholder={user.userTel} 
        disabled={true}
        id='input-id4'

        />
        
        </div>
       </div>

       <div className='member-join-form-list'>
       <p className='join-form-text'>새로운 휴대폰 번호</p>
       <label htmlFor="input28" className='blind'> </label>
        <input type="tel" 
        placeholder="휴대폰 번호를 (-)없이 입력해 주세요." 
        value={newUserTel}
        id='input28'
        name="newUserTel"
        onChange={handleNewPhoneChange} 
        style={
          (newUserTel.length > 0 && newUserTel.length < 11)
            ? { borderColor: 'red' }
            : {}
          }
        />
  {newUserTel.length > 0 && newUserTel.length < 11 && (
    <p className="error-message">* 휴대폰 번호는 11자리를 입력해주세요.</p>
  )}
  {newUserTel === user.userTel && newUserTel.length === 11 && (
    <p className="error-message">* 다른 휴대폰 번호를 입력해주세요.</p>
  )}
       </div>
       <div className='member-join-form-list'>
       <p className='join-form-text last'>비밀번호 확인</p>
       <label htmlFor="input29" className='blind'> </label>
        <input
          type="password"
          id='input29'
          name="password"
          placeholder="기존 비밀 번호를 입력해주세요."
          onChange={handlePasswordChange}
          value={password}
        />
        </div>

        <ClickButtonBigA
                    className="change-btn-1" 
        onClick={() => handleButtonClick()} 
        buttonText="휴대폰번호 변경" 
        disabled={!isValidate} 
        buttonId={buttonId} />
        </div>

        {showPopup && (
        <OneButtonPopup 
        onClick={handleCancel} 
        popupText={`${popupText}`} 
        buttonText="확인"/>
        )}
      </div>

    );
};

export default PhoneChange;