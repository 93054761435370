import React, { useEffect, useState } from 'react';
import ClickButtonBig from '../../common/Buttons/CllickButtonBig'
import { connectAxios } from '../../../module/connectAxios';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import Loadings from '../../common/Loading';

const  MMQALIS = () => {
  const navigate                                    = useNavigate();
  const [ qnaList, setQnaList]                      = useState([]);
  const [isLoading, setIsLoading]                   = useState(true);
  const [activeConsultation, setActiveConsultation] = useState(null);
  const [content, setContent]                       = useState('faq');
  const [hakjukChk, setHakjukChk]                   = useState(false);
  let { prevContent, hakbun, hakwi }                = useLocation().state || {}; 

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  const handleButtonClick = page => {
      navigate(`/${page}`);
  };
  async function getHakjukChk() {
    //I-08. 학적여부 확인

    const response = await connectAxios.get('/hakjuk-chk', {
      params: {
        lgnQryType    : giwan.lgnQryType,
        giwanNo       : giwan.giwanNo,
        tpid          : user.tpid,
        userMail      : user.userMail,
        userId        : user.userId,
        hakbun        : user.hakbun !== undefined ? user.hakbun : hakbun,
        userBirth     : user.userBirth,
        userName      : user.userName,
        hakwi         : hakwi
      }
    });
    return response;
  };

  async function sampleTpidChk () {
    const response    = await getHakjukChk();
    const resultCode  = response.data.resultCode;

    hakbun = response.data.hakbun;

    if(resultCode === "200"){
      const newUser = { ...user, tpid : response.data.tpid }
      window.sessionStorage.setItem("userInfo",  JSON.stringify(newUser));
      setHakjukChk(true);

    } else if(resultCode === "201"){
      navigate('/ec-select/fail');
  
    } else if(resultCode === "202"){
      navigate('/ec-select/hakbun', { state : { prevPage : '/customer-support'}});

    } else if(resultCode === "203"){
      navigate('/ec-select/fail', { state : { msg : response.data.resultMessage } });

    } else if(resultCode === "204"){
      navigate('/ec-select/fail', { state : { msg : response.data.displayMessage } });
    }
  }

  //등록 후 넘어왔을 경우 1:1상담으로 이동
  useEffect(() => {
    sampleTpidChk();

    if (prevContent !== undefined){
      setContent(prevContent);
    }
  }, []);

  useEffect(() => {
    if(user && hakjukChk){
      async function getQnaList() {
        try {
          const response = await connectAxios.get('/qna', {
            params: {
              tpid: user.tpid,
              giwanNo: giwan.giwanNo
            }
          });
  
          if(response.data.resultCode === "200"){
            const qnaList = response.data.qnaList;
            setQnaList(qnaList);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
      getQnaList();
    }
  }, [hakjukChk]);

  if(!user) {
    return <div>
      <div className='loading-message'>로그인 후 이용 가능합니다.</div>
      <button className='goback'id='margin-auto' onClick={()=> handleButtonClick('login')}>로그인하러가기 
     </button>
    </div>;
  } else if (isLoading) {
    return  <Loadings
    loadingTxt="1:1 문의 내역을 "
    loadingTxt1="불러오는 중입니다."
/>;
  }

  const toggleConsultation = (num) => {
    if (activeConsultation === num) {
      setActiveConsultation(null);
    } else {
      setActiveConsultation(num);
    }
  };

  return (
    <div id='counslation-list'>
      
      <h2 className='foam-title'>1:1 상담 내역</h2>
      <p className='consulations-num'>나의 상담 총 { qnaList.length}건</p>
    <div className="scroll-container">
      
      { qnaList.length > 0 ? (
        <div>
          
          { qnaList.map((giwan) => (
           <div key={giwan.num} className={`customer-box`}>
              <div
                className="consultation-title"
                onClick={() => toggleConsultation(giwan.num)}
              > {activeConsultation === giwan.num ? (
                <IoIosArrowUp className="arrow-icons" />
              ) : (
                <IoIosArrowDown className="arrow-icons" />
              )}
                <p className='custom-title'>{giwan.title}</p>
                <p className='custom-date'>{giwan.regDate}</p>
                <div className="cate-flex">
                <p className='custom-cate-cate'>{giwan.tcTitle}</p>
                {giwan.replyList.length > 0 ? (
                  <div className="custom-cate" id='custom-cate-finish' >
                    답변 완료
                  </div>
                ) : (
                  <div className="custom-cate"  >
                    답변 미완료
                  </div>
                )}
              </div>
              </div>
              {activeConsultation === giwan.num && (
                    <div className="consultation-details">
                      <div className="btn-box"></div>
                      <div className="answer-box">
                        {giwan.contents} 
                        </div>
                      {giwan.replyList.map((reply) => (
                          <div className="cousultation-box" id='reply-content' dangerouslySetInnerHTML={{ __html: `${reply.split('--------------------------')[1]}`}}></div>
                      ))}
                    </div>
                )}
            </div>
          ))}
        </div>
      ) : (
        <div className='no-reply'>상담 내역이 없습니다.</div>
      )}
  </div>
   <ClickButtonBig onClick={() => handleButtonClick("customer-consult")} buttonText="글쓰기" />


    </div>
  );
};

export default MMQALIS;