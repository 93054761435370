import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import Loadings from '../../components/common/Loading';


const MMECSEL3 = () => {
  const navigate                                = useNavigate();
  const [validationMessage, setValidationMessage] = useState('');
  const [isLoading, setIsLoading]                 = useState(false);
  const { hakjuk, korYN, certificate, option }  = useLocation().state;
  const [showPopup, setShowPopup]               = useState(false);
  const [popupText, setPopupText]               = useState('');
  const [showPopup2, setShowPopup2]             = useState(false);
  const [issueInfo, setIssueInfo] = useState({
    minNo       : '', 
    orderNo     : '', 
    korYN       : korYN,
    purpose     : '',
    receiver    : '',
    jobPrice    : '',
    transPrice  : '',
    totalPrice  : ''
  });
  
  const [selectedPurpose, setSelectedPurpose]           = useState('직접입력');

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  //문구 제한
  const chkKeyword = async () => {
    //I-05. 증명서 가접수
    const response = await connectAxios.get('/keyword-chk', {
        params : {
          keyword : issueInfo.receiver
        }
      }
    );

    if(response.data.resultCode === "200"){
      if(response.data.chkResult === "true"){
        return true;
      }
    }

    return false;
  };

  //가접수
  const certGajubsu = async () => {
    //I-05. 증명서 가접수
    const response = await connectAxios.post('/gajubsu', {
        tpid      : user.tpid,
        giwanNo   : giwan.giwanNo,
        jobNo     : certificate.jobNo,
        hakbun    : hakjuk.hakbun,
        userBirth : user.userBirth,
        userName  : user.userName,
        sangtae   : hakjuk.sangtae,
        minCnt    : '01', //2자리수여야함
        receiver  : issueInfo.receiver,
        purpose   : issueInfo.purpose,
        korYN     : certificate.korYN,
        option    : option,
        hakgwa    : hakjuk.hakgwa,
      }
    );

    return response.data;
  }

  // 화살표 함수로 구현
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  //가접수 확인
  const certGajubsuChk = async (minNo) => {
    //I-05. 증명서 가접수
    const response = await connectAxios.get('/min-state', { params : {
          giwanNo     : giwan.giwanNo,
          lgnQryType  : giwan.lgnQryType,
          minNo       : minNo
        }
      }
    );

    return response.data;
  }

  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
    setShowPopup2(false);
  };

  //용도 고정
  const purposes = ['직접입력', '일반용', '회사제출용', '교육기관용', '의료기관용', '공공기관용', '금융기관용', '비자발급용'];

  //I-06. 증명서 발급
  async function getIssue() {
    try{
      await connectAxios.post('/issue',{
        minNo       : issueInfo.minNo,
        giwanNo     : giwan.giwanNo,
        transCnt    : "1",
        receiver    : issueInfo.receiver,
        purpose     : issueInfo.purpose,
        korYN       : certificate.korYN,
        userName    : user.userName,
        userTel     : ''
      })
      .then((response) => {
        if(response.data.resultCode === "200"){
          navigate(`/ec-pay2`);
        } else if(response.data.resultCode === "500"){
          setIsLoading(false);
          setShowPopup2(true);
          setPopupText("결제 실패하였습니다."); 
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  }

  //가접수 성공했으면
  useEffect(() => {
    if(issueInfo.minNo !== ""){ //무상
      if("111106" == giwan.giwanNo){
        async function getCertList() {
          await connectAxios.post('/issue-pay',{
            tpid        : user.tpid,
            giwanNo     : giwan.giwanNo,
            orderNo     : issueInfo.orderNo,
            lgdPaykey   : '',
            userName    : user.userName,
            jobName     : certificate.jobNe,
            buyerTel    : ''
          })
          .then(async (response) => {
            if(response.data.resultCode === "200"){
              await getIssue();
            }
          });
        }

        getCertList();
      } else { //유상
        navigate(`/ec-pay1` , { 
          state : {
            jobName     : certificate.jobNe,
            issueInfo   : issueInfo
            } 
        });
      }
    }
  }, [issueInfo.minNo]);

  const handleButtonClick = async () => {
    
    setIsLoading(true);
    var chk     = await chkKeyword();
    if (chk){
      var result  = await certGajubsu();

      //가접수 성공
      if(result.resultCode === '200'){

        var minNo       = result.minNo;
        var orderNo     = result.orderNo;
        var jobPrice    = result.jobPrice;
        var transPrice  = result.transPrice;
        var totalPrice  = result.totalPrice;

        //가접수 minStateCd 확인
        while (true){
          var result2 = await certGajubsuChk(minNo);

          if(result2.resultCode === '200'){
            if(result2.reflashTime !== "X") {
              let reflashTime = result2.reflashTime;
              if(reflashTime === "5000"){
                await sleep(reflashTime);
                continue;
              } else if(reflashTime === "3000000"){
                let retReason = result2.retReason;
                setPopupText(retReason);
                setIsLoading(false);
                setShowPopup2(true);
                break;
              }
            } else {
              setIssueInfo(issueInfo => ({...issueInfo, minNo: minNo, orderNo : orderNo, jobPrice: jobPrice, transPrice: transPrice, totalPrice: totalPrice}));

              break;
            }
          }
        }
      }
    } else {
      setIsLoading(false);
      setShowPopup(true);
    }
  };


  const handlePurposeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPurpose(selectedValue);
    if(selectedValue !== "직접입력"){
      setIssueInfo(issueInfo => ({...issueInfo, purpose : selectedValue}));
    } else {
      setIssueInfo(issueInfo => ({...issueInfo, purpose : ''}));
    }
  };

    // 직접입력 유효성 처리
  const handleCustomPurposeChange = (e) => {
    const inputValue = e.target.value;
  
    // 유효성 검사 조건 추가
    let sanitizedValue = inputValue;
  
    // 1. 특수문자 불가능
    sanitizedValue = sanitizedValue.replace(/[@#$%^&*()_+[\]{}|\\:;"'<>,.!?/~`]/g, '');
  
    // 2. '볌'글자 불가능
    sanitizedValue = sanitizedValue.replace(/볌/g, '');
  
    // 3. "%u" 글자는 2자리로 치환
    sanitizedValue = sanitizedValue.replace(/%u/g, '  ');
  
    // "%" 글자는 3으로 나눔
    sanitizedValue = sanitizedValue.replace(/%/g, '   ');
  
   // 한글 최대 자 또는 영어 최대 22자 입력 가능
   if (/[^a-zA-Z]/.test(sanitizedValue)) {
    sanitizedValue = sanitizedValue.substring(0, 8);
  } else {
    sanitizedValue = sanitizedValue.substring(0, 16);
  }


    // 수정된 값으로 state 업데이트
    setIssueInfo({
       ...issueInfo, 
       purpose: sanitizedValue 
      });
  };
  

  // 제출처 유효성 처리
  const handleReceiverChange = (e) => {
    let inputValue = e.target.value;

  // 특수문자 및 '볌' 글자 제거
  inputValue = inputValue.replace(/[@#$%^&*()_+[\]{}|\\:;"'<>,.!?/~`볌]/g, '');

  // 한글 최대 11자 또는 영어 최대 22자 입력 가능
  if (/[^a-zA-Z]/.test(inputValue)) {
    inputValue = inputValue.substring(0, 11);
  } else {
    inputValue = inputValue.substring(0, 22);
  }
    // 수정된 값으로 state 업데이트
    setIssueInfo({
      ...issueInfo,
      receiver: inputValue ,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };


  const isButtonDisabled = !issueInfo.purpose || !issueInfo.receiver;
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';


  return (
    <div id='select-screen'>
      {isLoading && (
        <React.Fragment>
          <Loadings 
          loadingTxt="증명서 발급 중입니다."
          loadingTxt1="잠시만 기다려주세요."
          />
        </React.Fragment>
      )}
      {!isLoading && (
        <React.Fragment>
          <TopNavBar/>
          <div className="select-screen" >
            <div className="section-title">
                <h2 className='foam-title'>증명서 신청정보</h2>
              </div>
            <div>

            <div className="info-box">
                <div className="info-row">
                  <div className="info-label">증명서 종류</div>
                  <div className="info-value">{certificate.jobNe}</div>
                </div>
                <div className="info-row">
                  <div className="info-label">통수</div>
                  <div className="info-value">1 통</div>
                </div>
                <div className="info-row">
                  <div className="info-label">발급형태</div>
                  <div className="info-value">PDF</div>
                </div>
              </div>
              <div className="section-title">
                <h2 className='foam-title'>용도</h2>
              </div>
              <form onSubmit={handleSubmit}>
              <label htmlFor="select2" className='blind'>용도입력</label>

            <select className="dropdown" value={selectedPurpose} id='select2' onChange={handlePurposeChange}>
              {purposes.map((purpose) => (
                  <option key={purpose} value={purpose}>
                    {purpose}
                  </option>
                ))}
              </select>
              {selectedPurpose === '직접입력' && (
              <input type="text" 
              id='input13'
              value={issueInfo.purpose} 
              placeholder="최대 국문 8자, 영문 16자를 입력해주세요."
              onChange={handleCustomPurposeChange} />
            )}
                <label htmlFor="input13" className='blind'>직접 입력</label>  

            {validationMessage && (
              <p style={{ color: 'red' }}>{validationMessage}</p>
            )}  
              </form>
              <div>
                <p className="foam-title">제출처</p>

                <label htmlFor="input14" className='blind'>제출처 입력</label>
                <input type="text" 
                id='input14'
                value={issueInfo.receiver} 
                onChange={handleReceiverChange}
                placeholder="최대 국문 11자, 영문 22자를 입력해주세요." />

              </div>
            </div>
            <span className='tip-text' id='no-padding'>* 해외 기관 제출 시 영문으로 기재</span>
            
            </div>
            <ClickButtonBigA
                  className="change-btn"
            onClick={() => handleButtonClick()} 
            buttonText="신청하기"  
            buttonId={buttonId}  
            disabled={isButtonDisabled}
            />
        </React.Fragment>
      )}

      {showPopup && (
        <OneButtonPopup onClick={handleCancel} popupText="제출처명이 올바르지 않습니다.<br>올바른 예 : 한국정보인증(주), 한국대학교 등 제출기관 실제 명칭<br>잘못된 예 : 기업, 기관 등 실제 명칭이 아닌 일반적인 단어" buttonText="확인"/>
        )}
      {showPopup2 && (
        <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
        )}
    </div>
  );
};

export default MMECSEL3; 

