import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './sideNavigation.css';
import { RiFilePaper2Line } from 'react-icons/ri';
import { RiFolderLine } from 'react-icons/ri';
import { RiDownload2Line } from 'react-icons/ri';
import { AiOutlineAudit, AiOutlineBook, AiOutlineMessage } from 'react-icons/ai';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { RiSettings2Line } from 'react-icons/ri';
import { AiOutlineUser } from 'react-icons/ai';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { IoIosArrowForward } from 'react-icons/io';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { connectAxios } from '../../../module/connectAxios';
import NavChat from '../../views/NavChat';
import axios from 'axios';

const SideNavigation = ({handleNav, isOpen}) => {

  const toggleSidebar = () => {
    isOpen = false;
    handleNav(true);
  };

  return (
    <>
      <div className={`sideNavigation ${isOpen ? 'open' : ''}`}>
        <Sidebar 
            isOpen={isOpen} 
            toggleSidebar={toggleSidebar} 
        />
        <div className="horizontal-line"></div>
        <p className="copyright-txt">
          ⓒ 2023. KICA inc. All rights reserved.
          <br />
        </p>
      </div>
      <div className={`side_overlay ${isOpen ? 'open' : 'close'}`} onClick={toggleSidebar}/>
    </>
  );
};

export default SideNavigation;


const Sidebar = ({ 
  toggleSidebar
 }) => {
  const navigate                  = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [showTwoPopup, setShowTwoPopup]   = useState(false);
  
  //Storage에서 가져오기
  const user    = JSON.parse(sessionStorage.getItem('userInfo'));

  const handleButtonClick = (page) => {
    navigate(`/${page}`);
  };

  const handleClickLogout = async () => {
    sessionStorage.removeItem("userInfo");
    await connectAxios.get("/logout");
    // sessionStorage.removeItem("isAutoLogin");
    setShowTwoPopup(false);
    document.documentElement.style.overflow = 'auto';
    navigate(`/login`);

    // setShowTwoPopup(true);
  };

  const noShowList = !user || !user.userTel;

  return (
    <>
     <div className='side-navigation'>
      <div 
      className="toggle-button" 
      onClick={toggleSidebar}>
        <IoIosArrowForward className="toggle-icon" size={30} />
        <NavChat id="fixed-chat"/>
      </div>
      <span className="user-text">{user !== null ? `${user.userName}님 반갑습니다.` : '로그인 후 이용가능합니다.'}</span>
      <div className="horizontal-line"></div>
      <ul className="menu-list">
        <li>
          <button onClick={() => handleButtonClick('ec-select1')} disabled={!user}>
            <RiFilePaper2Line size={'24px'} />
            <p className="side-text">증명서 발급</p>
          </button>
        </li>
        <li>
          <button onClick={() => handleButtonClick('basket-list')} disabled={!user}>
            <RiFolderLine size={'24px'} />
            <p className="side-text">증명서 보관함</p>
          </button>
        </li>
        <li>
          <button onClick={() => handleButtonClick('history-list')} disabled={!user}>
            <RiDownload2Line size={'24px'} />
            <p className="side-text">발급내역</p>
          </button>
        </li>
      </ul>
      <div className="horizontal-line"></div>
      <ul className="menu-list">
        <li>
          <button onClick={() => handleButtonClick('aut')}>
            <IoCheckmarkCircleSharp size={'24px'} />
            <p className="side-text">진위 확인</p>
          </button>
        </li>
      </ul>
      <ul className="menu-list">
        <li>
          <button onClick={() => handleButtonClick('guide')}>
            <AiOutlineBook size={'24px'} />
            <p className="side-text">이용 가이드</p>
          </button>
        </li>
        <li>
          <button onClick={() => handleButtonClick('customer-support')}>
            <AiOutlineQuestionCircle size={'24px'} />
            <p className="side-text">고객지원</p>
          </button>
        </li>
      </ul>
      <div className="horizontal-line"></div>
      <ul className="menu-list">
      {/* <li>
          <button onClick={() => handleButtonClick('notice')}>
            <AiOutlineMessage size={'24px'} />
            <p className="side-text">공지사항</p>
          </button>
        </li> */}
           
        <li >
          <button onClick={() => handleButtonClick('setting/member')} disabled={noShowList}>
            <AiOutlineAudit size={'24px'} />
            <p className="side-text">회원 정보 관리</p>
          </button>
        </li>
      

        <li>
          <button onClick={() => handleButtonClick('setting/policy-terms')} >
            <RiSettings2Line size={'24px'} />
            <p className="side-text">약관 및 방침</p>
          </button>
        </li>
 
        <li>
          {user ? (
            <button onClick={handleClickLogout}>
              <RiLogoutCircleLine size={'24px'} />
              <p className="side-text">로그아웃</p>
            </button>
          ) : (
            <button onClick={() => handleButtonClick('login')}>
              <AiOutlineUser size={'24px'} />
              <p className="side-text">로그인</p>
            </button>
          )}
        </li>
      </ul>
    </div>
    </>
  );
};