import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from "recoil";
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './hooks/usei18n.js';

const root = ReactDOM.createRoot(document.getElementById('root'));



window.addEventListener('load', function() {
  setTimeout(function() {
    // 페이지가 로드된 후 하단바를 숨깁니다.
    window.scrollTo(0, 1);
  }, 0);
});


root.render(
  <RecoilRoot>
  {/* <React.StrictMode> */}
      <App />
  {/* </React.StrictMode> */}
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
