import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomTwoButton from '../../common/Buttons/BottomtTwoButton';
import ClickBottomModal from '../../common/Modal/ClickBottomModal';
import ViewPDF from './ViewPDF';
import { deliveryState } from '../../../recoil/deliveryState';
import { useRecoilState } from 'recoil';
import OneButtonPopup from '../../common/Popup/OneButtonPopup';
import { connectAxios } from '../../../module/connectAxios';
import TwoButtonPopupF from '../../common/Popup/TwoButtonPopupF';

const MMBAPRV = () => {

  const navigate                                = useNavigate();
  const [modalOpen, setModalOpen]               = useState(false);
  const [isTwoButtonPopup, setIsTwoButtonPopup] = useState(false);
  const [isOneButtonPopup, setIsOneButtonPopup] = useState(false);
  const [onePopupText, setOnePopupText]         = useState(false);
  const [onePopupText2, setOnePopupText2]       = useState(false);
  const [isSendWallet, setIsSendWallet]         = useState(false);
  const [content, setContent]                   = useRecoilState(deliveryState);

  const { state }     = useLocation();
  const { basket }    = state;

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  const handleButtonClick = (content) => {
    if(content === 'gov'){
      setIsTwoButtonPopup(true);
      setModalOpen(false);
    } else {
      navigate(`/basket-del`, {
        state : {
          basket : basket
        }
      });
      setModalOpen(true);
      document.documentElement.style.overflow = 'auto';
      setContent(content);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
    document.documentElement.style.overflow = 'hidden';
  };

  const handleCancelNo = () => {
    setIsTwoButtonPopup(false);
  }

  const handleYes = async () => {
    setIsTwoButtonPopup(false);

    if(isSendWallet){
      setOnePopupText("전자문서지갑 전송 중입니다.");
      setOnePopupText2("잠시만 기다려주세요.");
      setIsOneButtonPopup(true);
      return;
    } 

    setIsSendWallet(true);

    const response = await connectAxios.post("/send", {
      sendType    : 'W',
      lgnQryType  : giwan.lgnQryType,
      minNo       : basket.minNo,
      giwanNo     : giwan.giwanNo,
      tpid        : user.tpid,
      jobNo       : basket.jobNo,
      korYN       : basket.korYN,
      userCi      : user.userCi
    });

    const result = response.data;

    if(result.resultCode === "200"){
      setOnePopupText("전자문서지갑으로 전송 완료되었습니다.");
      setOnePopupText2("정부24 전자문서지갑에서 확인가능합니다.");
      setIsOneButtonPopup(true);
    } else if(result.resultCode === "201") {
      setOnePopupText("전자문서지갑 전송에 실패했습니다.");
      setOnePopupText2("잠시 후 시도해주세요.");
      setIsOneButtonPopup(true);
      setIsSendWallet(false);
    } else if(result.resultCode === "202") {
      setOnePopupText("전자문서지갑이 존재하지 않습니다.");
      setOnePopupText2("정부 24앱에서 전자문서지갑을 발급해주세요.");
      setIsOneButtonPopup(true);
      setIsSendWallet(false);
    } else if(result.resultCode === "203") {
      setOnePopupText("전자문서지갑을 조회할 수 없습니다.");
      setOnePopupText2("웹민원 로그인을 해주세요.");
      setIsOneButtonPopup(true);
      setIsSendWallet(false);
    } else if(result.resultCode === "204") {
      setOnePopupText("다운로드 받은 증명서는");
      setOnePopupText2("전자문서지갑 전송이 불가능합니다.");
      setIsOneButtonPopup(true);
      setIsSendWallet(false);
    } 
  }

  const handleWalletSubmit = () => {
    setIsOneButtonPopup(false);
    if(onePopupText.includes('전송 완료')){
      navigate('/main');
    }
  }

  const handleDownload = async () => {
    // pdf 체크
    const response  = await connectAxios.get('/download-chk', {
      params: {
        giwanNo  : giwan.giwanNo,
        minNo    : basket.minNo
      }
    });

    var result = response.data;
    if(result.resultCode === "200"){
      // B-03. 증명서 다운로드
      const url = `${process.env.REACT_APP_BACK_BASE_URL}/mobile/download?giwanNo=${giwan.giwanNo}&tpid=${user.tpid}&userName=${user.userName}&minNo=${basket.minNo}&korYN=${basket.korYN}`;
      
      const download = document.createElement('a');

      download.href = url;
      download.click();
    } else {
      setOnePopupText('PDF 생성 중입니다.');
      setOnePopupText2('잠시만 기다려주세요.');
      setIsOneButtonPopup(true);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      document.documentElement.style.overflow = 'hidden';
    }
  }, [modalOpen]);


  return (
    <div className='aut-screen'>
    {/* <div style={{height:'66%'}}> */}
    <div className="section-title">    
      <h2 className='foam-title' id='margin37'>미리보기</h2>
        </div>
      <div className="aut-area">
        <ViewPDF minNo={basket.minNo} tpid={user.tpid}/>
      </div>
      {/* <ul className='guide-line-list' id='prev-guide'>
        <li className='guide-line'><span>•</span><p> 안드로이드 : download / 다운로드 폴더</p></li>
        <li className='guide-line'><span>•</span> <p> 아이폰 : 내 파일 / ios 13 이후 부터 가능함</p></li>
    </ul> */}
      <BottomTwoButton
        onClick1={handleDownload}
        onClick2={handleModalOpen}
        buttonText1={'다운로드'}
        buttonText2={'전송하기'}
      ></BottomTwoButton>

      {modalOpen && (
        <ClickBottomModal
          modalText="전송방법을 선택해주세요."
          modalOpen={modalOpen}
          modalClose={()=>setModalOpen(false)}
          ecdwYN={basket.ecdwYN}
          downYN={basket.downYN}
          // OnClickKKO={() => handleButtonClick("kko")}
          // OnClickEml={() => handleButtonClick("eml")}
          OnClickFax={() => handleButtonClick("fax")}
          OnClickGov={() => handleButtonClick("gov")}
          className={user.userCi === "" ? 'blind-btn' : ''}
        />
      )}

      {isTwoButtonPopup && (
        <TwoButtonPopupF  
        onClick={handleCancelNo}  
        onClick2={handleYes}
        popupText="전자문서 지갑으로 전송한 증명서는" 
        popupText2=" 결제 취소가 불가능하며," 
        popupText3="정부 24앱 내 전자문서지갑에서"
        popupText4="확인가능합니다."
        buttonText="취소" 
        buttonText2="전송"/>
      )}

      {isOneButtonPopup && (
        <OneButtonPopup 
        onClick={handleWalletSubmit}  
        popupText={onePopupText} 
        popupText2={onePopupText2} 
        buttonText="확인" />
      )}
    {/* </div> */}
    </div>
  );
};

export default MMBAPRV;