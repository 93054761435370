import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import TopNavBar from '../../components/common/TopNavBar';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';


const MMECSEL2_SEBU = () => {
    const navigate                                  = useNavigate();
    const { state }                                 = useLocation();
    const { hakjuk, korYN, certificate, option }    = state;

    const handleButtonClick = async () => {
      navigate(`/ec-select3`, { 
        state : { 
          hakjuk      : hakjuk,
          korYN       : korYN,
          certificate : certificate,
          option      : option
        } 
      });
    } 

    return (
      <div id='' >
          <TopNavBar/> 
          <div className="select-screen">
            <div className="section-title">
            <h2 className='foam-title'>세부사항</h2>
          </div>
          <div className="info-box">

            <div className="info-row">
              <div className="info-label">학위명칭</div>
              <div className="info-value">{hakjuk.hakwi}</div>
            </div>
            
            <div className="info-row">
              <div className="info-label">증명서 명칭</div>
              <div className="info-value">{certificate.jobNe}</div>
            </div>
            <div className="info-row">
              <div className="info-label">증명서 종류</div>
              <div className="info-value">{korYN === "K"? "국문":"영문"}</div>
            </div>
            
            <div className="info-row">
              <div className="info-label">신청 통수</div>
              <div className="info-value">1 통</div>
            </div>
            <div className="info-row">
              <div className="info-label">학번</div>
              <div className="info-value">{hakjuk.hakbun}</div>
            </div>
     
          </div>

          </div>
          <ClickButtonBigA 
          onClick={() => handleButtonClick("ec-pay1")}
           buttonText="다음"
           className="change-btn" />
      </div>
    );    
  };
        
export default MMECSEL2_SEBU;