import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ClickButtonBig from '../../common/Buttons/CllickButtonBig';
import TopNavBar from '../../common/TopNavBar';


const RegisterTermsUse = () => {
	let   { agreePrivacy }	= useLocation().state || false; 
  	const navigate 			= useNavigate();

	const handleButtonClick = () => {
		navigate('/member-register' , { state : {agreeTerms : true, agreePrivacy : agreePrivacy}});
	};

	return (
		<div>
	     <div className="navbar-container">
      <div className="nav">
        <span className="arrow-icon">
          {/* <IoIosArrowBack /> */}
        </span>
        <span className="nav-text"></span>
      </div>
      <p className="menu-title">회원가입</p>
 
    </div>
		<div className="section-screen">
		<div className="title-box">
			<h2 className="foam-title"> 이용약관</h2>
		</div>
		<div className="terms-use">{/* Your terms use content here */}
		<div id="rcontents">
				<p>
					<span class="named">
						제1조 (목적)
					</span>
				</p>
				<p class="marginTop20">본 약관은 한국정보인증(주)(이하 “회사”)이 운영하는 인터넷 웹사이트 “웹민원센터(www.webminwon.com)”에서 제공하는 모든 서비스(이하 “서비스”)의 이용조건 및 절차, 이용자와 회사의 권리, 의무, 책임 사항과 기타 필요한 사항을 규정함을 목적으로 합니다.</p><br/>
				<p>
					<span class="named">
						제2조 (용어의 정의)
					</span>
				</p>
						<p class="marginTop10">본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에서 정의하지 않은 용어의 의미는 일반적인 거래관행 및 관계법령을 따릅니다.</p>
						<dl class="marginTop10">
							<dt>1. “서비스”란 회사가 운영하는 사이트나 모바일 애플리케이션을 통해 제공하는 인터넷 증명발급 및 증명서 열람 관련 모든 서비스를 말합니다.</dt>	
							<dt>2. “이용자”란 본 약관에 따라 회사가 제공하는 서비스를 이용할 수 있는 회원 및 비회원을 말합니다.</dt>	
							<dt>3. “회원”이란 회사에 개인정보 등 관련 정보를 제공하여 회원등록을 한 개인(재외국민 포함)으로서 회사의 정보를 제공 받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</dt>					
							<dt>4. “비회원”이란 회원에 가입하지 않고, 회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다.</dt>					
							<dt>5. “가입”이란 회사가 제공하는 서비스를 제공 받기 위해 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위를 말합니다.</dt>	
							<dt>6. “해당발급기관”이란 회사와 제휴 관계를 맺고 당사 서비스를 공동 제공하기로 한 법인을 말합니다. 해당발급기관은 웹민원센터 홈페이지에 고지 되어 있으며, 회사 및 해당발급기관의 사정에 따라 해지 또는 추가될 수 있습니다.</dt>	
							<dt>7. “이메일(ID)”이란 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여 등록된 전자우편 주소를 말합니다.</dt>	
							<dt>8. “비밀번호(Password)”란 이용자와 아이디가 일치하는지를 확인하고 통신상 자신의 비밀 보호를 위하여 이용자 자신이 설정하여 웹민원센터에 등록한 문자와 숫자의 조합을 말합니다.</dt>	
							<dt>9. “회원탈퇴”란 회원이 이용계약을 종료시키는 행위를 말합니다.</dt>	
							<dt>10. “환불”이란 회사가 이용자의 요청에 따라 이용자의 결제 범위 내에서 이용자가 요청하는 금액을 이용자가 지정한 금융기관의 계좌로 입금 하는 것을 말합니다.</dt>	
							<dt>11. “수수료”란 이용자가 회사의 서비스를 이용하는 과정에서 회사 및 해당 발급기관이 정하는 기준에 따라 이용자에게 부과하는 이용 비용을 말합니다.</dt>	
						</dl><br />
						
						<p>
							<span class="named">
								제3조 (약관의 명시와 설명 및 개정)
							</span>
						</p>
						
						<dl class="marginTop10">
							<dt>1. 회사는 이용자가 본 약관 내용에 동의하는 것을 조건으로 이용자에게 서비스를 제공할 것이며, 이용자가 본 약관의 내용에 동의하는 경우, 회사의 서비스 제공 행위 및 이용자의 서비스 사용 행위에는 본 약관이 우선적으로 적용됩니다.</dt>	
							<dt>2. 회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소, 소비자 불만을 처리할 수 있는 연락처, 전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 이용자가 쉽게 확인할 수 있도록 웹민원센터의 초기 서비스 화면에 게시합니다. 다만, 약관의 구체적인 내용은 이용자가 팝업화면을 통하여 볼 수 있도록 합니다.</dt>	
							<dt>3. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 개정할 수 있으며, 개정된 약관은 현행 약관과 함께 해당 발급기관 홈페이지 및 회사 내에 공지함으로써 이용자가 직접 확인하도록 할 것입니다. 약관을 개정 할 경우에는 그 적용 일자부터 개정 후 약관이 효력을 지닙니다. 약관 변경 공지 후 이용자가 명시적으로 약관 변경에 대한 거부 의사를 표시하지 아니하면, 이용자가 약관에 동의한 것으로 간주합니다. 이용자가 변경된 약관에 동의하지 아니하는 경우, 이용자는 본인의 회원등록을 취소(회원탈퇴)할 수 있습니다.</dt>	
							<dt>4. 회사가 약관을 개정 할 경우에는 그 개정약관은 그 적용 일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다.</dt>	
						</dl><br />

						<p>
							<span class="named">
								제4조 (약관외 준칙) 
							</span>
						</p>
						<p class="marginTop10">본 약관에서 명시되지 않은 사항과 본 약관의 해석에 관하여는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「전자금융거래법」, 「전자서명법」, 「전기통신기본법」, 「전기통신사업법」, 「프로그램보호법, 「전자상거래 등에서의 소비자 보호에 대한 법률」, 「소비자 기본법」 「약관의 규제 등에 관한 법률」, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자 보호지침」 등의 관계법령과 개인정보처리방침 및 상관례에 따릅니다.</p><br/>
						<p>
						<span class="named">
							제5조 (서비스의 제공 및 변경)
						</span>
						</p>
						<p class="marginTop10">
							회사가 제공하는 서비스는 아래와 같으며, 그 변경될 서비스의 내용을 이용자에게 공지하고 아래에서 정한 서비스를 변경하여 제공할 수 있습니다. <br/>
							① 전자상거래 서비스(통신판매중개서비스 포함) 및 이에 수반 되는 기타 서비스<br/>
							② 회사가 자체 개발하거나 다른 기관과의 협의 등을 통해 제공하는 일체의 서비스
						</p><br/>
					<p>
						<span class="named">
							제6조 (서비스 이용시간)
						</span>
					</p>					
						
					<dl class="marginTop10">
						<dt>1. 서비스 이용시간은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</dt>	
						<dt>2. 제1항의 이용시간은 정기점검 등의 필요로 인하여 회사가 정한 날 또는 시간은 예외로 합니다.</dt>	
					</dl><br />
					
					<p>
						<span class="named">
							제7조 (서비스의 중단)
						</span>
					</p>	
					
					<dl class="marginTop10">
						<dt>1. 회사는 다음 각 호에 해당하는 경우, 그 사유가 해소될 때까지 서비스 제공을 일시적으로 중단할 수 있습니다.</dt>
						<dd>① 기술상의 장애사유로 인한 서비스 중단의 경우(시스템관리자의 고의·과실 없는 디스크장애, 시스템 다운 등)</dd>	
						<dd>② 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지하는 등 통신이 두절 될 경우</dd>	
						<dd>③ 긴급한 시스템 점검, 정보통신설비의 교체 및 고장, 증설 및 교체설비의 보수 등을 위하여 부득이한 경우</dd>	
						<dd>④ 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등 기타 서비스 이용에 지장이 있는 경우</dd>	
						<dd>⑤ 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정되는 경우</dd>	
						<dt>2. 회사가 정상적인 서비스 제공의 어려움 인하여 일시적으로 서비스를 중지하여야 할 경우에는 사전 고지 후 서비스를 중지할 수 있으며, 사전 고지 기간 동안 이용자가 고지내용을 인지하지 못한 데 대하여 회사는 책임을 부담하지 않습니다. 부득이한 사정이 있을 경우 사전 고지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 회사는 책임을 부담하지 않습니다.</dt>
						<dt>3. 회사의 사정으로 서비스를 영구적으로 중단하여야 할 경우 제 2 항에 의거합니다. </dt>
						<dt>4. 회사는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 이용자 또는 제3자에게 어떠한 책임도 부담하지 아니합니다. </dt>
						<dt>5. 회사에 장기간 접속을 하지 아니한 회원의 경우 이메일 또는 공지사항 등을 통한 안내 후 검토 기간을 거쳐 서비스 이용을 중지할 수 있습니다. </dt>
					</dl><br />
						
					<p>
						<span class="named">
							제8조 (회원가입)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 이용자는 온라인으로 회사에서 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하고, 이 약관에 대한 동의를 완료한 경우에 회원가입을 신청합니다. </dt>
						<dt>2. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회사에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</dt>
						<dt>3. 회원이 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 신청서에 기재된 회원 정보를 수집, 이용하는 것에 동의하는 것으로 간주됩니다.</dt>
						<dt>4. 회사는 회원 정보의 진위여부 및 소속기관의 확인 등을 위하여 확인절차를 거칠 수도 있습니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제9조 (회원의 의무) 
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회원 가입시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 귀하에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</dt>
						<dt>2. 회원은 회사의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</dt>
						<dt>3. 회원은 회사 서비스를 이용하여 얻은 정보를 회사의 사전승낙 없이 복사, 복제, 변경, 번역, 출판·방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</dt>
						<dt>4. 회원은 회사 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.</dt>
						<dd>① 다른 회원의 ID를 부정 사용하는 행위</dd>
						<dd>② 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</dd>
						<dd>③ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 사회질서를 해하는 정보를 웹민원센터에 공개 또는 게시하는 행위</dd>
						<dd>④ 타인의 명예를 훼손하거나 모욕하는 행위</dd>
						<dd>⑤ 타인의 저작권 등 지적재산권 등의 권리를 침해하는 행위</dd>
						<dd>⑥ 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)등의 변경, 송신 또는 게시, 해킹행위 또는 컴퓨터바이러스의 유포행위</dd>
						<dd>⑦ 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위</dd>
						<dd>⑧ 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</dd>
						<dt>5. 회원은 본 약관에서 규정하는 사항 및 주의사항을 준수하여야 합니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제10조 (휴면계정 전환 및 회원 탈퇴)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 회원이 자신 계정의 마지막 로그인 일시로부터 1년이 초과되는 시점까지 로그인을 하지 않은 경우, 해당 회원의 계정을 휴면계정으로 전환합니다. 또한 전환과 동시에 회사 개인정보처리방침에 따라 해당 회원의 개인정보는 3년간 보관되며, 해당 기간에 한하여 계정 복구도 가능합니다.</dt>
						<dt>2. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 회원의 탈퇴 요청을 받았을 경우, 웹민원센터를 통하여 회사가 고지한 방법에 따라 신속하게 회원탈퇴를 처리합니다. </dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제11조 (회사의 의무)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 지속적, 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.</dt>
						<dt>2. 회사는 서비스 제공과 관련하여 취득한 이용자의 정보를 본인의 승낙 없이 타인에게 누설 또는 배포할 수 없으며, 상업적 목적으로 사용할 수 없습니다. 다만, 전기통신관련법령 등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.</dt>
						<dt>3. 회사는 이용자가 안전하게 회사 서비스를 이용할 수 있도록 이용자의 개인정보 (신용정보 포함) 보호를 위한 보안시스템을 갖추어야 합니다.</dt>
						<dt>4. 회사는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.</dt>
						<dt>5. 회사는 서비스와 관련한 이용자의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우 이용자에게 그 사유와 처리일정을 회사, 전화, 이메일, 팩스 등으로 통보하여야 합니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제12조 (회원에 대한 통지)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 전자우편(또는 우편), 전화, SMS, 팩스 등의 방법으로 할 수 있습니다.</dt>
						<dt>2. 회사는 불특정다수 회원에 대한 통지를 하는 경우 1주일 이상 회사 내 서비스 화면에 게시함으로서 개별 통지에 갈음 할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제13조 (개인정보보호)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 이용자의 개인정보 수집 시 이용자가 회사 서비스 가입 시 동의하여 제공하는 정보에 한하여 최소한의 개인정보를 수집합니다. </dt>
						<dt>2. 회사가 처리하는 모든 개인정보는 개인정보 보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보보호 및 권익을 보호합니다.</dt>
						<dt>3. 회사는 이용자의 개인정보를 수집/이용할 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</dt>
						<dt>4. 회사는 수집된 개인정보를 목적외의 용도로 이용 할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제 3자에게 제공하는 경우에는 이용/제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관계 법령에 달리 정함이 있는 경우에는 예외로 합니다. </dt>
						<dt>5. 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. </dt>
						<dt>6. 회사는 개인정보보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</dt>
						<dt>7. 회사 또는 그로부터 개인정보를 제공받은 제 3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.</dt>
						<dt>8. 회사는 개인정보의 수집/이용/제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제14조 (회원의 ID 및 비밀번호에 대한 의무)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 이용자가 회사 서비스 가입 절차를 완료하는 순간부터 귀하는 입력한 정보의 비밀을 유지할 책임이 있으며, 회원의 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 회원본인에게 있습니다.</dt>
						<dt>2. ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, 회원의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야 합니다. 신고를 하지 않음으로 인한 모든 책임은 회원 본인에게 있습니다.</dt>
						<dt>3. 이용자는 회사 서비스의 사용 종료시 마다 정확히 접속을 종료하도록 해야 하며, 정확히 종료하지 아니함으로써 제3자가 귀하에 관한 정보를 이용하게 되는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 회사는 책임을 부담하지 아니합니다.</dt>
						<dt>4. 비밀번호 분실 시 통보는 이메일로 안내하며, 전 항의 규정에도 불구하고 회원의 이메일 주소 또는 휴대전화번호 기입 잘못 등 본인 과실 및 본인 정보 관리 소홀로 발생하는 문제의 책임은 회원에게 있습니다.</dt>
						<dt>5. 회원은 개인정보 보호 및 관리를 위하여 서비스의 개인정보관리에서 수시로 개인정보를 수정·삭제할 수 있습니다. </dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제15조 (정보의 제공 및 광고의 게재)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 서비스 운영과 관련하여 이용 중 필요하다고 인정되는 다양한 정보를 게시판에 공지하거나, 서비스화면, 전자우편(또는 우편), SMS, 앱푸쉬 등의 방법으로 광고를 게재할 수 있습니다. 광고가 포함된 전자우편 등을 수신한 회원은 수신을 거부할 수 있습니다.</dt>
						<dt>2. 회원이 전항에 의해 수신을 거부하는 경우, 회사는 서비스 이용에 필수적으로 요구되는 정보(예: 관련 규정/정책의 변경 등)를 홈페이지에 공시하는 방법으로 정보의 제공을 갈음할 수 있으며, 회원이 수신을 거부하고 홈페이지에 공시된 정보의 제공을 확인하지 아니함에 따라 발생하는 손해에 대해서 회사는 책임을 부담하지 않습니다.</dt>
						<dt>3. 회사는 서비스에 적절하다고 판단되거나 활용 가능성 있는 홍보물을 게재할 수 있습니다. </dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제16조 (인터넷 민원발급 서류의 위, 변조에 대한 주의사항)
						</span>
					</p>	
						
					<p class="marginTop10">
						이용자는 웹민원센터에서 발급받은 민원서류에 대해 다음 각호에 해당하는 경우, 대한민국 형법 제225조, 제230조에 의해 10년 이하의 징역 또는 2,000만원 이하의각 죄에 정한 형을 받을 수 있습니다.<br/>
						① PC 화면 또는 출력된 문서를 복사(위조)하여 사용하는 행위<br/>
						② PC 화면 또는 출력된 문서를 수정 또는 변조하여 사용하는 행위<br/>
						③ 기타 전자적 방법에 따른 해킹 등으로 문서를 위,변조하여 사용하는 행위<br/>
					</p><br/>
					
					<p>
						<span class="named">
							제17조 (구매계약의 성립)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 이용자는 웹민원센터 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 이용자의 구매신청을 함에 있어서 다음이 각 내용을 알기 쉽게 제공하여야합니다.</dt>
						<dd>① 재화 등의 검색 및 선택</dd>
						<dd>② 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호)등의 입력</dd>
						<dd>③ 약관내용, 청약철회권이 제한되는 서비스, 배송료, 수수료 등의 비용부담과 관련한 내용에 대한 확인</dd>
						<dd>④ 재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의</dd>
						<dd>⑤ 결제방법의 선택</dd>
						<dt>2. 회사가 제3자에게 이용자의 개인정보를 제공할 필요가 있는 경우 실제 구매신청 시 이용자의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이때 회사는 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유·이용기간 등을 이용자에게 명시하여야 합니다.</dt>
						<dt>3. 회사가 제3자에게 이용자의 개인정보 처리(개인정보의 수집, 생성, 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기, 그 밖에 이와 유사한 행위를 포함) 업무를 위탁할 필요가 있는 경우 실제 구매신청 시 이용자의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이때 회사는 개인정보 처리위탁을 받는 자, 개인정보 처리위탁을 하는 업무의 내용 등을 이용자에게 명시하여야 합니다. 다만, 『정보통신망이용촉진 및 정보보호 등에 관한 법률』에 달리 정함이 있는 경우에는 그에 따릅니다. </dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제18조 (수신확인통지/구매신청 변경 및 취소)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</dt>
						<dt>2. 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고, 이용자의 요청이 있는 경우에는 지체없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 제19조의 청약철회 등에 관한 규정에 따릅니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제19조 (청약철회 등)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사와 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약 내용에 관한 서면을 받은 날((그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등의 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약 철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따르고, 회사가 청약철회의 기간을 달리 정하여 미리 이용자에게 고지한 경우에는 그 기간내에 한하여 청약을 철회 할 수 있습니다.</dt>
						<dt>2. 회사는 자체 시스템의 문제로 인하여 이용자에게 정상적으로 서비스를 제공 하지 못하는 경우, 서비스 연장, 환불 등의 조치를 취합니다. 단, 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.</dt>
						<dd>① 필요한 증명서를 사용자 개인이 잘못 선택 한 경우</dd>
						<dd>② 발급 후 사용자 개인의 상황에 따라 증명서 용도 변경으로 필요성이 없어졌을 경우</dd>
						<dd>③ 프린터 상태가 고장 또는 토너가 없는데 출력하였을 경우</dd>
						<dd>④ 이용자에게 책임이 있는 사유로 재화 등이 멸실 또는 훼손된 경우</dd>
						<dd>⑤ 시간의 경과에 의하여 재화 등의 가치가 현저히 감소한 경우</dd>
						<dd>⑥ 같은 성능을 지닌 재화 등으로 복제한 경우</dd>
						<dd>⑦ 회사가 특정 재화 등에 대하여 청약철회 시 회복할 수 없는 중대한 피해가 예상되어 사전에 청약철회 제한에 관하여 고지하고, 동의가 이루어진 경우</dd>
						<dt>3. 이용자는 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시 및 광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약 철회 등을 할 수 있습니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제20조(청약철회 등의 효과)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 이용자로부터 재화 등을 반환 받은 경우, 3영업일 이내에 이미 지급 받은 재화 등의 대금을 환급하거나 환급에 필요한 조치를 취합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연 기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.</dt>
						<dt>2. 회사는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 법령에서 정한 결제 수단으로 재화 등의 대금을 지급한 때에는 지체없이 당해 결제 수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.</dt>
						<dt>3. 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 회사는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시•광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가 부담합니다.</dt>
						<dt>4. 이미 재화 등이 일부 사용 또는 일부 소비된 경우에 회사는 그 재화 등의 사용 또는 일부 소비에 의하여 이용자가 얻은 이익 또는 그 재화 등의 공급에 소요된 비용에 상당하는 금액으로서 법령에서 정하는 범위의 금액의 지급을 이용자에게 청구할 수 있습니다.</dt>
						<dt>5. 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 회사는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제21조 (지적재산권)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 서비스와 관련된 저작권 등 일체의 지적재산권은 회사에 귀속합니다.</dt>
						<dt>2. 이용자는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 아니 됩니다.</dt>
						<dt>3. 이용자가 게재한 자료와 게시물에 대한 권리와 책임은 게시 당사자에게 있습니다. 단, 회사는 서비스 운영 및 이용자통계조사, 학술연구, 또는 시장조사, 상품의 판</dt>
						<dt>4. 촉, 홍보 등 마케팅을 위하여 이용자가 작성한 리뷰, Q&A, 1:1 문의게시판 등 각종 자료와 게시물을 이용하거나 회사가 제휴한 채널에 사전 통지 없이 복제, 배포, 전송, 전시할 수 있으며, 본질적인 내용에 변경을 가하지 않는 범위 내에서 이를 수정, 편집하여 활용할 수 있습니다. 이 경우 회사는 해당 게시물로부터 이용자의 개인정보가 식별되지 않도록 필요한 조치를 합니다.</dt>
						<dt>5. 회사는 개별 서비스별로 자료 및 게시물과 관련된 세부이용지침을 정하여 시행할 수 있습니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제22조 (양도금지)
						</span>
					</p>	
						
					<p class="marginTop10">
						이용자가 서비스의 이용 권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
					</p><br/>
					
					<p>
						<span class="named">
							제23조 (손해배상)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사가 법률 및 본 약관을 위반한 행위로 이용자에게 손해가 발생한 경우 이용자는 회사에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못 하는 경우 책임을 면할 수 없습니다.</dt>
						<dt>2. 회사나 이용자가 본 약관의 규정을 위반하거나 기타 불법행위를 하여 상대방에게 손해를 발생시킨 경우, 그 손해 발생에 귀책사유가 있는 당사자는 그 상대방의 피해를 배상하여야 합니다.</dt>
						<dt>3. 회사는 무료로 제공되는 서비스와 관련하여 이용자에게 어떠한 손해가 발생하더라도 손해가 회사의 중대한 과실에 의한 경우를 제외하고 이에 대하여 책임을 부담하지 아니합니다. </dt>
						<dt>4. 회사에게 손해배상을 청구하는 경우에는 청구사유, 청구금액 및 산출근거를 기재하여 서면으로 제출하여야 합니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제24조 (면책조항)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 제7조에 따라 서비스가 중지됨으로써 이용자에게 손해가 발생하더라도 이로 인한 책임을 부담하지 않습니다. </dt>
						<dt>2. 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</dt>
						<dt>3. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련 법령에 특별한 규정이 없는 한 책임을 지지 않습니다.</dt>
						<dt>4. 회사는 이용자와 해당발급기관 간의 인터넷증명발급에 대해 중개대행 서비스를 제공할 뿐이므로, 해당발급기관이 제공하는 서비스로 인하여 발생하는 어 떠한 손해에 대하여도 책임을 지지 않습니다.</dt>
						<dt>5. 회사는 이용자가 서비스 이용과 관련하여 기대하는 수익을 상실한 것에 대하여 책임을지지 않으며, 그 밖의 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제 25조 (재판권 및 준거법)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 본 약관의 해석에 관한 회사와 이용자 간의 분쟁에 관하여는 대한민국의 법률을 적용합니다.</dt>
						<dt>2. 본 약관 및 서비스 이용에 관련하여 회사와 이용자간에 발생한 서비스 이용에 관한 분쟁에 관하여는 대한민국 법을 적용하며 소송이 제기되는 경우에는 민사소송법상의 관할법원에 제기합니다.</dt>
					</dl><br/>
					
					<p>
						<span class="named">
							제26조 (분쟁해결)
						</span>
					</p>	
						
					<dl class="marginTop10">
						<dt>1. 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.</dt>
						<dt>2. 회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해드립니다.</dt>
						<dt>3. 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시/도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</dt>
					</dl><br/>
						
					<p class="marginTop10">
						* 부칙 <br />
						∙ 공고일자: 2019년 12월 24일<br />	
						∙ 시행일자: 2020년  1월  1일<br />	
					</p><br />	
			</div>
{/*영문 이용약관  */}
		{/* <div id="rcontents">
				
				<dl>
					<dt><span class="named"><br/>Article 1 (Purpose)</span></dt>
						<dd>
							The agreement shall be concerned with specifying the use conditions and procedures, the rights, duties, and liabilities of the user and the company, and the other requirements
							regarding every service (hereinafter "Service") provided by the Internet web site of the "Webminwon Center (www.webminwon.com)" operated by Digitalzone Co., Ltd. (hereinafter "Company").
						</dd>
					<dt><span class="named"><br/>Article 2 (Definition of Terms) </span></dt>
						<dd>
							The definition of the terms used for the agreement shall be as follows. The meaning of the terms not defined in the agreement shall comply with the general transaction practices and relevant laws. 
						</dd>
						
						<dd>
							<ol class="count">
								<li>
									"Service" shall mean every service provided through the site or mobile application operated by the Company regarding the Internet certificate issue and perusal.
								</li>
								<li>
									"User" shall mean the member and non-member possibly using the service provided by the Company according to the agreement.
								</li>
								<li>
									"Member" shall mean the individual (including overseas Korean nationals) registering membership by providing the relevant information such as personal information for the Company and the person receiving the Company informatio
									n and possibly and steadily using the Service provided by the Company.
								</li>
								<li>
								"Non-Member" shall mean the person not
								registering membership and possibly using the Service provided by the Company.	
								</li>
								<li>
								"Registration" shall mean the act of concluding the Service use contract by 
								consenting to the agreement to receive the Service provided by the Company. 
								</li>
								<li>
								"Relevant Issue Agency" shall mean the corporation forming an alliance with the Company and jointly providing the Service of the Company. The Relevant Issue Agency shall be announced on the homepage of the Webminwon Center and shall 
								be possibly terminated or added depending on the circumstances of the Company and the Relevant Issue Agency. 
								</li>
								<li>
								"e-Mail (ID)" shall mean the e-mail address registered for Member identification and Service 
								use with the setting by the Member and with the approval by the Company.
								</li>
								<li>
								"Password" shall mean the combination of the letters and numbers set and registered to the Webminwon Center by the user to check the matching of the User with the ID and to protect its communication secret.
								</li>
								<li>
								"Membership Withdrawal" shall mean the act of User's terminating the use contract. 
								</li>
								<li>
								"Refund" shall mean Company's making the deposit of the amount requested by the User at the User's request within the payment range of the User. 
								</li>
								<li>
								"Fee" shall mean the usage cost imposed to the User for the Company's Service used by the User according to the standards specified by the Company and the Relevant Issue Agency.
								</li>
							</ol>
						</dd>
					<dt><span class="named"><br/>Article 3 (Specification, Explanation, and Revision of Agreement)</span></dt>
						<dd>
							<ol class="count">
								<li>
									The Company shall provide the Service for the User with the condition that the User shall agree on the details of the agreement. 
									If the User agrees on the details of the agreement, the agreement shall be applied first to the Company's providing the Service and the User's using the Service.
								</li>
								<li>
									The Company shall post the agreement details, the Company and CEO names, the business office address, the contact for consumer complaints, the e-mail address, the business registration number,  
									the telemarketing business report number, and the personal information management officer on the initial service screen of the Webminwon Center to enable the User to check them easily. However, the specific details shall be shown through the pop-up screen. 
								</li>
								<li>
								The Company shall possibly revise the agreement in case of not violating the relevant law if necessary. The revised agreement shall be available for the direct check by the User through the announcement on the homepage of the relevant issue agency and inside the Company together with the current agreement. 
								In case of revising the agreement, the agreement shall be effective from the application date after revision. 
								If the User doesn't reject the agreement change explicitly after announcing the agreement change, the User shall be considered to agree on the agreement. If the User doesn't agree on the changed agreement, the User shall possibly cancel (withdraw membership) its membership registration.
								</li>
								<li>
								If the Company revises the agreement, the revised agreement shall be applied only to the contract concluded after the application date. For the contract concluded therebefore, the agreement before revision shall be applied intactly.
								</li>
							</ol>
						</dd>
					<dt>
						<span class="named">
							<br/>Article 4 (Rules other than Agreement) 
						</span>
						
					</dt>
					<dd>
					The matters not specified in the agreement and the interpretation of the agreement shall comply with the relevant laws, the personal information processing policy, and the business practices including 
					the Act on Promotion of Information and Communications Network Utilization and Information Protection, Etc., 
					the Electronic Financial Transactions Act, the Digital Signature Act, the Framework Act on Telecommunications, 
					the Telecommunications Business Act, the Computer Programs Protection Act, the Act on the Consumer Protection in Electronic Commerce, Etc., the Framework Act on Consumers, the Act on the Regulation of Terms and Conditions,
					and the Guidelines on the Consumer Protection in the Electronic Financial Transactions specified by the Fair Trade Commission. 
					</dd>
					<dt>
						<span class="named">
							<br/>Article 5 (Service Provision and Change)
						</span>
					</dt>
					<dd>
					The Service provided by the Company shall be as follows. 
					The details of the Service to be changed shall be announced to the User, and the Service specified below shall be possibly changed for provision. 
					</dd>
					
					<dd>
					① Electronic commerce Service (including the telemarketing mediation Service) and another accompanied Service
					</dd>
					<dd>
					② The overall Service developed internally by the Company or provided through the discussion with another agency
					</dd>
					<dt>
						<span class="named">
							<br/>Article 6 (Service Use Hours) 
						</span>
					</dt>
						<dd>
							<ol class="count">
								<li>
									Service use hours shall be basically always open and 24 hours a day unless there is a special problem with the business or technical matter. 
								</li>
								<li>
									For the use hours of Provision 1, the date or hour specified by the Company for the necessary regular check shall be excluded.  
								</li>
								
							</ol>
						</dd>
					<dt><span class="named"><br/>Article 7 (Service Suspension)</span></dt>
						<dd>
							<ol class="count">
								<li>
									In case of each of the following numbers, the Company shall possibly and temporarily suspend service provision until the reason is removed.<br />
									① In case of the service provision caused from the technical failure (The disk failure and system shutdown occurring without the intention or fault of the system administrator)<br />
									② If communication is disconnected due to the suspension of the telecommunication service by the key telecommunications business operator specified in the Telecommunications Business Act<br />
									③ If it is inevitable for the urgent system check, the replacement, failure, and expansion of the information and communication facility, and the repair of the replacement facility<br />
									④ If there is a problem with using the Service due to the national emergency, the Service facility failure, or Service overuse<br />
									⑤ If it is accepted that the steady Service provision by the Company is unsuitable due to a crucial reason<br />
								</li>
								<li>
								If the Company has to temporarily suspend the Service due to the difficulty in providing the normal Service, the Service shall be possibly suspended after the prior notice. 
								The Company shall not be responsible for user's unrecognizing the notice details during the prior notice period. In case of an inevitable situation, the prior notice period shall be possibly reduced or omitted. 
								The Company shall not be responsible for the case that the details of the message saved or transmitted in the Service and other communication messages are not saved, deleted, or 
								transmitted with the communication data loss due to the suspension of the above Service.
								</li>
								<li>
									Permanently suspending the Service due to the Company's circumstances shall be based on Provision 2.
								</li>
								<li>
									The Company shall possibly and temporarily modify, change, and suspend the Service after the prior notice and shall not be responsible to the user or a third party for it.
								</li>
								<li>
								In case of the member not accessing the Company for a long time, the Service use shall be possibly suspended after the review period elapses after providing the information 
								by e-mail or through the public notice. 
								</li>
							</ol>
						</dd>
					<dt>
						<span class="named">
						<br/>Article 8 (Membership Registration)
						</span>
					</dt>
						<dd>
							<ol class="count">
								<li>
									The User shall record the matters required in the prescribed membership Registration form provided online 
									by the Company and shall apply for membership Registration in case of agreeing on the agreement.
								</li>
								<li>
									In case of the change to the matter registered during membership Registration, 
									the Member shall have to notify the change to the Company by modifying the Member information within a reasonable time.  
								</li>
								<li>
									Member's submitting the use application to the Company according to the agreement shall be considered to agree on Company's collecting
									and using the Member information stated in the application according to the agreement.  
								</li>
								<li>
								The Company shall possibly go through the confirmation procedure to check the authenticity of Member information and to get the check by the affiliated agency. 
								</li>
							</ol>
						</dd>
					<dt>
						<span class="named">
						<br/>Article 9 (Member's Duties) 
						</span>
						
					</dt>
					<dd>
							<ol class="count">
								<li>
							The information required for membership Registration shall have to be stated correctly. 
								Moreover, the information provided on you shall have to be maintained and updated for correctness. The Member shall not have its ID and Password used by a third party.
								</li>
								<li>
									The Member shall not do any profit-making activity with the Service without Company's prior consent. 
								</li>
								<li>
								The Member shall not use or provide for another person the information obtained from the Company Service through duplication,
								reproduction, change, translation, broadcasting, and another method without Company's prior consent. 
								</li>
								<li>
								The Member shall not do the act stated in each of the following numbers regarding the use of the Company Service. <br />
								① Act of illegally using the ID of another Member<br />
								② The act done for a criminal act or related to another criminal act<br />
								③ The act of disclosing or posting the obscene or violent message, video, and voice and another information damaging the social order to the Webminwon Center<br />
								④ Act of defaming the reputation of another person or insulting another person <br />
								⑤ Act of infringing the right such as the intellectual property right including the copyright of another person<br />
								⑥ The act of changing, transmitting, posting, or hacking the information (computer program, etc.) excluding the information specified 
								by the Company or the act of distributing a computer virus<br />
								⑦ The act of steadily transmitting the fixed contents such as advertising information contrary to another person's intention<br />
								⑧ Any act of surely or possibly disrupting the safe operation of the Service<br />
								</li>
												
							</ol>
						</dd>
							
					<dt><span class="named"><br/>Article 10 (Conversion into the Dormant Account and Membership Withdrawal)</span></dt>
						<dd>
							<ol class="count">
								<li>
									If the Member doesn't log in after 1 year from the last account login date, the Company shall convert the Member's account into the dormant account. 
									Moreover, the personal information of the corresponding member shall be saved for 3 years according to the personal information processing policy as soon as it is converted. 
									The account recovery shall be possible only for the corresponding period.  
								</li>
								<li>
									The Member shall possibly request withdrawal to the Company anytime. At Member's request for withdrawal, the Company shall process membership withdrawal fast through the Webminwon Center according to the method notified by the Company. 
								</li>
							</ol>
						</dd>
					<dt><span class="named"><br/>Article 11 (Company's Duties) </span></dt>
						<dd>
							<ol class="count">
								<li>
									The Company shall not do the act being prohibited by the law and the agreement or violating the public order and good morals and shall have to make an effort on steadily and stably providing the Service.
								</li>
								<li>
									The Company shall not disclose or distribute to another person the User information acquired with regard to Service provision without the person's consent. 
									The information shall not be used for the commercial purpose.
									However, it shall not be the case if there is a request from the relevant national agency according to the relevant law such as the law related to telecommunication.  
								</li>
								<li>
								The Company shall have to possess the security system for protecting the personal information (including the credit information) of the User to enable the safe Company Service.
								</li>
								<li>
								The Company shall not be responsible for the service use failure caused from User's fault.
								</li>
								<li>
								The Company shall have to immediately process the User complaint received with regard to the Service. 
								If the immediate processing is difficult, the Company shall have to notify the reason and the processing schedule by the Company phone, e-mail, and fax.
								</li>
							</ol>
						</dd>
					<dt><span class="named"><br/>Article 12 (Notification to the Member)</span></dt>
						<dd>
							<ol class="count">
								<li>
									The Company shall possibly make a notice to the Member by the e-mail (or mail), phone, SMS, and fax designated by the Member with the prior agreement with the Company. 
								</li>
								<li>
									In case of making notices to many and unspecified persons, the Company shall possibly substitute the positing on the Service screen inside the Company 
									for 1 or more weeks for the individual notice. However, the individual notice shall be made for the matter greatly affecting the Member's transaction.  
								</li>
							
							</ol>
						</dd>
					<dt>
						<span class="named">
						<br/>Article 13 (Personal Information Protection) 
						</span>
					</dt>
						<dd>
							<ol class="count">
								<li>
								In case of collecting the personal information of the User, the Company shall collect the minimum personal
								information only for the information provided by the User with agreement reached during the Company Service Registration.  
								</li>
								<li>
									For every personal information processed by the Company, the personal information, rights, 
									and interests of the User shall be protected by complying with the personal information protection
									regulations of the relevant law such as the Personal Information Protection Act.
								</li>
								<li>
									In case of collecting and using the personal information of the User, the Company shall notify the purpose to the corresponding User for agreement.  
								</li>
								<li>
								The Company shall not use the collected personal information beyond the purpose. If a new purpose of use occurs or 
								it is provided for a third party, the Company shall notify the purpose to the corresponding User 
								for agreement in the use and provision stages. However, the case specified differently in the relevant law shall be an exception. 
								</li>
								<li>
								The User shall possibly request the perusal and error correction of its personal information possessed by the Company anytime. The Company shall have to
								immediately take a necessary action accordingly. If the User requests the error correction, the Company shall not use the corresponding personal information until the error is corrected.
								</li>
								<li>
								The Company shall have to limit the number of the person handling the personal information of the User to the minimum for protecting personal information and shall be fully responsible for the
								damage done to the User from the loss, theft, release, provision to a third party without consent, falsification, etc. of the personal information of the User including credit cards, bank accounts, etc.
								</li>
								<li>
								The Company or the third party receiving personal information from it shall 
								immediately destroy the corresponding personal information when the purpose of collecting or receiving the personal information is achieved.
								</li>
								<li>
								The Company shall not have the setting of the preselected consent section on collecting, using, and providing personal information.
								</li>
							</ol>
						</dd>
					<dt>
						<span class="named">
							<br/>Article 14 (Duty on the Member ID and Password)
						</span>
					</dt>
						<dd>
							<ol class="count">
								<li>
									From the moment when the User completes the Company Service Registration procedure, 
									you shall be responsible for maintaining the secret of the inputted information. 
									The Member shall be responsible for all the results occurring from using the Member ID and Password. 
								</li>
								<li>
									The Member shall be fully responsible for managing its ID and Password and shall have to report 
									the finding of the illegal use of the Member ID and Password. The Member shall be fully responsible for the matter caused from no report. 
								</li>
								<li>
									The User shall have to go through correct disconnection whenever the Company Service is ended. 
									The Company shall not be responsible for the damage and loss caused from a third party's using your information due to the incorrect disconnection.
								</li>
								<li>
									The Password loss shall be notified by e-mail. The Member shall be responsible for the problem caused from the fault and information management negligence of the person 
									including the incorrect entry of the Member e-mail address or cellphone number in spite of the regulation of the previous provision.
								</li>
								<li>
									The Member shall possibly and frequently modify or delete personal information
									in the personal information management of the Service for personal information protection and management.
								</li>
							</ol>
						</dd>
					<dt>
						<span class="named">
							<br/>Article 15 (Information Provision and Advertisement Posting)
						</span>
					</dt>
						<dd>
							<ol class="count">
							<li>
							The Company shall possibly announce to the bulletin board the various information accepted to be necessary for use with regard to the Service operation or post 
							it as advertisement with the Service screen, e-mail (or mail), SMS, app push, etc. The Member receiving the e-mail including advertisement shall possibly reject the receipt. 
							</li>
							<li>
							If the Member rejects the receipt based on the previous provision, the Company shall possibly replace information provision by the announcement of the information 
							(Ex.: Change of the relevant regulation and policy, etc.) required for
							Service use on the homepage and shall not be responsible for the damage occurring as the Member rejects the receipt and doesn't check the provision of the information announced on the homepage.
							</li>
							<li>
							The Company shall possibly post the promotion material decided to be proper or usable for the Service.
							</li>
							</ol>
						</dd>
					
					
					<dt><span class="named"><br/>Article 16 (Precautions on the Forgery and Falsification of the Complaint Document Issued through the Internet)</span></dt>
							<dd>
						If the complaint document issued by the Webminwon Center is the case corresponding to each of the following numbers, the User shall be sentenced to the punishment specified for each crime as no more than 
						10 years in jail or the penalty of no more than 20 million KRW according to Articles 225 and 230 of the Criminal Act of the Republic of Korea. 
						
						</dd>
						<dd>
						① Act of copying (forging) and using the PC screen or the outputted document  <br />
						② The act of modifying or falsifying the PC screen or the outputted document<br />
						③ The act of forging, falsifying, and using the document with the hacking based on other electronic methods<br />
						</dd>
					<dt><span class="named"><br/>Article 17 (Conclusion of the Purchase Contract)</span></dt>
						<dd>
							<ol class="count">
								<li>
									The User shall request the purchase with the following or similar method on the Webminwon Center. 
									The Company shall have to provide each of the following contents to enable the User to request the purchase.<br />
									① Goods search and selection<br />
									② Input of the name, address, phone number, e-mail address (or cellphone number), etc. of the receiver<br />
									③ Check of the agreement details, the Service limiting the subscription withdrawal right, and the details on bearing the costs such as the delivery charge, the Fee, etc.<br />
									④ The goods purchase request and the check on it or the agreement on Company's check<br />
									⑤ Selection of the payment method<br />
								</li>
								<li>
									In case of requiring the provision of the personal information of the User for a third party, the Company shall have to get the agreement from the User during the actual 
									purchase request and shall not get the comprehensive agreement in advance during membership Registration. 
									The Company shall have to specify to the User the provided personal information item, the receiver, and the use purpose and possession
									and use periods of the personal information of the receiver.  
								</li>
								<li>
									In case of requiring the consignment of the work (including the collection, creation, connection, linkage, record, storage, possession, processing, editing,
									search, output, correction, recovery, use, provision, and destruction and the other similar acts)  on processing the personal information of the User to a third party,
									the Company shall have to get the agreement from the User during the actual purchase request and shall not get the comprehensive agreement in advance during the membership
									Registration. The Company shall have to specify the person getting the consignment of processing personal information and the details of the work of consignment
										of processing personal information. However, if specified differently in the Act on Promotion of Information and Communications Network Utilization and Information 
										Protection, Etc., it shall be observed. 
								</li>
							</ol>
						</dd>
					<dt>
						<span class="named">
						<br/>Article 18 (Notice of Receipt Notification and Change and Cancellation of the Purchase Request)
						</span>
					</dt>
						<dd>
							<ol class="count">
								<li>
									The Company shall notify the receipt confirmation to the User at the User's purchase request. 
								</li>
								<li>
									In case of the inconsistency of the declaration of intention, the User receiving the notice of receipt confirmation shall possibly and immediately 
									request the change and cancellation of the purchase request after receiving the notice of receipt confirmation. Processing shall have to be done 
									immediately at the User's request. However, if payment is done already, the regulation on subscription withdrawal of Article 19 shall be observed.
								</li>
						
							</ol>
						</dd>
					<dt>
						<span class="named">
							<br/>Article 19 (Subscription Withdrawal, Etc.)
						</span>
					</dt>
					<dd>
							<ol class="count">
								<li>
									The User concluding the contract with the Company on purchasing goods shall possibly withdraw subscription within
									7 days of the date (meaning the goods supply date or the goods supply start date if the goods are supplied later
									than the date of receiving the document) receiving the document on the contract details according to Article 13 Provision
									2 of the Act on the Consumer Protection in Electronic Commerce, Etc. However, if specified differently in the Act on the Consumer
										Protection in Electronic Commerce, Etc. regarding the subscription withdrawal, the regulation of the act shall be observed.
										If the Company notifies the different subscription withdrawal period to the User in advance, the subscription shall be possibly withdrawn only within the period. 
								</li>
								<li>
									In case of not normally providing the Service for the User due to the problem with the internal system, the Company shall take an action on the Service extension, refund, etc. 
									However, in case of each of the following numbers, return and Refund shall not be done.<br />
									① If the individual User incorrectly selects the required certificate<br />
									② If it is not required anymore due to the use change of the certificate depending on the circumstances of the individual User after issue <br />
									③ If it is printed out when the printer is in failure or has no toner<br />
									④ If goods are lost or damaged due to the User's fault<br />
									⑤ If the value of goods is decreased remarkably over time<br />
									⑥ If it is copied as the goods having the same performance<br />
									⑦ If the Company notifies the limitation to subscription withdrawal in advance and the consent 
									is obtained since the unrecoverable serious damage is expected from the subscription withdrawal of the specific goods <br />
								</li>
								<li>
									If the details of goods are different from the indication and advertisement details or are implemented differently from the contract details,
									the User shall possibly withdraw subscription 
									in spite of the regulations of Provisions 1 and 2 within 3 months of the receiving 
									the corresponding goods and within 30 days of the date of knowing or possibly knowing the fact.
								</li>
							</ol>
					</dd>
				
					<dt>
						<span class="named">
							<br/>Article 20 (Effect of Subscription Withdrawal, Etc.)
						</span>
					</dt>
					<dd>
							<ol class="count">
								<li>
								In case of receiving the goods returned from the User, the Company shall refund the money received for the goods or take an action needed for
								Refund within 3 business days. If the Company delays the Refund for the goods to the User, the delay interest calculated by multiplying
								the delay interest rate specified for the delay period in Article 21-2 of the Enforcement Decree of the Act on the Consumer Protection
								in Electronic Commerce, Etc. shall be paid.
								</li>
								
								<li>
								If the User pays for the goods with the credit card or the payment means specified in the law, the Company shall
								immediately request the business operator providing the corresponding payment means to suspend or cancel 
								the request for paying for the goods for the Refund of the money.  
								</li>
								<li>
								In case of the subscription withdrawal, the User shall bear cost required for returning
								the supplied goods. The Company shall not request the User to pay the penalty or damages for the subscription withdrawal.
								However, if the subscription withdrawal is done since the goods details are different 
								from the indication and advertisement details or are implemented differentl
								y from the contract details, the Company shall bear the cost required for returning the goods. 
								</li>
								<li>
								If hoods are partially used or consumed already, the Company shall possibly request the User to pay the amount within the range 
								specified in the law as the amount corresponding to the profit obtained 
								from the use or partial consumption of the goods by the User or the cost required for the goods supply.
								</li>
								<li>
								For easy understanding by the User, the Company shall clearly indicate who bears the cost in case of subscription withdrawal 
								if the User bears the shipping cost to receive the goods.
								</li>
							</ol>
					</dd>
					<dt>
						<span class="named">
							<br/>Article 21 (Intellectual Property Right) 
						</span>
					</dt>
					<dd>
							<ol class="count">
								<li>
								All the intellectual property rights including the copyright related to the Service shall belong to the Company.
								</li>
								
								<li>
								The User shall not use or have a third party use the information obtained from the
								Service use for profit with reproduction, transmission, publication, distribution, broadcasting, and another method without the prior consent of the Company.
								</li>
								<li>
								The posting person shall have the right and responsibility for the material and post stated by the User. However, for the Service operation and User statistics survey, 
								the academic research, or the marketing including the sales promotion and PR of the product, the Company shall possibly use the various materials and posts 
								including the review, Q&A, and 1:1 inquiry bulletin board prepared by the User or reproduce, distribute, transmit, and display 
								them to the channel affiliated with the Company without the prior notice and shall possibly modify and edit them for use within 
								the range of not changing the substantial details. In this case, the Company shall take a necessary action to have the personal
								information of the User not identified in the corresponding post.
								</li>
								<li>
								The Company shall possibly decide and implement the detailed use guidelines on materials and posts for each individual Service.
								</li>
							</ol>
					</dd>
					<dt>
						<span class="named">
							<br/>Article 22 (Prohibition of Transfer) 
						</span>
					</dt>
					<dd>
					The User shall not transfer or donate the right of the Service use and the position of the use contract to another person and shall not possibly provide them as security. 
					</dd>
					<dt>
						<span class="named">
							<br/>Article 23 (Indemnification)
						</span>
					</dt>
					<dd>
							<ol class="count">
								<li>
								The User shall possibly request the indemnification for the Company's act of violating the law and the agreement damages the User. 
								In this case, if the Company doesn't verify that there is no intention or fault, the Company shall not be exempted from responsibility. 
								</li>
								
								<li>
								If the Company or the User violates the regulations of the agreement or does an illegal act resulting in the damage to the other party, 
								the party having the attributable reason for the damage shall have to indemnify the other party for the damage.
								</li>
								<li>
								Even though damage occurs to the User from the Service provided for free, the Company shall not be responsible for 
								it except for the case of the damage done by the Company's serious fault.
								</li>
								<li>
								In case of requesting indemnification to the Company, the reasons for request, the requested amount, and the calculation basis shall have 
								to be stated and submitted in writing.
								</li>
							</ol>
					</dd>
					<dt>
						<span class="named">
							<br/>Article 24 (Exemption)
						</span>
					</dt>
					<dd>
							<ol class="count">
								<li>
								The Company shall not be responsible for the damage done to the User from the Service suspension according to Article 7.
								</li>
								
								<li>
								The Company shall not be responsible for the Service use failure caused from the User's fault.
								</li>
								<li>
								Unless there is a special regulation in the relevant law, the Company shall not be responsible for using the Service provided for free.
								</li>
								<li>
								As the Company shall provide the mediation Service for the certificate issue through the Internet between the User and the relevant issue agency, 
								the Company shall not be responsible for any damage occurring from the Service provided by the relevant issue agency.
								</li>
								<li>
								The Company shall not be responsible for the loss of the profit expected from the User's using the 
								Service and the damage from the other materials obtained through the Service.
								</li>
							
							</ol>
					</dd>
					<dt>
						<span class="named">
							<br/>Article 25 (Jurisdiction and Governing Law)
						</span>
					</dt>
					<dd>
							<ol class="count">
								<li>
								The laws of the Republic of Korea shall be applied to the dispute between the Company and the User on the interpretation of the agreement.
								</li>
								
								<li>
								The laws of the Republic of Korea shall be applied to the dispute between the Company and the User on the agreement and the Service use.
								A lawsuit shall be filed to the court having jurisdiction according to the Civil Proceedings Act. 
								</li>
								
							</ol>
					</dd>
					<dt>
						<span class="named">
							<br/>Article 26 (Dispute Resolution)
						</span>
					</dt>
					<dd>
							<ol class="count">
								<li>
								The Company shall reflect the fair opinion or complaint presented by the User and shall establish and operate the damage indemnification organization.
								</li>
								
								<li>
								The Company shall preferentially process the complaint and opinion submitted by the User. However, if the fast processing is impossible, 
								the reason and the processing schedule shall be immediately notified  to the User.
								</li>
								<li>
								The damage remedy request on the electronic commerce dispute occurring between the Company and the User shall possibly comply with the arbitration of the Fair 
								Trade Commission or the dispute arbitration agency designated by the mayor or the governor.
								</li>
							</ol>
					</dd>
					<dt>	
						<span class="named">
							<br/>* Bylaw 
						</span>
					</dt>
					<dd>
					∙ Announcement Date: December 24, 2019<br />
					∙ Effective Date: January 1, 2020
					</dd>
				</dl>

			</div> */}
	</div>
	</div>
		<ClickButtonBig onClick={handleButtonClick} buttonText="동의하기" />
		</div>
		
	);
};

export default RegisterTermsUse;