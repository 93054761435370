import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./tab.css";

const ToggleTab = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = index => {
    setActiveTab(prevActiveTab => (prevActiveTab === index ? -1 : index));
  };

  return (
    <div className="tabMenuContainer">
      <ul>
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`${activeTab === index ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            <ul>
              <div className="tabTitle">
                {tab.title} {activeTab === index ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div className={`tabAnswer ${activeTab === index ? "active" : ""}`}>
              {tab.description1}

                <img src={tab.img} alt="" className= {`guide-img ${activeTab === index ? "active" : ""}`}/>
                {tab.description}<br/>
              </div>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ToggleTab;