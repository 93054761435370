import React from 'react';
import "./popup.css"

const TwoButtonPopup = ({
  onClick, 
  onClick2, 
  popupText,
  popupText2,
  buttonText,
  buttonText2,
  buttonId,
  buttonId2}) => {
    return (

<div className="popup-container">
            <div className="popup-box">
            <div className="popup-in">
              <div className="popup-content">
                <h3> {popupText}</h3>
                <h3> {popupText2}</h3>

              </div>
                <div className="popup-btn-box">
                <button  onClick={onClick} className="popup-button-sm1" id={buttonId}>{buttonText}</button>
                <button  onClick={onClick2} className="popup-button-sm2" id={buttonId2}>{buttonText2}</button>
                </div>
              </div>
            </div>
          </div>

    );
};

export default TwoButtonPopup