import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import TwoButtonPopup from '../../components/common/Popup/TwoButtonPopup';
import TopNavChat from '../../components/views/TopNavChat';


function MemberRegister() {
  const [agreeAll, setAgreeAll]         = useState(false);
  let   {agreeTerms = false, agreePrivacy = false }     = useLocation().state || {}; 
  const [agreeTermsChk, setAgreeTermsChk] = useState(false);
  const [agreePrivChk, setAgreePrivChk] = useState(false);  
  const [showPopup, setShowPopup]         = useState(false);
  const [showTwoPopup, setShowTwoPopup]   = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (agreeAll) {
      navigate('/member-register2');
    }
  };

  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
  };

  const handleAgreeAll = () => {
    const newValue = !agreeAll;
    setAgreeAll(newValue);
    setAgreeTermsChk(newValue);
    setAgreePrivChk(newValue);
  };

  const handleAgreeTerms = () => {
    navigate('/member-register:agree-terms', {state : {agreePrivacy : agreePrivChk}});
  };
  
  const handleAgreePrivacy = () => {
    navigate('/member-register:agree-policy', {state : {agreeTerms : agreeTermsChk}});
  };

  const changeAgree = (type) => {
    if(type === "terms"){
      setAgreeTermsChk(!agreeTermsChk);
    } else if(type === "privacy"){
      setAgreePrivChk(!agreePrivChk);
    }
  }

  const handleBacKClick = () => {
      setShowTwoPopup(true);
    };

  const handleCancelNo = () => {
    setShowTwoPopup(false);
  };

  const handleYes = async () => {
    setShowTwoPopup(false);
      navigate('/main');
  };

  useEffect(()=>{
    setAgreeTermsChk(agreeTerms);
    setAgreePrivChk(agreePrivacy);

    if(agreeTerms && agreePrivacy){
      setAgreeAll(true);
    }
  },[]);

  useEffect(()=>{
    if(agreeTermsChk && agreePrivChk){
      setAgreeAll(true);
    } else {
      setAgreeAll(false);
    }
  }, [agreeTermsChk, agreePrivChk])

  const isButtonDisabled =  !agreeAll;

  useEffect(() => {
    if (agreeTerms && agreePrivacy) {
      setAgreeAll(true);
    }
  }, [agreeTerms, agreePrivacy]);
  

  return ( 
    <div id="member-register">
            <div className="navbar-container">
      <div className="navbar-inner">
        <div className="nav" onClick={handleBacKClick}>
          <span className="arrow-icon">
            <IoIosArrowBack />
          </span>
        </div>
        <p className="menu-title">회원가입</p>
        <TopNavChat />
        </div>
      </div>

      <div className="member-screen">
        <h2 className="top-title">웹민원센터 가입절차</h2>

        <h2 className="step-title">
          <span className="step-txt"><div className='step-number'>1</div></span>서비스 이용을 위하여 <br/>  아래의 약관에 동의해 주세요.
        </h2>
        <div className="agree-box">
          <div className="agree-list" onClick={handleAgreeAll}>
          <label htmlFor="check-box1" className='blind'>전체동의 </label>
            <input
                id='check-box1'
              type="checkbox"
              className="checkbox-round"
              checked={agreeAll}
            />
            <p className="agree-text">전체동의</p>
          </div>
          <div className="agree-list-sub" onClick={handleAgreeTerms}>
            <div className="agree-list-button">
            <div className="agree-cont">
            <label htmlFor="check-box2" className='blind'>이용약관 동의 </label>
            <input
                type="checkbox"
                id='check-box2'
                className="checkbox-round"
                checked={agreeTermsChk}
                onClick={(e) => {e.stopPropagation(); changeAgree('terms');}}
              />
              <p className="agree-text">이용약관 (필수)</p>   
              </div>
              <IoIosArrowForward />
              {' '}
            </div>
          </div>
          <div className="agree-list-sub" onClick={handleAgreePrivacy}>
            <div className="agree-list-button">
            <div className="agree-cont">
            <label fhtmlFor="check-box3" className='blind'>개인정보처리방침 동의 </label>
              <input
                type="checkbox"
                id='check-box3'
                className="checkbox-round"
                checked={agreePrivChk}
                onClick={(e) => {e.stopPropagation(); changeAgree('privacy');}}
              />
              <p className="agree-text">개인정보처리방침 (필수)</p>  
              </div>
               <IoIosArrowForward />
               {' '}
            </div>
          </div>
        </div>

        <ClickButtonBig 
        onClick={() => handleButtonClick()}
         buttonText="다음"
         disabled={isButtonDisabled}
         buttonId={isButtonDisabled ? 'buttonDisabled' : ''}
          />
      </div>

      {showPopup && (
        <OneButtonPopup onClick={handleCancel} popupText="약관 동의가 필요합니다." buttonText="확인"/>
        )}

           
      {showTwoPopup && (
    <TwoButtonPopup  
     onClick={handleYes}  
     onClick2={handleCancelNo}
     popupText="회원 가입을 취소하시겠습니까?" 
     popupText2="" 
     buttonText="예" 
     buttonText2="아니요"/>
    )}

    </div>
  );
}

export default MemberRegister;