
import React from 'react';
import { useState, useEffect } from 'react';
import Pagination from './Pagination';
import './board.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import TopNavBar from '../TopNavBar';


const Board = () => {
  const [lists, setLists]                 = useState([]);
  const [isPageVisible, setIsPageVisible] = useState(false);
  const LIST_PER_PAGE = 10; // 한장에 보여질 리스트 수
  const [page, setPage] = useState(1); // 페이지
  const startNum = (page - 1) * LIST_PER_PAGE; // 0 10 20 30
  const endNum = startNum + LIST_PER_PAGE; // 10 20 30 40
  const navigate = useNavigate();
  const navLink = () => {
      navigate("/intro");
  };

  useEffect(() => {
    fetch('/data/noticeInfo.json')
      .then((res) => res.json())
      .then((data) => setLists(data));
  }, []);

  useEffect(() => {
    if(lists.length > 0){
      setIsPageVisible(true);
    }
  }, [lists]);

  // 상단으로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({ top: 0 - 3 * 16, behavior: 'smooth' }); // 스무스 스크롤을 원할 경우 'smooth' 옵션 사용
  };


  
  return (
    <div className='notice'>
      {isPageVisible && (
      <>
   <TopNavBar/>
      <div className="pagetip" id='margin33'>
      <p className='notice-title'>목록</p>
      <p>웹민원센터의 최근 소식을 알 수 있습니다.</p>
      </div>
      <div id='Notice'>

          <div className='board-search-container'>
            <form method='post' name='search' action='/notice'>
              <div className='pull-right'>
              </div>
            </form>
          </div>
          <div className='list-box'>

            <ul className='notice-list'>
              {[...lists]
                .reverse()
                .slice(startNum, endNum)
                // .reverse()
                .map((item, idx) => {
                  return (
                    <li className='board-lists' key={item.id} data={item}>
                      <Link
                        to={'/notice-all/detail/' + item.id}
                        className='link-box'
                      >
                        <div className='id-box'
                        >
                        <p className='id-name'>{item.id}</p>
                        </div>
                        <div className='flex-column'>
                        <p className='list-title'>{item.title}</p>

                        <p className='list-author'>{item.author}</p>
                        <p className='list-date'>{item.date}</p>
                        </div>
                      </Link>
                    </li>
                  );
                })}
            </ul>
           
            <Pagination
              total={lists.length}
              page={page}
              setPage={setPage}
              LIST_PER_PAGE={LIST_PER_PAGE}
            />
          </div>
        </div>
      </>
    )}
    </div>
  );
};

export default Board;
