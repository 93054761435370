import React from 'react';
import "./footer.css"

const Footer = () => {
    return (
        <div className="address-wrapper" id='setting-address-wrapper' >
            
            {/* <!-- 국문 --> */}
            <address className="address-container" id='setting-address'>
                <dl>
                    <dt>대표이사</dt>
                    <dd>조태묵</dd>
                    <dt>개인정보보호책임자</dt>
                    <dd>김윤규</dd>
                    </dl>
                <dl>
                    <dt>통신판매업신고</dt>
                    <dd>제2016-성남분당-0366호</dd>
                   
                </dl>
                <dl>
                <dt>사업자등록번호</dt>
                    <dd>110-81-41568</dd>
                </dl>
                <dl>
                <dt>주소</dt>
                <dd>13453 경기도 성남시 수정구 금토로 69, 6층<br/>(금토동, 다우디지털스퀘어)</dd>
                </dl>
                <dl>
                    <dt>고객지원센터</dt>
                    <dd>1644-2378(유료)</dd>
                    <dt>FAX</dt>
                    <dd>02-6008-3017</dd>
                 
                </dl>
                <dl>
                <dt>E-MAIL</dt>
                    <dd>cert@signgate.com</dd>
                </dl>
               <p>ⓒ 2023. KICA inc. All rights reserved.</p> 
            </address>


        </div>
    );
};

export default Footer;