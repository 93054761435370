import { useEffect, useState } from "react";
import "./testChat.css"
import { ReactComponent as ChatbotIcon } from "../common/Svg/chatbot.svg";

const NavChat = (id)=> {   
    const [isInit, setIsInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
   
    const loadScript = async () => {
        // Check if the script has already been loaded
        if (document.getElementById("chatbot-script")) {
            setIsInit(true);
            return;
        }
            const script = document.createElement("script");
            script.src = "https://public-common-sdk.s3.ap-northeast-2.amazonaws.com/sdk/cloud/Twc.plugin.js";
            script.id = "chatbot-script";

            script.onload = () => {
                if (window.Twc) {
                window.Twc('init', {
                    brandKey: "20230424045024IUpE-C6_EHQnA9t2o_GoiQ",
                    channelType: "scenario",
                    scenarioId: "MjkzNA==",
                    buttonOption: {
                        showLauncher: false,
                        zIndex: 10000,
                        bottom: 25,
                        right: 25
                    }
                });

                setIsInit(true);
            } else {
                console.error("Twc is not defined.");
            }
        };

        // Append the script to the body
        document.body.appendChild(script);
    };

    useEffect(() => {
        loadScript();
        setIsLoading(true);
      
      }, []);


    const chatBot = () => {
        if (window.Twc && window.Twc.Chat) {
            window.Twc.Chat.open();
        } else {
            console.error("Twc.Chat is not defined.");
        }
    };
    
    return (
        <div className="chatbot-area">

        {isLoading && (
 <button
 className="chat-btn"
 id={id}
 onClick={(e) => chatBot(e)}
>
    <img
      style={{ width: '70px' }}
            src="/img/common/chatbot-a.png"
            alt="Chatbot Icon"
            id="topnav-chat"
          />
{/* <ChatbotIcon className="chatbot-icon" /> */}
</button>
      )}
    </div>
  );
};
export default NavChat;