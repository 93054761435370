import React from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../../components/common/Buttons/CllickButtonBig';



const SplashScreen1 = () => {
    const navigate = useNavigate();

    const handleButtonClick = page => {
        navigate(`/${page}`);
      };

    return (
        <div className='splash-screen'>
            <div className="top-section">
                <div className="logo"><img src="img/common/logo_WEBMINONE.png" alt="logo" /></div>
                <div className="progress-bar">
                    <div className="progress-circle" id='progressing'></div>
                    <div className="progress-circle" ></div>
                    <div className="progress-circle"></div>
                </div>
            </div>
            <div className="middle-section">
                <div className="splash-text-1">내 손안의 전자증명서,<br /> 웹민원센터</div>
                <div className="splash-img"> 
                 <img src="img/Onboarding/splash1.png" alt="SPLASH" />
                </div>
                <div className="splash-text-2">모바일로 언제 어디서든<br />간편하게 발급받고, 실시간으로 확인하세요.  </div>
            </div>
            
    <ClickButtonBig onClick={() => handleButtonClick("splash2")} buttonText="다음" />



            
        </div>
    );
};

export default SplashScreen1;