import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {
    const { pathname } = useLocation();

    useEffect(() => {
    // 네비게이션 바의 높이(3rem)만큼 화면을 위로 스크롤
    window.scrollTo(0, 0 - 3 * 16); // 1rem을 16px로 가정한 경우
    }, [pathname]);

    return <>{props.children}</>;
}