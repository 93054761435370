import React from 'react';
import TopNavBar from '../../components/common/TopNavBar';


const VoiceEye = () => {
    return (
    <div>
        <TopNavBar />
        <div className="voiceeye-screen">
        <div className="section-title">
          <h2 className='foam-title'>증명서 음성안내</h2>
        </div>
        <div className="voice-eye" >
        </div>
        </div>
    </div>
    );
};

export default VoiceEye;