import React, { useEffect } from 'react';
import { MAP_DATA_CUSTOMERSERVICE } from './MapDataCustomerservice';
import { useState } from 'react';
import MMQALIS from '../../components/views/Customer/MMQALIS.jsx?ver=2';
import TopNavBar from '../../components/common/TopNavBar';
import MMFQLST from '../../components/views/Customer/MMFQLST';
import { useLocation, useNavigate } from 'react-router-dom';


const MMCUSTOMER = () => {
    const  navigate                 = useNavigate();
    const [content, setContent]     = useState('faq');
    let { tabNum }                  = useLocation().state || {}; 
   
    const buttonValueSetting = e => {
      const { name } = e.target;
      setContent(name);
    };

   
    useEffect(()=>{
      if (tabNum === "2"){
        setContent('consultation');
      }
    }, []);
  
    const selectComponent = {
        faq: <MMFQLST /> ,
        consultation: <MMQALIS />,
    };

    return (
      <div className ="customer-screen">
      <TopNavBar/>
      <nav className="detail-tab-lists">
      {MAP_DATA_CUSTOMERSERVICE.map((data) => {
        return (
          <button
            className={
              content === data.name ? 'detail-tab-list active' : 'detail-tab-list'
            }
            onClick={buttonValueSetting}
            name={data.name}
            key={data.id} 
          >
            {data.text}
          </button>
        );
      })}
    </nav>
    {content && <div className='detail-tab-content '>{selectComponent[content]}</div>}
  </div>
);
};

export default MMCUSTOMER