import { useEffect, useState } from "react";
import { connectAxios } from "../../module/connectAxios";
import { useNavigate } from "react-router-dom";
import OneButtonPopup from "../../components/common/Popup/OneButtonPopup";

const SSOLogin = () => {
  const navigate                          = useNavigate();
  const [showPopup, setShowPopup]         = useState(false);
  const [user, setUser]                   = useState({
    tpid      : '',
    hakbun    : '',
    userName  : '',
    userBirth : '',
    userMail  : '',
    userTel   : '',
    userCi    : ''
  });

  function goSchool () {
    navigate('/search-school');
  }

  const params = new URLSearchParams(window.location.search);

  let giwanNo = params.get("GIWANNO");
  let userId  = params.get("USERID");
  let commandCd = params.get("COMMAND");
  
  const getSSOLogin = async () => {

    //기관 정보 저장
    const giwanRes = await connectAxios.get("/giwan-info", { 
      params : {giwanNo : giwanNo, command : commandCd}
    })
    const giwan = { giwanNo: giwanRes.data.giwanNo, giwanName: giwanRes.data.giwanNe };

    //M02. 로그인 타입 조회
    await connectAxios.get('/giwan-type', {
      params: {
        "giwanNo" : giwanNo,
        "command" : commandCd
      }
    })
    .then(function(response){
      let result = response.data;

      //sessionStorage 저장
      const newGiwan    = {...giwan, lgnQryType : result.lgnQryType}
      window.sessionStorage.setItem('giwanInfo', JSON.stringify(newGiwan));
    });
    
    //로그인
    await connectAxios.get("/sso", { params : {
      "giwanNo"   : giwanNo,
      "userId"    : userId,
      "commandCd" : commandCd
    }
  }).then(function(response2){
    setUser(response2.data);
  });
  }

  useEffect(()=> {
    getSSOLogin();
  }, []);

  useEffect(()=> {
    if(user.tpid !== "" && user.resultCode == "200") {
      sessionStorage.setItem('userInfo', JSON.stringify(user));
      navigate('/popup-notice');
    } else if (user.resultCode == "201"){
      setShowPopup(true);
    }
  }, [user]);

  return (
    <>
    {showPopup && (
        <OneButtonPopup onClick={()=>goSchool()} popupText={'SSO 로그인에 실패했습니다.'} popupText2={'잠시 후 다시 시도해주세요.'} buttonText="확인"/>
        )}
    </>
  )
}
export default SSOLogin