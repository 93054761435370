import React, { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';


const MMFQLST = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [activeFAQ, setActiveFAQ]         = useState(null);
  const [faqData, setFaqData]             = useState(null);
  const [allFaqData, setAllFaqData]       = useState(null);
  const [category, setCategory]           = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchFaqData = async () => {
      const response = await fetch(`/data/faq.json`);
      const data = await response.json();

      let faq       = data["데이터"];
      let category  = data["카테고리"];

      setAllFaqData(faq);
      setFaqData(faq);
      setCategory(category);

    };

    fetchFaqData();
  }, []);

  if (!faqData) {
    return <div className='loading-message'>FAQ를 불러오고 있습니다.</div>;
  }

  const handleCategoryClick = async (categoryId) => {
    let categoryFaqData = categoryId === 'all' ? allFaqData : { [categoryId]: allFaqData[categoryId] };
    setActiveFAQ(null);
    setFaqData(categoryFaqData);
    setSelectedCategory(categoryId);
  };
  // const renderCategoryButtons = () => {
  //   return Object.keys(category).map((key) => {
  //     const isActive = selectedCategory === key;
  //     return (
  //       <button
  //         key={key}
  //         className={`faq-tap-btn ${isActive ? 'category-active' : ''}`}
  //         onClick={() => handleCategoryClick(key)}
  //       >
  //         {category[key]}
  //       </button>
  //     );
  //   });
  // };

  const renderCategoryButtons = () => {
    const allCategoryButton = (
      <button
        key="all"
        className={`faq-tap-btn ${selectedCategory === 'all' ? 'category-active' : ''}`}
        onClick={() => handleCategoryClick('all')}
      >
        전체
      </button>
    );
  
    const categoryButtons = Object.keys(category).map((key) => {
      const isActive = selectedCategory === key;
      return (
        <button
          key={key}
          className={`faq-tap-btn ${isActive ? 'category-active' : ''}`}
          onClick={() => handleCategoryClick(key)}
        >
          {category[key]}
        </button>
      );
    });
  
    return [allCategoryButton, ...categoryButtons];
  };
  
  const handleSearchInputChange = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);

    const filteredData = {};

    Object.keys(allFaqData).forEach((key)=> {
      const filteredItems = allFaqData[key].filter((item) => item.title.includes(keyword) || item.content.includes(keyword));
      if (filteredItems.length > 0) {
        filteredData[key] = filteredItems;
      }
    });

    setActiveFAQ(null);
    setFaqData(filteredData);
  };

  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  return (
    <div className='faq-screen'>
      <div className="search-input-box">
      <IoSearch style={{ color : '#ccc' }} />
      <input
        type="search"
        className="search-input"
        value={searchKeyword}
        onChange={handleSearchInputChange}
        placeholder="찾으시는 질문을 검색하세요."
      />
      </div>

      <div className='faq-tap-container'>{renderCategoryButtons()}</div>
      {/* <div>
          {searchKeyword.length > 0 && Object.keys(faqData).length === 0 ? (
            <div className='text-box'>일치하는 FAQ가 없습니다.</div>
          ) : (
            <div>
              {Object.keys(faqData).map((faqKey, index) => (
                <div key={index}>
                  {faqData[faqKey].map((item, subIndex) => (
                    <div key={subIndex} className="customer-box">
                      <div className="faq-question" onClick={() => toggleFAQ(`${index}${subIndex}`)}>
                        {activeFAQ === `${index}${subIndex}` ? (
                          <IoIosArrowUp className="arrow-icons" />
                        ) : (
                          <IoIosArrowDown className="arrow-icons" />
                        )}
                        Q. {item.title}
                      </div>
                      {activeFAQ === `${index}${subIndex}` && (
                        <div
                          className="faq-answer"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div> */}
        <div className='faq-list-container'>
  {searchKeyword.length > 0 && Object.keys(faqData).length === 0 ? (
    <div className='text-box'>일치하는 FAQ가 없습니다.</div>
  ) : (
    <div>
      {Object.keys(faqData).map((faqKey, index) => (
        <div key={index} >
          {faqData[faqKey].map((item, subIndex) => {
            // FAQ 제목과 내용을 가져오고
            const title = item.title;
            const content = item.content;
            // FAQ 제목과 내용 중에서 검색 키워드가 포함된 항목을 찾습니다.
            const shouldDisplay = title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
              content.toLowerCase().includes(searchKeyword.toLowerCase());

            // 만약 FAQ 제목 또는 내용 중에서 검색 키워드가 포함된 항목이 있다면 해당 FAQ 항목을 표시합니다.
            return shouldDisplay ? (
              <div key={subIndex} className="customer-box">
                <div className="faq-question" onClick={() => toggleFAQ(`${index}${subIndex}`)}>
                  {activeFAQ === `${index}${subIndex}` ? (
                    <IoIosArrowUp className="arrow-icons" />
                  ) : (
                    <IoIosArrowDown className="arrow-icons" />
                  )}
                  Q. {title}
                </div>
                {activeFAQ === `${index}${subIndex}` && (
                  <div className="faq-answer" dangerouslySetInnerHTML={{ __html: content }}></div>
                )}
              </div>
            ) : null;
          })}
        </div>
      ))}
    </div>
  )}
</div>
    </div>
  );
};

export default MMFQLST;