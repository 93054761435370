import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import Loadings from '../../components/common/Loading';
import { IoIosArrowBack } from 'react-icons/io';
import TopNavChat from '../../components/views/TopNavChat';

const MMECHAK1 = () => {
  const navigate                        = useNavigate();
  const [inputValue, setInputValue]     = useState('');
  const [hakwi, setHakwi]               = useState('1');
  const {prevPage}                      = useLocation().state || {};
  const [isLoading, setIsLoading]       = useState(false);

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  const handleInputChange = (event) => {
    let value     = event.target.value;
    // 영어와 숫자만 허용하는 정규식
    const regex     = /^[a-zA-Z0-9]+$/;

    // 입력값이 정규식을 만족하는지 검사
    if (!regex.test(value)) {
      // 정규식을 만족하지 않는 경우, 유효하지 않은 문자를 제거하여 새로운 값을 설정
      value = value.replace(/[^a-zA-Z0-9]/g, '');
  
      // 팝업 메시지 또는 다른 처리를 원하면 여기에 추가
    }

    if("021132" === giwan.giwanNo){
      setInputValue(value.toUpperCase());
    } else {
      setInputValue(value);
    }
  };

  const handleButtonClick = async () => {
    async function getHakjukChk() {
      //I-08. 학적여부 확인
      setIsLoading(true);
      const response = await connectAxios.get('/hakjuk-chk', {
        params: {
          lgnQryType    : giwan.lgnQryType,
          giwanNo       : giwan.giwanNo,
          tpid          : user.tpid,
          userMail      : user.userMail,
          userId        : user.userId,
          hakbun        : user.hakbun !== undefined ? user.hakbun : inputValue,
          userBirth     : user.userBirth,
          userName      : user.userName
        }
      });
      return response;
    };
    const response = await getHakjukChk();

    user.hakbun = response.data.hakbun;

    // 학번 한번 입력 받기
    // window.sessionStorage.setItem("userInfo",  JSON.stringify(user));

    if(prevPage.includes('customer-support')){
      navigate(prevPage, { state : { hakbun : inputValue, tabNum : "2", hakwi : hakwi } });
    } else {
      navigate(prevPage, { state : { hakbun : inputValue } });
      navigate(prevPage, { state : { hakbun : inputValue, hakwi : hakwi } });
    }
  };
  
  const navLink = () => {
    navigate('/main');
  };
  
  const getMenuTitle = (prevPage) => {
    switch (prevPage) {
      case '/ec-select1':
        return '증명서 발급';
        case '/basket-list':
          return '증명서 보관함';
          case '/customer-service':
            return '고객지원';    
            case '/history-list':
              return '발급내역';
              
              default:
                return '사용자 정보 확인'; // 다른 페이지에 대한 기본 값
              }
            };
            
            const handleRadioChange = (event) => {
              setHakwi(event.target.value);
            };
            
            
              const isButtonDisabled = !inputValue || !hakwi;
              const buttonId = isButtonDisabled ? 'buttonDisabled' : '';
  return (
    <div className='hakbun-check'>
      {isLoading && (
        <React.Fragment>
          <Loadings 
                 loadingTxt="학적 정보를 "
                 loadingTxt1="조회하는 중입니다."
          />
        </React.Fragment>
      )}
      {!isLoading && (
        <React.Fragment>
        <div className="navbar-container">
        <div className="navbar-inner">
        <div className="nav" onClick={navLink}>
          <span className="arrow-icon">
            <IoIosArrowBack />
          </span>
          <span className="nav-text"></span>
        </div>
        <p className="menu-title">{getMenuTitle(prevPage)} </p>
        <TopNavChat />

        </div>
      </div>
   

    <div className="basket-screen" id='basket-screen'>
    <h2 className='foam-title'>학적 조회</h2>
    </div>
     <div className="section-title">    
        </div>
        <div className="pagetip">
      <p  id='left-line'>학적 정보 조회를 위해 학번을 입력 후 확인을 눌러 진행해주세요.</p>
        {/* <p>증명서 전송은 1회 가능하며, 전송 후 다운로드는 제한 됩니다.</p> */}
        </div>
        <div className="section-screen"  id='first-sm'>
        <div className="info-box" id="first-sm" >
          <div className="info-row">
            <div className="info-label">이름</div>
            <div className="info-value">{user.userName}</div>
          </div>
          <div className="info-row">
            <div className="info-label">생년월일</div>
            <div className="info-value">{user.userBirth}</div>
          </div>
        </div>

        {giwan.giwanNo === '021101' && ( 
<div>

<p className="join-form-text"  id="first-sm">구분 선택</p>
          <div className='course-select-sm-container'>

  <div className='course-select-sm-box' >
  <label htmlFor="course-select-sm-1" id="course-select-sm-label">
    <input type='radio' 
    className="sm-check" 
    name="HAKWI" 
    id="course-select-sm-1"  
    value="1" 
    onChange={handleRadioChange}
    />
   <p className="course-txt">학부,<br /> 대학원 </p> </label>

  </div>
  <div className='course-select-sm-box'>
  <label htmlFor="course-select-sm-2" id="course-select-sm-label">
    <input type='radio' 
    className="sm-check"
    name="HAKWI" 
    id="course-select-sm-2" 
    value="2" 
    onChange={handleRadioChange} />
    <p className="course-txt">교직원</p> </label>

  </div>
  <div className='course-select-sm-box' >
  <label htmlFor="course-select-sm-3" id="course-select-sm-label">
    <input type='radio' className="sm-check" name="HAKWI" id="course-select-sm-3" value="3" onChange={handleRadioChange} />
    <p className="course-txt">TESOL</p></label>

  </div>
  </div>
  </div>
)}
        <div className="section-containers">
       <p className="join-form-text"  id="first-sm">학번 입력</p>
       <label  htmlFor="hakjuck " className='blind'>학번 입력 </label>
  
        <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="학번을 입력해주세요" 
        id='hakjuck'
        />


         {giwan.giwanNo === "024713" && (
         <ul className='guide-line-list' >
   <h2 className="guide-txt">[안내 사항] </h2>
          <li className='guide-line'><span>•</span><p> 학번 입력 시, 7자리일 경우 2000년 이전은 00을, 2000년 이후는 20을 앞에 붙여주시기 바랍니다.<br/> 예) 2009123456,009234567</p></li>
   {/* <li className='guide-line'><span>•</span> <p> 학번 입력 후, 다른 학적 증명서를 발급하기 위해서는 로그아웃 후 재진입해주세요.</p></li> */}
   </ul>
   )} 
        <p className='tip-text'></p>
          {/* <button className="search-num-btn" onClick={() => searchHakbunClick()}>
          학번 검색
        </button> */}
        
        <ClickButtonBig onClick={() =>handleButtonClick()}
                     disabled={isButtonDisabled} 
                     buttonId={buttonId}
                      buttonText="확인" />
      </div>
      </div>
      </React.Fragment>
      )}
    </div>
  );
  }


export default MMECHAK1;
