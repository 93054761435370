import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../components/common/Inputs/Input';
import SliderComponent from '../../components/common/Slider';
import ToggleFooter from '../../components/common/Footer/ToggleFooter?ver=2';
import { IoSearch } from 'react-icons/io5';
import NoticeItem from '../../components/common/Board/NoticeItem';
import MainChat from '../../components/views/MainChat';
import { ReactComponent as Logo } from "../../components/common/Svg/logo1.svg";

const IntroPage = () => {
  const navigate                                = useNavigate();
  const [isPageVisible, setIsPageVisible]       = useState(false);
  const [list1, setList1]                       = useState([]);
  const [notiList, setNotiList]                 = useState([]);

  const handleButtonClick = (page) => {
    navigate(`/${page}`);
  }
  // 공지사항 json으로 두개만 불러오기 
  useEffect(() => {
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('giwanInfo');

    fetch('/data/noticeInfo.json')
      .then((response) => response.json())
      .then((response) => setList1(response));
  }, []);

   /* 인덱스 2개만 뽑기 */
  useEffect(() => {
    const newsListData = list1.slice(-3);
    setNotiList(newsListData
    .sort((a, b) => b.id - a.id) // id가 큰 숫자부터 정렬
    .map((item) => {
      return <NoticeItem key={item.id} item={item} />;
    }));
  }, [list1])
 
  useEffect(() => {
    if(notiList.length > 0){
      setIsPageVisible(true);
    }
  }, [notiList])
  
  
  return (
    <React.Fragment>
    {isPageVisible && (
    <div className="intro-screen">
            <div className='login-header'>
        <div className="logo">
        <Logo />
          </div>
          <div className="logo-box">
        <MainChat className="main-chat"/>
       
        </div>
      </div>
      {/* <div className="top-section">
        <div className="logo">
          <img src="img/common/logo_WEBMINONE.png" alt="logo" id='bottomargin' />
        </div>

          <p className="text-logo" >
          대한민국 No.1 증명발급 포털
        </p>
      </div> */}
      <div className="middle-section">
        <p  className="text-3">증명서 서비스 이용을 원하시는 <br /> 학교를 먼저 검색해 주세요.
        </p>
     
      </div>
      <label htmlFor="input333" className='blind'>학교 찾기 넘어감</label>
   
      <div className="search-input-box"  id='intro-search' style={{marginTop: "16px"}} onClick={() => handleButtonClick('search-school')}>
        <IoSearch style={{ color : '#328AE6' }} />
        <Input
          type="search"
          placeholder="학교명을 입력해 주세요."
          id='input333'
          disabled="disabled" 
          autocomplete="off"
/>
</div>    
<div className="intro-button-container">
<button className="menu-button" id="intro-btn-1" onClick={() => handleButtonClick('aut')}>
        <img src="img/Main/autC.png" alt="aut" />          
          진위확인</button>

    <button className="menu-button" id="intro-btn-2" onClick={() => handleButtonClick('guide')}>
        <img src="img/Main/guideC.png" alt="guide" />    
        이용가이드      
        </button>
        </div>
        
<div className="intro-notice-area">
  <div className="notice-top">
    <div className="notice-top-left">
  <img src="img/Main/notice.png" alt="notice" />    
  <h2 className="foam-title" id='intro-title'>
    공지사항
  </h2></div>
  <Link to="/notice-all"> <button className="add-notice">
    + 더보기
  </button> </Link>
  </div>
  <div className="noti-list-ul">
  {notiList}
  </div>
{/* <ul className="noti-list-ul">
 <li className="noti-list"> 
 <div className="noti-wrap">	웹민원센터 X 카카오, 대학 전자증명서(PDF) 발급 서비스 오픈</div>
  <p className="date">23.00.00</p> 
  </li>
 <Link to="/notice-all/detail/19" noticeData={noticeData} className='link-box'>
    <li className="noti-list last-list" >	 <div className="noti-wrap">		(주)대교 인터넷증명발급 서비스 오픈</div> <p className="date">23.00.00</p> </li>
  </Link>
    </ul> */}
</div>
{/* 
<div className="banner" id='nomargin'>
      <img src="img/Main/banner1.png" alt="banner" />      
      </div> */}
        <SliderComponent/>

<ToggleFooter />
    </div>
    )}
    </React.Fragment>
  );
};

export default IntroPage;