import React, { useEffect, useState, forwardRef  } from "react";
import "./modal.css";
import ButtomButtonModal from "../Buttons/ButtomButtonModal";

import { IoIosCalendar, IoIosCheckmark } from "react-icons/io";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";


// import dayjs from "dayjs";
const ClickBottomOptionKGL = ({
  modalOpen,
  OnClickOption,
  optionValues,
  certificate,
  sebuList,
  modalClose,
}) => {

  const handleOutsideClick = (event) => {
    modalClose()
    document.documentElement.style.overflow = 'auto';
};

  const [selectOptType, setSelectOptType]     = useState([]);
  const [error, setError]                     = useState([]);
  const [optCustomTxt, setOptCustomTxt]       = useState(null);
  const [optYear, setOptYear]                 = useState(null);
  const [tour, setTour]                       = useState(' ');

  const [selectOptValues, setSelectOptValues] = useState({
    year    : ' ',
    purpose : '',
    periodOption: ' ',
    performanceCriteria: ' ', 
  });

  const [performanceCriteria, setPerformanceCriteria] = useState(' ');
  const [periodOption, setPeriodOption] = useState(' ');
  
    
  // 날짜선택옵션
  
  const [startDate, setStartDate] = useState(' ');
  const [endDate, setEndDate] = useState(' ');
  
  useEffect(()=>{
    let year_1;
    if("10109" === certificate.jobNo){
      year_1 = sebuList.MONEYYEAR.split('| |');
    } else if ("10103" === certificate.jobNo || '10203' === certificate.jobNo){
      year_1 = sebuList.YEAR.split(',');
    } else if ("10106" === certificate.jobNo || '10107' === certificate.jobNo){
      year_1 = sebuList.KYOUKYEAR.split('@');
    }
    setOptYear(year_1);

    if('10103' === certificate.jobNo || '10203' === certificate.jobNo){
      setTour("1");
      setPerformanceCriteria('1');
      if(periodOption !== ' '){
        setPeriodOption(periodOption);
        if(periodOption === '2'){
          year_1[0] = ' ';
        }
      } else {
        setPeriodOption('1');
      }

      setSelectOptValues(selectOptValues => ({
        ...selectOptValues,
        ['performanceCriteria']: '1', // 초기값 설정
        ['year'] : year_1[0]
      }));
    } else if("10109" === certificate.jobNo || "10106" === certificate.jobNo || '10107' === certificate.jobNo){
      setSelectOptValues(selectOptValues => ({
        ...selectOptValues,
        ['year'] : year_1[0]
      }));
    }

    if("10103" === certificate.jobNo || '10203' === certificate.jobNo || "10102" === certificate.jobNo  || "10202" === certificate.jobNo || "10104" === certificate.jobNo || "10204" === certificate.jobNo || "10105" === certificate.jobNo || "10205" === certificate.jobNo){
      setStartDate(new Date());
      setEndDate(new Date());
    }

  }, [sebuList, periodOption]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button 
    className="custom-input-button"
     onClick={onClick} 
     ref={ref}>
      {value}
    </button>
  ));

  // Date 객체를 원하는 형식으로 포맷팅하는 함수
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

  const OnClickOptionChk = () => {
    const joinOpt = Object.values(selectOptValues).join('@');
    OnClickOption(joinOpt);
  };

  const handleOptSelect = (e) => {
    let values = e.target.value.split("@");
    setSelectOptValues(selectOptValues => ({
      ...selectOptValues,
      [e.target.name] : values[0]
    }));
  }


const handleOptSelect_TOUR = (e) => {
  let yearList = [];

  if (periodOption === '1') {
    // periodOption이 1인 경우에 대한 처리
    if (e.target.value === '1') {
      yearList = sebuList.YEAR.split(',');
    } else if (e.target.value === '2') {
      yearList = sebuList.YEAR_RE.split(',');
    } else if (e.target.value === '3') {
      yearList = sebuList.YEAR_DR.split(',');
    } else if (e.target.value === '4') {
      yearList = sebuList.YEAR_JT.split(',');
    } else if (e.target.value === '5') {
      yearList = sebuList.YEAR_SN.split(',');
    } else if (e.target.value === '6') {
      yearList = sebuList.YEAR_ETC.split(',');
    }

    setTour(e.target.value);

  } else if (periodOption === '2') {
    // periodOption이 2인 경우에 대한 처리
    yearList = [e.target.value];
  }

  setOptYear(yearList);

  // 나머지 selectOptValues 업데이트 코드 추가
  setSelectOptValues((selectOptValues) => ({
    ...selectOptValues,
    ['year']: yearList[0],
    // ['tour']: e.target.value,
    // 다른 필요한 값들도 필요에 따라 추가
  }));

};

  //기간별 
  const handlePeriodChange = (value) => {
    setPeriodOption(value);

    if(value === '2'){
      setSelectOptValues((selectOptValues) => ({
        ...selectOptValues,
        ['year']: ' '
      }));
    }
  };

  //실적별 
  const handlePerformanceCriteriaChange = (criteria) => {
    setPerformanceCriteria(criteria);
  };


  // 직접입력 유효성 처리
  const handleCustomOptSelect = (e) => {
    const inputValue = e.target.value;
  
    // 숫자, 영문, 한글 입력만 허용, 특수문자 제거
    const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]*$/;
    let sanitizedValue = inputValue.replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
  
    // 최대 16자까지만 허용
    sanitizedValue = sanitizedValue.slice(0, 16);
  
    setOptCustomTxt(sanitizedValue);
  
    // 수정된 값으로 state 업데이트
    setSelectOptValues((selectOptValues) => ({
      ...selectOptValues,
      ['purpose']: sanitizedValue,
    }));
  };


  useEffect(() => {
    const formattedStartDate = startDate.length !== 1 ? formatDate(startDate) : startDate;
    const formattedEndDate = endDate.length !== 1 ? formatDate(endDate) : endDate;

    //날짜 유효성 검사
    if(startDate.length !== 1 && endDate.length !== 1){
      if(startDate > endDate){
        
      }
    }

    if (["10103", "10203"].includes(certificate.jobNo) && periodOption === '1') {
      setSelectOptValues((selectOptValues) => ({
        ...selectOptValues,
        ['periodOption']: `${tour}| | `,
        ['performanceCriteria']: performanceCriteria + "|" +  periodOption,
      }));
    } else if (["10103", "10203"].includes(certificate.jobNo) && periodOption === '2') {
      setSelectOptValues((selectOptValues) => ({
        ...selectOptValues,
        ['periodOption']: `${tour}|${formattedStartDate}|${formattedEndDate}`,
        ['performanceCriteria']: performanceCriteria + "|" +  periodOption,
      }));
    } else {
      setSelectOptValues((selectOptValues) => ({
        ...selectOptValues,
        ['periodOption']: ` |${formattedStartDate}|${formattedEndDate}`,
        ['performanceCriteria']: performanceCriteria + "|" +  periodOption,
      }));
    }  
  }, [startDate, endDate, periodOption, performanceCriteria, tour]);

 

  // 날짜 선택 시 호출되는 함수
  const handleDateChange = (date, field) => {
    if (field === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };
  const areAllOptionsSelected = optionValues.every((values, index) => selectOptValues[`opt${index}`] !== undefined && selectOptValues[`opt${index}`].length > 0) ;
  const artSelectDate = startDate.length === 1 || endDate.length === 1 ? false : startDate > endDate ? true : false;
  const isButtonDisabled = !areAllOptionsSelected || !optCustomTxt || selectOptValues.year === '해당사항없음' || artSelectDate;
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

  return (
    <>
      <div className={`modalContainer ${modalOpen && "modalView"}`}>
        <div className="buttonContainer" id="option-modal-kgl">
          <div className="modal-inner">
       
      <div className="section-title">
      {['10103', '10203','10106','10109','10107'].includes(certificate.jobNo) && (
          <h2 className='foam-title-s'>기간선택</h2>
          )}

          {['10103', '10203'].includes(certificate.jobNo) && (
       <div className="select-btn-container" id="bottom-margin-10">
       <button
         onClick={() => handlePeriodChange('1')}
         className={periodOption === '1' ? 'select-btn active' : 'select-btn'}
       >
         <p className={periodOption === '1' ? 'select-round active' : 'select-round'}>
           {periodOption === '1' && <IoIosCheckmark />}
         </p>
         <p>연도별</p>
       </button>
       <button
         onClick={() => handlePeriodChange('2')}
         className={periodOption === '2' ? 'select-btn active' : 'select-btn'}
       >
         <p className={periodOption === '2' ? 'select-round active' : 'select-round'}>
           {periodOption === '2' && <IoIosCheckmark />}
         </p>
         <p>기간별</p>
       </button>
          
     </div>
          )}


        </div>
     
            
            <label htmlFor="option-select" className='blind'>기간 선택</label>
{/* 전체투어 */}
{['10103', '10203'].includes(certificate.jobNo) && (
        <select className="dropdown option-box" 
            
            name='tour' 
            onChange={handleOptSelect_TOUR}
           id='option-select'>
              <option value='1' > 전체투어 </option>     
              <option value='2' > KLPGA투어 </option>     
              <option value='3' > 드림투어 </option>              
              <option value='4' > 점프투어 </option>     
              <option value='5' > 챔피언스투어 </option>     
              <option value='6' > 기타(선발,시드,아마추어 등) </option>     
            </select>
                    )}
{/* 2023 */}
{(['10106', '10109', '10107'].includes(certificate.jobNo) || (['10103', '10203'].includes(certificate.jobNo) && periodOption === '1')) && (
     <select className="dropdown option-box" 
            name='year'
            onChange={handleOptSelect}
            id='option-select'>
              {optYear && optYear.map((value) => (
                 <option value={value}>{value}</option>  
              ))}   
            </select>
 )}
 {['10103', '10203'].includes(certificate.jobNo) && periodOption === '2' && (
  <div className="date-picker-container">
    <div className="section-title">
    </div>

    {/* 시작일 선택 */}
    <div className="date-picker">
            <label htmlFor="startDate" className='blind'>시작일</label>
            {startDate.length !== 1 && (
            <DatePicker
              id="startDate"
              showIcon
              selected={startDate}
              onChange={(date) => handleDateChange(date, "start")}
              selectsStart
              maxDate={new Date()}
              startDate={startDate}
              endDate={endDate}
              dateFormat="yy.MM.dd"
              customInput={<CustomInput />}
              showPopperArrow={false}
              popperClassName="some-custom-class"
      popperPlacement="top-end"
      popperModifiers={[
        {
          name: "myModifier",
          fn(state) {
            // Do something with the state
            return state;
          },
        },
      ]}
    />
            )}

          <p className="date-between">~</p>
          {/* 종료일 선택 */}
 
            <label htmlFor="endDate" className='blind'>종료일</label>
            {endDate !== ' ' && (
           
            <DatePicker
              id="endDate"
              showIcon
              type="date"
              selected={endDate}
              onChange={(date) => handleDateChange(date, "end")}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              dateFormat="yy.MM.dd"
              customInput={<CustomInput />}
              showPopperArrow={false}
              popperClassName="some-custom-class"
              popperPlacement="top"
              popperModifiers={[
                {
                  name: "myModifier",
                  fn(state) {
                    // Do something with the state
                    return state;
                  },
                },
              ]}
            />
            )}
          </div>
    <p className='tip-text' id="option-tip-text">* 대회기간을 모두 선택해 주시기 바랍니다.</p>
  </div>
)}
  {/* 기간 선택 창 */}
  {['10102', '10104', '10204', '10105', '10205'].includes(certificate.jobNo) && (
        <div className="date-picker-container">
          <div className="section-title">
            <h2 className='foam-title-s'>대회기간</h2>
          </div>

          {/* 시작일 선택 */}
          <div className="date-picker">
            <label htmlFor="startDate" className='blind'>시작일</label>
            {startDate !== ' ' && (

            <DatePicker
              id="startDate"
              showIcon
         
              className="custom-start-date-picker"
              selected={startDate}
              onChange={(date) => handleDateChange(date, "start")}
              selectsStart
              startDate={startDate}
              customInput={<CustomInput />}
              showPopperArrow={false}
              endDate={endDate}
              maxDate={new Date()}
              dateFormat="yy.MM.dd"
              popperClassName="some-custom-class"
              popperPlacement="bottom"
              popperModifiers={[
                {
                  name: "myModifier",
                  fn(state) {
                    // Do something with the state
                    return state;
                  },
                },
              ]}
            />
            )}

          <p className="date-between">~</p>
          {/* 종료일 선택 */}
 
            <label htmlFor="endDate" className='blind'>종료일</label>
            {endDate !== ' ' && (

            <DatePicker
              id="endDate"
              className="custom-end-date-picker"
              selected={endDate}
              onChange={(date) => handleDateChange(date, "end")}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              customInput={<CustomInput />}
              showPopperArrow={false}
              maxDate={new Date()}
              dateFormat="yy.MM.dd"
              showIcon
              popperClassName="some-custom-class"
              popperPlacement="bottom"
              popperModifiers={[
                {
                  name: "myModifier",
                  fn(state) {
                    // Do something with the state
                    return state;
                  },
                },
              ]}
            />
            )}
          </div>
          <p className='tip-text' id="option-tip-text">* 대회기간을 모두 선택해 주시기 바랍니다.</p>  
        </div>

      )}
             {/* 실적기준 */}

                {['10103', '10203'].includes(certificate.jobNo) && (
                <div className="section-title">
                <h2 className='foam-title-s'>실적기준</h2>
                <div className="select-btn-container"  style={{ gap: '10px' }}>
                  <button
                    onClick={() => handlePerformanceCriteriaChange('1')}
                    className={performanceCriteria === '1' ? 'select-btn active' : 'select-btn'}
                  >
                    <p id="select-btn-3" className={performanceCriteria === '1' ? 'select-round active' : 'select-round'}>
                      {performanceCriteria === '1' && <IoIosCheckmark />}
                    </p>
                    <p className="select-round-p">전체<br/>기록</p>
                  </button>
                  <button
                    onClick={() => handlePerformanceCriteriaChange('2')}
                    className={performanceCriteria === '2' ? 'select-btn active' : 'select-btn'}
                  >
                    <p id="select-btn-3" className={performanceCriteria ===  '2' ? 'select-round active' : 'select-round'}>
                      {performanceCriteria ===  '2' && <IoIosCheckmark />}
                    </p>
                    <p className="select-round-p">10위<br/>이내</p>
                  </button>
                  <button
                    onClick={() => handlePerformanceCriteriaChange( '3')}
                    className={performanceCriteria === '3' ? 'select-btn active' : 'select-btn'}
                  >
                    <p id="select-btn-3" className={performanceCriteria === '3' ? 'select-round active' : 'select-round'}>
                      {performanceCriteria === '3' && <IoIosCheckmark />}
                    </p>
                    <p className="select-round-p">20위<br/>이내</p>
                  </button> 
                </div>
              </div>
        )}

        <div className="section-title">
        <h2 className='foam-title-s'>용도</h2>
        <input 
                  type="text" 
                  name=""
                  id='input1312'
                  value={optCustomTxt} 
                  placeholder="ex) 제출용, 확인용"
                  onChange={handleCustomOptSelect}
                  maxLength="21"
                   />
                  {error.isDisplay && (
                  <p className="error-message-option">{error.message}</p>)
                }
        </div>
          
        </div>
        <ButtomButtonModal 
        onClick ={() => OnClickOptionChk()} 
        buttonText="선택완료" 
        buttonId={buttonId}  
        disabled={isButtonDisabled} />
        </div>
      </div>
      <div onClick={handleOutsideClick} className={`modalBackground ${modalOpen && "modalBackgroundView"}`} />
    </>
  );
};

export default ClickBottomOptionKGL;