import { BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import SplashScreen1 from './screens/Onboarding/splashs/SplashScreen1';
import SplashScreen2 from './screens/Onboarding/splashs/SplashScreen2';
import SplashScreen3 from './screens/Onboarding/splashs/SplashScreen3';
import SearchSchool from './screens/Onboarding/SearchSchool?ver=1';
import PermissionAgreement from './screens/Onboarding/PermissionAgreement';
import Login from './screens/LoginMember/Login?ver=4';
import MemberRegister from './screens/LoginMember/MemberRegister';
import MemberRegister2 from './screens/LoginMember/MemberRegister2';
import MemberRegister3 from './screens/LoginMember/MemberRegister3';
import MemberSearch from './screens/LoginMember/MemberSearch';
import MainLayout from './screens/Main/MainLayout?ver=1';
import VoiceEye from './screens/Main/VoiceEye';
import MMECPAY1 from './screens/IssueCertificate/MMECPAY1?ver=2';
import MMECPAY2 from './screens/IssueCertificate/MMECPAY2';
import MMECSEL1 from './screens/IssueCertificate/MMECSEL1?ver=1';
import MMECSEL2 from './screens/IssueCertificate/MMECSEL2?ver=2';
import MMECSEL3 from './screens/IssueCertificate/MMECSEL3?ver=2';
import MMBALST from './screens/BasketCertificate/MMBALST?ver=1';
import MMBAKKO1 from './screens/BasketCertificate/MMBAKKO1';
import MMBAKKO2 from './screens/BasketCertificate/MMBAKKO2';
import MMBAEAL from './screens/BasketCertificate/MMBAEAL?ver=2';
import MMHILIST from './screens/HistoryCertificate/MMHILST?ver=1';
import MMCUSTOMER from './screens/CustomerService/MMCUSTOMER.jsx';
import MMVAAUT from './screens/Authenticity/MMVAAUT?ver=1';
import Settings from './screens/Setting/Settings';
import NotificationSettings from './screens/Setting/NotificationSettings';
import TermsUse from './screens/Setting/TermsUse?ver=2';
import PrivacyPolicy from './screens/Setting/PrivacyPolicy?ver=3';
import MMGUINF from './screens/CustomerService/MMGUINF';
import PasswordChange from './screens/Setting/PasswordChange?ver=1';
import MMBADEL from './screens/BasketCertificate/MMBADEL?ver=2';
import MMECHAK1 from './screens/IssueCertificate/MMECHAK1?ver=1';
import "./styles/default.css?ver=2"
import RegisterTermsUse from './components/views/Member/RegisterTermsUse?ver=2';
import RegisterPrivacyPolicy from './components/views/Member/RegisterPrivacyPolicy?ver=3';
import MMQAWRT from './components/views/Customer/MMQAWRT';
import MMNOLST from './screens/Notice/MMNOLST';
import MMECPAYR from './screens/IssueCertificate/MMECPAYR?ver=1';
import AuthRegister from './screens/LoginMember/auth/AuthRegister';
import DownloadLink from './screens/KkoDownloadLink/DownloadLink?ver=2';
import KkoLink from './screens/KkoDownloadLink/KkoLink?ver=2';
import MemberRegisterFail from './screens/LoginMember/MemberRegisterFail';
import MMVAEAL from './screens/Authenticity/MMVAEAL?ver=1';
import MMECFAL from './screens/IssueCertificate/MMECFAL?ver=2';
import Withdraw from './screens/Setting/Withdraw?ver=1';
import MemberSetting from './screens/Setting/MemberSetting';
import PhoneChange from './screens/Setting/PhoneChange?ver=1';
import PrivateRoute from './components/common/Route/PrivateRoute';
import SelectRoute from './components/common/Route/SelectRoute';
import setScreenHeight from './utils/setScreenHeight';
import MainIndex from './screens/Onboarding/MainIndex';
import PopupNotice from './screens/Notice/PopupNotice';
import IntroPage from './screens/Onboarding/IntroPage';
import MMBAFAX2 from './screens/BasketCertificate/MMBAFAX2';
import MMBAEML2 from './screens/BasketCertificate/MMBAEML2';
import Board from './components/common/Board/Board';
import BoardDetail from './components/common/Board/BoardDetail';
import MMBAREJ from './components/views/Basket/MMBAREJ';
import { BrowserView, MobileView } from 'react-device-detect';
import WebRedirect from './components/common/Route/WebRedirect';
import MMECPAY3 from './screens/IssueCertificate/MMECPAY3';
import MemberSettingSecurity from './screens/Setting/MemberSettingSecurity';
import MMECPAYF from './screens/IssueCertificate/MMECPAYF';
import ScrollToTop from './hooks/ScrollToTop';
import PolicyTerms from './screens/Setting/PolicyTerms';
import MMECSEL2_SEBU from './screens/IssueCertificate/MMECSEL2_SEBU';
import SecukitIndex from './secukit-one/callback/index.jsx';
import IndexRedirect from './components/common/Route/IndexRedirect.jsx';
import TestPage from './constans/TestPage';
import SSOLogin from './screens/LoginMember/SSOLogin.jsx';


function App() {
  
// 처음 렌더링할 때 기준 높이로 vh를 계산하게되기 때문에 중간에 화면사이즈가 바뀌면 실시간으로 적용되지 않음
useEffect(() => {
  setScreenHeight();
  
  // resize 이벤트가 발생하면 다시 계산하도록 아래 코드 추가
  window.addEventListener('resize', setScreenHeight);
  return () => window.removeEventListener('resize', setScreenHeight);
}, []);


  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop>
          <AppContent />
          {/* <MainChat /> */}
       </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}
export default App;


function AppContent() {
  const location           = useLocation();
  const [width, setWidth]  = useState(window.innerWidth);
  const isAuthenticated    = sessionStorage.getItem("userInfo") !== null && sessionStorage.getItem("giwanInfo") !== null ? true : false;
  const isGiwanSelected    = sessionStorage.getItem("giwanInfo") !== null ? true : false;

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    
    return () => {
        // cleanup
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    
  return (
  <div className='App-contente'>
    {/* {!isMainPage && <TopNavBar />} */}
      <BrowserView>
        <Routes>
          <Route path="/" element={<WebRedirect />}/>
          <Route path="/download/input" element={<KkoLink/>}/>
          <Route path="/download/down" element={<DownloadLink/>}/>
          <Route path="*" element={<WebRedirect />} />
        </Routes>
      </BrowserView>
      <MobileView>
      <Routes>
        <Route path="/" element={<MainIndex />}/>
        <Route path="/test" element={<TestPage />}/>
        <Route path="/sso" element={<SSOLogin />}/>

        <Route path="/intro" element={<IntroPage />}/>
        <Route path="/splash1" element={<SplashScreen1 />}/>
        <Route path="/splash2" element={<SplashScreen2 />}/>
        <Route path="/splash3" element={<SplashScreen3 />}/>
        <Route path="/permission" element={<PermissionAgreement />}/>
        <Route path="/search-school" element={<SearchSchool />}/>
        <Route path="/login" element={<SelectRoute component={<Login/>} isGiwanSelected={isGiwanSelected}/>}/>\
        <Route path="/member-register" element={<SelectRoute component={<MemberRegister/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/member-register:agree-terms" element={<SelectRoute component={<RegisterTermsUse/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/member-register:agree-policy" element={<SelectRoute component={<RegisterPrivacyPolicy/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/member-register2" element={<SelectRoute component={<MemberRegister2/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/member-register3" element={<SelectRoute component={<MemberRegister3/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/member-register-fail" element={<SelectRoute component={<MemberRegisterFail/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/member-search" element={<SelectRoute component={<MemberSearch/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/aut" element={<MMVAAUT/>} />
        <Route path="/aut/early" element={<MMVAEAL/>} />
        <Route path="/guide" element={<MMGUINF/>}/>
        <Route path="/notice" element={<SelectRoute component={<MMNOLST/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/notice-all" element={<Board/>} />
        <Route path='/notice-all/detail/:id' element={<BoardDetail/>} />
        <Route path="/popup-notice" element={<SelectRoute component={<PopupNotice/>} isGiwanSelected={isGiwanSelected}/>}/>
        <Route path="/customer-support" element={<SelectRoute component={<MMCUSTOMER/>} isGiwanSelected={isGiwanSelected}/>}/>
        
        {/* 로그인&기관선택 필요 페이지 */}
        <Route path="/main" element={<PrivateRoute component={<MainLayout/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/voice-eye" element={<PrivateRoute component={<VoiceEye/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-pay1" element={<PrivateRoute component={<MMECPAY1/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-pay2" element={<PrivateRoute component={<MMECPAY2/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-pay3" element={<PrivateRoute component={<MMECPAY3/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-pay/return" element={<PrivateRoute component={<MMECPAYR/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-pay/fail" element={<PrivateRoute component={<MMECPAYF/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-select1" element={<PrivateRoute component={<MMECSEL1/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-select2" element={<PrivateRoute component={<MMECSEL2/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-select2/sebu" element={<PrivateRoute component={<MMECSEL2_SEBU/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-select3" element={<PrivateRoute component={<MMECSEL3/>} isAuthenticated={isAuthenticated}/>}/>
        
        <Route path="/ec-select/fail" element={<PrivateRoute component={<MMECFAL/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/ec-select/hakbun" element={<PrivateRoute component={<MMECHAK1/>} isAuthenticated={isAuthenticated}/>}/>  
        {/* <Route path="/ec-select/hakbun2" element={<MMECHAK2 />}/> */}
        
        <Route path="/basket-list" element={<PrivateRoute component={<MMBALST/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/basket-early" element={<PrivateRoute component={<MMBAEAL/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/basket-kko" element={<PrivateRoute component={<MMBAKKO1/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/basket-del" element={<PrivateRoute component={<MMBADEL/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/basket-kko2" element={<PrivateRoute component={<MMBAKKO2/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/basket-fax2" element={<PrivateRoute component={<MMBAFAX2/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/basket-eml2" element={<PrivateRoute component={<MMBAEML2/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/basket-kko" element={<PrivateRoute component={<MMBAKKO1/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/history-list" element={<PrivateRoute component={<MMHILIST/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/reject-certi" element={<PrivateRoute component={<MMBAREJ/>} isAuthenticated={isAuthenticated}/>}/>
        
        <Route path="/customer-consult" element={<PrivateRoute component={<MMQAWRT/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/setting" element={<PrivateRoute component={<Settings/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/notification-settings" element={<PrivateRoute component={<NotificationSettings/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/setting/policy-terms" element={<PolicyTerms/>}/>
        <Route path="/setting/terms" element={<TermsUse/>}/>
        <Route path="/setting/privacy" element={<PrivacyPolicy/>}/>
        
        <Route path="/setting/member" element={<PrivateRoute component={<MemberSetting/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/setting/member/password" element={<PrivateRoute component={<MemberSettingSecurity/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/setting/member/password-change" element={<PrivateRoute component={<PasswordChange/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/setting/member/phone-change" element={<PrivateRoute component={<PhoneChange/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/setting/member/withdraw" element={<PrivateRoute component={<Withdraw/>} isAuthenticated={isAuthenticated}/>}/>
        <Route path="/auth/register" element={<AuthRegister/>}/>
        <Route path="/download/input" element={<KkoLink/>}/>
        <Route path="/download/down" element={<DownloadLink/>}/>     

        {/* secukit useCallback */}
        <Route path="/secukit-one/callback/*" element={<SecukitIndex/>} isGiwanSelected={isGiwanSelected}/>     

        <Route path="*" element={<IndexRedirect />} /> {/* 나머지 모든 경로에 대한 라우트 */}
      </Routes>
    
      </MobileView>
    {/* {!isSplash && <TabBar />} */}

    </div>
  );
  } 