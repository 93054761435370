import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import PATHNAME_TITLE_MAP from "../../../constans/pathnameTitleMap";
import "./topNavBar.css?ver=2"
import TopNavChat from "../../views/TopNavChat";


const TopNavBar = () => {
  const pathname = useLocation().pathname.slice(1);
  const navigate = useNavigate();


  const navLink = () => {
    if (pathname === 'basket-list' || pathname === 'setting' || pathname === 'customer-support'  || pathname === 'ec-select/hakbun') {
      navigate('/main');
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="navbar-container">
      <div className="navbar-inner">
      {pathname !== "member-register3" ? (
  <div className="nav" onClick={navLink}>
    <IoIosArrowBack className="arrow-icon" />
  </div>
) : (
  <div>
    
  </div>
)}
      <p className="menu-title">{PATHNAME_TITLE_MAP[pathname]}</p>
      <TopNavChat className="topnav-chat" />
      </div>
    </div>
 
 );
};

export default TopNavBar;