import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";
import TopNavBar from '../../components/common/TopNavBar';


const MMECPAY2 = () => {
  return (
    <div id='screen' className='finish-screen'>

      <React.Fragment>
        <div id='middle-top' className="middle-container">
        <div id='icon-container'>
        <FaCheckCircle size={100} color="#328AE6" id='icon'/>
        </div>
        <h3 className="middle-txt">증명서 발급이 완료되었습니다!</h3>
        <p className="small-txt">증명서 보관함에서 다운로드  <br /> 또는 전송 신청이 가능합니다.</p>
        </div>
        <div className="home-button-container">
        <Link to='/main'><button className="home-btn">
              <span>홈으로 가기</span>
          </button></Link>
        <Link to='/basket-list'><button className="submit-btn" >
              <span>증명서 보관함으로 가기</span>
          </button></Link>
      
        </div>
      </React.Fragment>
    </div>
  );
}


export default MMECPAY2;
 