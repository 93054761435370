import React from "react";
import "./clickButtonBig.css?ver=1"

const ButtomButtonModal = ({ disabled, onClick, buttonText,buttonId }) => {
  return (

    <div className="bottom-modal-container">
    <button type="button" onClick={onClick} className="bottom-buttons"  disabled={disabled} id={buttonId}>
      {buttonText}
    </button>
    </div>
  );
};

export default ButtomButtonModal;

