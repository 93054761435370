import React, { useEffect, useState } from 'react';
import TopNavBar from '../../components/common/TopNavBar';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { connectAxios } from '../../module/connectAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import Loadings from '../../components/common/Loading';

const MMHILIST = () => {
    const  navigate                         = useNavigate();
    const [activeTab, setActiveTab]         = useState('전체');
    const [expandedItems, setExpandedItems] = useState([]);
    const [issuanceList, setIssuanceList]   = useState([]);
    const [totalCnt, setTotalCnt]           = useState([]);
    const [isPageVisible, setIsPageVisible] = useState(false);
    let   { hakbun, hakwi }                 = useLocation().state || {}; 
    const [isLoading, setIsLoading]         = useState(false);

    const user    = JSON.parse(sessionStorage.getItem('userInfo'));
    const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

    async function getHakjukChk() {
      //I-08. 학적여부 확인
      setIsLoading(true);
      const response = await connectAxios.get('/hakjuk-chk', {
        params: {
          lgnQryType    : giwan.lgnQryType,
          giwanNo       : giwan.giwanNo,
          tpid          : user.tpid,
          userMail      : user.userMail,
          userId        : user.userId,
          hakbun        : user.hakbun !== undefined ? user.hakbun : hakbun,
          userBirth     : user.userBirth,
          userName      : user.userName,
          hakwi         : hakwi
        }
      });
      return response;
    };

    async function sampleTpidChk () {
      const response    = await getHakjukChk();
      const resultCode  = response.data.resultCode;

      hakbun = response.data.hakbun;

      if(resultCode === "200"){
        setIsLoading(false);
        const newUser = { ...user, tpid : response.data.tpid }
        window.sessionStorage.setItem("userInfo",  JSON.stringify(newUser));
      } else if(resultCode === "201"){
        setIsLoading(false);
        navigate('/ec-select/fail');
      } else if(resultCode === "202"){
        setIsLoading(false);
        navigate('/ec-select/hakbun', { state : { prevPage : '/history-list'}});
      
      } else if(resultCode === "203"){
        setIsLoading(false);
        navigate('/ec-select/fail', { state : { msg : response.data.resultMessage } });

      } else if(resultCode === "204"){
        setIsLoading(false);
        navigate('/ec-select/fail', { state : { msg : response.data.displayMessage } });
      }
    }

    const getIssueHistory = async (issueType) => {
      const response = await connectAxios.get('/issue-history', {
        params : {
          giwanNo     : giwan.giwanNo,
          tpid        : user.tpid,
          lgnQryType  : giwan.lgnQryType,
          issueType   : issueType
        }
      });

      const result = response.data;
      setTotalCnt(result.totalCnt);
      setIssuanceList(result.issueList);
      setIsPageVisible(true);
    };

    //로딩시
    useEffect(() => {
      sampleTpidChk();
      getIssueHistory('A');
    },[]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setExpandedItems([]);

    let type = "A";

    if(tab === "PDF") {
      type = "P";
    } else if(tab === "보안") {
      type = "M";
    } else if(tab === "출력"){
      type = "I";
    } else if(tab === "우편"){
      type = "Z";
    }
    
    getIssueHistory(type);
  };

  const handleIssuanceClick = (index) => {
    setExpandedItems((prevItems) => {
      if (prevItems.includes(index)) {
        return prevItems.filter((itemId) => itemId !== index);
      } else {
        return [...prevItems, index];
      }
    });
  };

  return (
    <div id='history'>
              {isLoading && (
        <React.Fragment>
          <Loadings
              loadingTxt="발급내역을 "
              loadingTxt1="불러오는 중입니다."
          />
        </React.Fragment>
      )}
    {isPageVisible && !isLoading && (
      <React.Fragment>
        <div id="history-content">
<TopNavBar/>
<div className="history-screen" id='history-screen'>
<h2 className='foam-title'>증명서 발급내역</h2>
         <p className='certi-count'> 총 {totalCnt} 건</p>
</div>
      <div className="section-title">
              </div>
      <div className="tabs">
      <button
          className={`tab-button-round ${activeTab === '전체' ? 'active' : ''}`}
          onClick={() => handleTabClick('전체')}
        >
          전체
        </button>
        <button
          className={`tab-button-round ${activeTab === 'PDF' ? 'active' : ''}`}
          onClick={() => handleTabClick('PDF')}
        >
          PDF
        </button>
        {/* <button
          className={`tab-button-round ${activeTab === '보안' ? 'active' : ''}`}
          onClick={() => handleTabClick('보안')}
        >
          보안
        </button> */}
        <button
          className={`tab-button-round ${activeTab === '출력' ? 'active' : ''}`}
          onClick={() => handleTabClick('출력')}
        >
          출력
        </button>
        <button
          className={`tab-button-round ${activeTab === '우편' ? 'active' : ''}`}
          onClick={() => handleTabClick('우편')}
        >
          우편
        </button>
       
      </div>
      <div className="pagetip">
      <p id='left-line'>최근 90일 이내 발급받은 증명서를 확인할 수 있습니다.</p>
        {/* <p>웹사이트에서 발급받은 증명서의 내역을 확인할 수 있습니다.</p> */}
      <p id='left-line'>* 출력 및 우편서비스는 pc 환경에서 이용하실 수 있습니다.</p>

        </div>
      <div className="history-area">
        <div className="scroll-area">
  {totalCnt > 0 ? (
    issuanceList.map((item, index) => (
      <div key={index} className='contents-body'>
        <div className="issuance-row" onClick={() => handleIssuanceClick(index)}>
          <div className="issuance-close">
            <div className="issuance-info">
              <p className='card-tap-btn-history'>{item.certType}</p>
              <p className='certi-name'>{item.jobDisplayNe}</p>
              <p className='date-tap'>{item.minRegDate}</p>           
              <button>
                {expandedItems.includes(index) ? (
                  <IoIosArrowUp size='1rem' />
                ) : (
                  <IoIosArrowDown size='1rem' />
                )}
              </button>
            </div>
            {expandedItems.includes(index) && (
  <div className="issuance-open">
    {item.receiveList.length === 0 && item.verifyList.length === 0 ? (
      <div className="detail-info">
        <p>이용내역이 없습니다.</p>
      </div>
    ) : (
      <div>
        {item.receiveList.map((subItem, subIndex) => (
          <div key={subIndex} className="issuance-open-card">
            <div className='detail-info'>
              <p className='type-tap'> {subItem.sendType}</p>
              {/* <p className='cnt-tap'>  {subItem.sendCnt} 건</p> */}
              <div><p className='date-tap'> {subItem.sendDate}</p>
              <p className='time-tap'> {subItem.sendTime}</p></div>
            </div>
            {subItem.sendType === '우편신청' && (
              <div className='detail-info'>
                <p className='type-tap'> 처리상태</p>
               <div>
                <p className='state-taps'>{subItem.postStatus}</p>
                <p className='time-tap'></p></div>
              </div>
            )}
          </div>
        ))}
        
        {item.verifyList.map((verifyItem, verifyIndex) => (
          <div key={verifyIndex} className="issuance-open-card">
            <div className='detail-info'>
              <p className='type-tap'> 진위확인 </p>
              <div><p className='state-tap'> {verifyItem}</p></div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
)}
          </div>
        </div>
      </div>
    ))
  ) : (
    <p className='page-tip'>증명서 발급내역이 없습니다.</p>
  )}
</div>
</div>
</div>
      </React.Fragment>
    )}  
</div>
  );
};

export default MMHILIST;
