import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import ClickBottomOption from '../../components/common/Modal/ClickBottomOption?ver=1';
import { IoIosArrowForward } from 'react-icons/io';
import Loadings from '../../components/common/Loading';
import ClickBottomOptionKGL from './../../components/common/Modal/ClickBottomOptionKGL';

const MMECSEL2 = () => {
  const navigate                                  = useNavigate();
  const [certificates, setCertificates]           = useState([]);
  const [bookMessage, setBookMessage]             = useState('');
  const [modalOpen, setModalOpen]                 = useState(false);
  const [optValues, setOptValues]                 = useState([]);
  const [selectcertificate, setSelectCertificate] = useState(null);
  const [sebuMap, setSebuMap]                     = useState(null);
  const [isLoading, setIsLoading]                 = useState(false);

  const { state }           = useLocation();
  const { hakjuk, korYN }   = state;

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  useEffect(() => {
    setIsLoading(true);
    //I-03. 발급가능한 증명서 리스트
    async function getCertList() {
      
      await connectAxios.get('/issue',{
        params : {
          giwanNo     : giwan.giwanNo,
          tpid        : user.tpid,
          hakbun      : hakjuk.hakbun,
          jobSubGpCd  : hakjuk.jobSubGpCd,
          ename       : hakjuk.ename !== undefined ? hakjuk.ename : "",
          sangtae     : hakjuk.sangtae,
          userBirth   : user.userBirth,
          korYN       : korYN,
          lgnQryType  : giwan.lgnQryType,
          hakgwa      : hakjuk.hakgwa
        }
      })
      .then((response) => {
        setBookMessage(response.data.bookMessage);
        setCertificates(response.data.jobList);
        setSebuMap(response.data.sebuList);
        setIsLoading(false);
      });
    }
    getCertList();
  }, []);
  
  //증명서 리스트 클릭 시
  const handleCertClick = async (certificate) => {
    if("111106" !== giwan.giwanNo){
      //I-04. 증명서 옵션 선택
      const response = await connectAxios.get('/option', {
        params : {
          giwanNo : giwan.giwanNo,
          jobNo   : certificate.jobNo,
          hakbun  : hakjuk.hakbun,
          sangtae : hakjuk.sangtae,
          hakgwa  : hakjuk.hakgwa
        }
      });
  
      const data = response.data;
      if(0 < data.opt.length){
        setSelectCertificate(certificate);
        setOptValues(data.opt);
      } else {
        goNextPage("", certificate);
      }
    } else {
      //("111106" !== giwan.giwanNo)일때 처리
      setSelectCertificate(certificate);
    }
  };

  const handleModalOpenChk = async (certificate) => {
    await handleCertClick(certificate);

    setModalOpen(true);
    document.documentElement.style.overflow = 'hidden';

  };

  function OnClickOption (joinOpt) {
    goNextPage(joinOpt, selectcertificate) ;
  }

  function goNextPage(joinOpt, certificate) {
    let page = "";

    if("021104" === giwan.giwanNo || "021192" === giwan.giwanNo){
      page = "/ec-select2/sebu"
    } else {
      page = "/ec-select3";
    }
    
  navigate(`${page}`, { 
      state : { 
        hakjuk      : hakjuk,
        korYN       : korYN,
        certificate : certificate,
        option      : joinOpt
      } 
    });
  }

   useEffect(() => {
    if (modalOpen) {
      document.documentElement.style.overflow = 'hidden';
    }
  }, [modalOpen]);

  const chkJobName = (jobName) => {
    if(!('024801' === giwan.giwanNo && '02' === hakjuk.jobSubGpCd)){
      if(hakjuk.sangtae.slice(0,2) === jobName.slice(0,2)){
        jobName += "(최종학력증명서)";
      }
    }
    return jobName;
  };

  return (  
    <div className="MMECSEL2">
      {isLoading && (
        <React.Fragment>
          <Loadings
                 loadingTxt="증명서 리스트를"
                 loadingTxt1="불러오는 중입니다."
          />
        </React.Fragment>
      )}
      {!isLoading && (
        <>
        <TopNavBar/>
        <div className="select-screen">
        <h2 className='foam-title'>증명서 선택</h2>       
        </div>
        </>
      )}
      
      <div className="section-title">

      </div>
      <div className="select-area">
      {/* <div className="select-container">
      {certificates.length === 0 ? (
        <div className="list-info">
      <p>발급 가능한 증명서가 존재 하지 않습니다.</p>
       </div>
) : (
  certificates.map((certificate) => (
    <button className='certi-select' key={certificate.jobDisplayNe} onClick={() => handleModalOpenChk(certificate)}>
      {certificate.jobDisplayNe} <IoIosArrowForward color='var(--main-blue-color)'/>
    </button>
  ))
)}
        </div> */}
          <div className="select-container">
        <ul className="select-container-inner" >
  {isLoading ? (
       <div className="list-info">

     </div>
  ) : bookMessage !== '' ? (
    <div className="list-info">
      <p dangerouslySetInnerHTML={{ __html: bookMessage}}></p>
    </div>
    ) : 
    certificates.length > 0 ? (
      certificates.map((certificate) => (
        <li className='certi-select-list'    key={certificate.jobNe} 
        onClick={() => handleModalOpenChk(certificate)}>
        <p className='certi-select-button'>
     
        
        {chkJobName(certificate.jobNe)} 
        </p>
        <IoIosArrowForward color='var(--main-blue-color)'/>
        </li>
      ))
      ) : (
      <div className="list-info">
        <p>발급 가능한 증명서가 존재하지 않습니다.</p>
      </div>
    )}
</ul>
</div>
    </div>

    {modalOpen && (
            giwan.giwanNo === '111106' ? (
              <ClickBottomOptionKGL
                modalOpen={modalOpen}
                OnClickOption={OnClickOption}
                optionValues={optValues}
                certificate={selectcertificate}
                sebuList={sebuMap}
                modalClose={()=>setModalOpen(false)}
              />
            ) : (
        <ClickBottomOption
          modalOpen={modalOpen}
          OnClickOption={OnClickOption}
          optionValues={optValues}
          certificate={selectcertificate}
          modalClose={()=>setModalOpen(false)}
        />
            )
        )
      }
  </div> 
  );
};

export default MMECSEL2;