import React, { useEffect, useState } from "react";
import { connectAxios } from "../../../../module/connectAxios";
import Loadings from "../../Loading";
import { useNavigate } from "react-router-dom";
import OneButtonPopup from "../../Popup/OneButtonPopup";


const SecuKitLogin = ({
  // changeVisibleFunction
}) => {
  const navigate                          = useNavigate();
  const [showPopup, setShowPopup]         = useState(false);
  const [popupText, setPopupText]         = useState('');
  const [isOneButtonPopup, setIsOneButtonPopup] = useState(false);
  const [isSimpleLogin, setIsSimpleLogin]       = useState(true);
  const [isJsLoading, setIsJsLoading    ]       = useState(true);
  const [isDialogOpen, setIsDialogOpen]         = useState(false);
  const [ ip , setIp ]                          = useState("");
  const [user, setUser]                         = useState({
    tpid      : '',
    hakbun    : '',
    userName  : '',
    userBirth : '',
    userMail  : '',
    userTel   : '',
    userCi    : ''
  });

  function closeBtnClick () {
    setIsDialogOpen(false);
  }

  const loadScript = async (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  useEffect(()=>{
    //다이얼로그 켜졌을때 뒤로가기 이벤트 변경
    window.history.pushState(null, '', '');
    if(isDialogOpen){
      window.onpopstate = () => {
        window.location.href='/search-school';
      }
    } else {
      window.onpopstate = () => {
        window.location.href='/login';
      }
    }

    return () => {
      //언마운트
      var closeBtn = document.querySelector('#secukit-close-all');

      if(closeBtn){
        closeBtn.removeEventListener("click", closeBtnClick);
      }
    };
  },[]);

  const clickSimpleBtn = async () => {
    if(window.secukit && window.secukit.init !== undefined){
      setIsJsLoading(true);
      window.secukit.init();
      window.secukit.setDefaultMethod('kakao');
      window.secukit.dialog.open();
      
      setIsDialogOpen(true);
      //zindex
      document.getElementsByClassName('secukit')[0].style.zindex = "9999";
      // changeVisibleFunction(true);

      //이벤트리스너
      var closeBtn = document.querySelector('#secukit-close-all');
      closeBtn.addEventListener("click", closeBtnClick);
      
      var options = {
          targetCert: `${process.env.REACT_APP_CERTIFICATE}`,
          title : "한국정보인증 간편인증 요청",
          messageType: 'envelop',
          charset:'utf8'
      };
  
      var message   = "웹민원센터";
      const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));
  
      window.secukit.api.auth(message, options, function (res, err) {
        
        if (err !== undefined){
          setShowPopup(false);
          alert('code: ' + err.code + '\n' + 'message:' + err.message);
  
          return;
        }
        
        //테스트
        const simpleAuth = async () => {
          window.secukit.dialog.close();
          setShowPopup(true);
          setIsDialogOpen(false);

          const response = await connectAxios.get('/sign/simple-auth', {
            params: {
              userCi : res.userCi,
              userName : res.userName,
              userPhone : res.userPhone,
              giwanNo : giwan.giwanNo,
              provider : res.provider
            }
          });
  
          if(response.data.resultCode === "200"){
            setUser(response.data.user);
          } else if (response.data.resultCode === '201') {
            setShowPopup(false);
            setPopupText('가입된 회원정보가 없습니다.');      
            setIsOneButtonPopup(true);
          }
        }
  
        simpleAuth();
        
        // Secukit PRO
        if (window.secukit.context.method !== 'cert') {
          // console.log(res);
        }
      });
    } else {
      setIsJsLoading(false);
      await sleep(500);
      clickSimpleBtn();
    }
  }

  //로그인
  useEffect(()=>{
    if(user.tpid !== ''){
      sessionStorage.setItem('userInfo', JSON.stringify(user));
      navigate('/popup-notice');
    }
      
  },[user]);

  //스크립트 불러오기
  useEffect(()=>{
    const loadJQuery = async () => {
      await loadScript(`/secukit-one/plugin/jquery/jquery.3.5.1.min.js`);
      setIsSimpleLogin(false);

      loadScript(`/secukit-one/plugin/jquery/jquery-ui.min.js`);
      loadScript(`/secukit-one/plugin/jquery/jquery.scrollbar.min.js`);

      //-----------------간편결제 테스트용--------------------//
      // let ip;
      // await axios.get('https://geolocation-db.com/json/').then((res) => {
      // ip = res.data.IPv4;
      // })

      // if("39.117.24.254" === ip || "106.102.128.21" == ip) {
      //   loadScript(`/secukit-one/config/config_test.js`);
      // } else {
        await loadScript(`/secukit-one/config/config.js`);
      // }
      loadScript(`/secukit-one/main/main.js`);
    };

    if(isSimpleLogin){
      if (document.querySelector(`script[src="/secukit-one/main/main.js"]`)) {
        setIsSimpleLogin(false);
        return;
      }
      loadJQuery();
    }

  },[isSimpleLogin]);

  //회원가입 이동
  function navReg(){
    window.location.href = '/member-register';
  }
  
  return(
  <>

     {(isSimpleLogin || !isJsLoading || isDialogOpen) && (
        <React.Fragment>
          <Loadings/>
        </React.Fragment>
      )}
    {showPopup && (
      <React.Fragment>
            <Loadings loadingTxt={'로그인 진행 중입니다.'} loadingTxt1={'잠시만 기다려주세요.'}/>
      </React.Fragment>
    )}
     <button className='kakao-login'  id='social-login' onClick={() => clickSimpleBtn()}>
      간편 인증 로그인
    </button>
    {/* <button className='kakao-login'  id='social-login' onClick={() => clickSimpleBtn()}>
      <img src="img/Login/kakao.png" alt="social-login" /> 카카오 인증 로그인
    </button> */}
    {/* <button onClick={() => buttonClick('naver')} className='naver-login' id='social-login'>
      <img src="img/Login/naver.png" alt="social-login" /> 네이버 인증 로그인
    </button> */}

    {isOneButtonPopup && (
      <OneButtonPopup 
      onClick={navReg}  
      popupText={popupText}
      buttonText="확인" />
    )}
  </>
  )
}
export default SecuKitLogin
