import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./MainLayout.css?ver=1";
import "../../styles/input-default.css?ver=1"
import SideNavigation from "../../components/common/NavBar";
import ToggleFooter from "../../components/common/Footer/ToggleFooter?ver=2";
import MainSlider from "../../components/common/Slider/MainSlider";
import MainChat from "../../components/views/MainChat";
import SliderComponent from "../../components/common/Slider";


const MainLayout = () => {
  const navigate                          = useNavigate();
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

    //giwanNo는 스토리지에서 가져옴
    const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  return (
    <>
    <div className="main-layout" id='main-screen'>
      <div className='login-header' id='main-header'>
      <div className="logo">
      <img src={`${process.env.REACT_APP_BACK_BASE_URL}/resources/images/logo/logo_${giwan.giwanNo}.gif`} 
        alt="logo"
                 /* 서울디지털대학교, 동의대학교 로고 위치 수정 */
                 className={giwan.giwanNo === '021125' || giwan.giwanNo === '022609' ? 'move-logo' : ''}
                 />
        </div>
        <div className="logo-box" id="main-logo-box">
        <MainChat className="main-chat"/>
        <button className="hamburger" id='main-hamburger' onClick={toggleSideNav}>
        <FaBars size={'24px'}/>
        </button>
        </div>

      </div>

      <div className="menu-container">
      <div className="menu-buttons">
        <Link to ="/ec-select1"><button className="menu-button" id="btn-1">
          <img src="img/Main/issue.png" alt="" />         
          증명서 발급</button></Link>
          
        <Link to ="/basket-list"><button className="menu-button" id="btn-2">
        <img src="img/Main/basket.png" alt="basket" />          
          증명서 보관함</button></Link>

          <Link to ="/history-list"> <button className="menu-button" id="btn-4">
        <img src="img/Main/history.png" alt="history" />          
          발급내역</button></Link>

        <Link to ="/aut"><button className="menu-button" id="btn-3">
        <img src="img/Main/aut.png" alt="aut" />          
          진위확인</button></Link>

        <Link to ="/guide"> <button className="menu-button" id="btn-5">
        <img src="img/Main/guide.png" alt="guide" />    
        이용가이드      
        </button></Link>
        <Link to ="/customer-support"><button className="menu-button" id="btn-6">
        <img src="img/Main/custom.png" alt="voicei" />          
        고객지원</button></Link>
      </div>
      {/* <Link to ="/guide">
      <div className="banner">
      <img src="img/Main/banner.png" alt="banner" />          
      </div></Link> */}
      </div>
      <SliderComponent />

      {/* <div className="bottom-menu">
        <Link to='/main'>
        <button className="bottom-menu-button" >
        <img src="img/Main/home.png" alt="home" id="bottom-btn-img"/>          
          </button></Link>
          <Link to ="/guide">
       <button className="bottom-menu-button" >
        <img src="img/Main/book.png" alt="book" id="book-icon" />          
         </button></Link>
        <button className="bottom-menu-button">
        <img src="img/Main/plus.png" alt="plus" className="center-plus" onClick={() => goIssue()}/>          
          </button>
          <Link to ="/notice">
        <button className="bottom-menu-button">
        <img src="img/Main/alarm.png" alt="alarm" id="alarm-icon" className="alarm-icon"/>          
          </button></Link>
          <Link to="/setting"><button className="bottom-menu-button" > 
        <img src="img/Main/setting.png" alt="setting" id="bottom-btn-img"/>          
          </button></Link>
      </div> */}
     <ToggleFooter></ToggleFooter>
    <SideNavigation handleNav={toggleSideNav} isOpen={isSideNavOpen}/>
    </div>
    </>
  );
};

export default MainLayout;