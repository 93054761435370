import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./testChat.css"
import { ReactComponent as ChatbotIcon } from "../common/Svg/chatbot.svg";


const MainChat = (id)=> {   
    const [isInit, setIsInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    //이동하는거 구현
    // const navigate = useNavigate();
    // const location = useLocation();
    // const [position, setPosition] = useState({ x: `70%`, y: `90%` });
    // const handleTouchStart = (e) => {
    //     const touch = e.touches[0];
    //     const startX = touch.clientX;
    //     const startY = touch.clientY;
    
    //     const handleTouchMove = (e) => {
    //         const touch = e.touches[0];
    //         const newX = touch.clientX - startX;
    //         const newY = touch.clientY - startY;
    
    //         setPosition({ x: `${newX}%`, y: `${newY}%` });
    //     };
    
    //     const handleTouchEnd = () => {
    //         document.removeEventListener('touchmove', handleTouchMove);
    //         document.removeEventListener('touchend', handleTouchEnd);
    //     };
    
    //     document.addEventListener('touchmove', handleTouchMove);
    //     document.addEventListener('touchend', handleTouchEnd);
    // };

    const loadScript = async () => {
        // Check if the script has already been loaded
        if (document.getElementById("chatbot-script")) {
            setIsInit(true);
            return;
        }
            const script = document.createElement("script");
            script.src = "https://public-common-sdk.s3.ap-northeast-2.amazonaws.com/sdk/cloud/Twc.plugin.js";
            script.id = "chatbot-script";

            script.onload = () => {
                if (window.Twc) {
                window.Twc('init', {
                    brandKey: "20230424045024IUpE-C6_EHQnA9t2o_GoiQ",
                    channelType: "scenario",
                    scenarioId: "MjkzNA==",
                    buttonOption: {
                        showLauncher: false,
                        zIndex: 10000,
                        bottom: 25,
                        right: 25
                    }
                });

                setIsInit(true);
            } else {
                console.error("Twc is not defined.");
            }
        };

        // Append the script to the body
        document.body.appendChild(script);
    };

    useEffect(() => {
        loadScript();
        setIsLoading(true);
    }, []);
        // 특정 패스에 따라 챗봇 위치 초기화
    //     if (
    //       location.pathname.includes("basket-list") ||
    //       location.pathname.includes("aut/early") ||
    //       location.pathname.includes("setting/terms") ||
    //       location.pathname.includes("setting/policy-terms") ||
    //       location.pathname.includes("setting/privacy") ||
    //       location.pathname.includes("basket-early")
    //     ) {
    //       setPosition({ x: `75%`, y: `83%` });
    //     } else {
    //       setPosition({ x: `70%`, y: `90%` });
    //     }
    //   }, [location.pathname]);


    const chatBot = () => {
        if (window.Twc && window.Twc.Chat) {
            window.Twc.Chat.open();
        } else {
            console.error("Twc.Chat is not defined.");
        }
    };
    
    return (
        <div className="chatbot-area">

        {isLoading && (
 <button
 className="chat-btn-main"
//  style={{ position: 'absolute', top: position.y, left: position.x }}
//  onTouchStart={handleTouchStart}
 id={id}
 onClick={(e) => chatBot(e)}
>
{/* <ChatbotIcon   className="chatbot-icon"/> */}
<img
      style={{ width: '70px' }}

            src="/img/common/chatbot-a.png"
            alt="Chatbot Icon"
            id="topnav-chat"
          />
</button>
      )}
    </div>
  );
};
export default MainChat;