import React, { useEffect, useRef, useState } from 'react';
import TopNavBar from '../../components/common/TopNavBar';
import MMBAKKO1 from './MMBAKKO1';
import MMBAFAX1 from './MMBAFAX1?ver=1';
import MMBAEML1 from './MMBAEML1';
import { MAP_DATA_Delivery } from './MapDataDelivery';
import { useRecoilState } from 'recoil';
import { deliveryState } from '../../recoil/deliveryState';
import { useLocation, useNavigate } from 'react-router-dom';
import PayIframe from '../../components/common/Modal/PayIframe';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import Loadings from '../../components/common/Loading';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import TwoButtonPopup from '../../components/common/Popup/TwoButtonPopup';

const MMBADEL = () => {
  const navigate                          = useNavigate();
  const { state }                         = useLocation();
  const { basket }                        = state;
  const [orderNo, setOrderNo]             = useState('');
  const [showPopup, setShowPopup]         = useState(false);
  const [popupText, setPopupText]         = useState('');
  const [isLoading, setIsLoading]         = useState(false);
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [isChecked, setIsChecked]         = useState(false); 
  const [showTwoPopup, setShowTwoPopup]   = useState(true);

  const [deliveryInfo, setDeliveryInfo] = useState({
    sendType      : '',
    paymentMethod : '',
    receiver      : '',
    destination   : '',
    phone         : '',
    receivePhone  : '',
    receiverFax   : '',
    purpose       : '',
    paymentAmount : '',
    korYN         : ''
  });
  const [content, setDeliveryrState]    = useRecoilState(deliveryState);
  
  //이전 값 저장
  const prevOrderNoRef  = useRef(orderNo); 
  const deliveryInfoRef = useRef(deliveryInfo);
    
  const buttonValueSetting = e => {
    const { name } = e.target;
    setDeliveryrState(name);
  };
  
  // submit 방지
  const handleSubmit = (event) => {
    event.preventDefault(); 
  };

  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
    navigate('/basket-list');
};

  const handlePaymentClick = async () => {
    let response  = await connectAxios.get('/orderNo');
    let orderNo   = response.data.orderNo;
    
    setOrderNo(orderNo);
    prevOrderNoRef.current = orderNo;

    navigate('/ec-pay3' , { state : { deliveryInfo : deliveryInfo, basket : basket , paymentMethod: deliveryInfo.paymentAmount, orderNo: orderNo, prev : "send"}})
  };

  const changeInfo = (deliveryInfo) => {
    deliveryInfoRef.current = deliveryInfo;
    setDeliveryInfo(deliveryInfo);
  }

  const selectComponent = {
      // kko: <MMBAKKO1 basket={basket} changeInfo={changeInfo}/>,
      // eml: <MMBAEML1 basket={basket} changeInfo={changeInfo}/>,
      fax: <MMBAFAX1 basket={basket} changeInfo={changeInfo}/>
  };

  
  const isButtonDisabled =
  !isChecked ||
  // !deliveryInfo.receiver ||
  !deliveryInfo.destination ||
  deliveryInfo.destination.length < 9 ||
  !deliveryInfo.phone ||
  deliveryInfo.phone.length < 11 ||
  !deliveryInfo.receiverFax ||
  // deliveryInfo.receivePhone.length < 11 ||
  !deliveryInfo.purpose;
  
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const toggleContent = () => {
    setIsContentOpen(!isContentOpen);
  };

  const handleCancelNo = () => {
    setShowTwoPopup(false);
  
  };
  const handleYes = async () => {
    setShowTwoPopup(false);
    navigate(-1);

  };

  // 새로고침시 다시 랜더링
  useEffect(() => {
    // 브라우저 새로고침 이벤트 핸들러
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // 이 부분은 브라우저마다 다르게 동작할 수 있습니다.
      // 컴포넌트 상태 초기화
      setDeliveryrState('fax');
    };

    // 이벤트 핸들러 등록
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 핸들러 제거
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [setDeliveryrState]); // setContent을 의존성 배열에 추가


  return (
  <div className ="customer-screen">
    {isLoading && (
      <React.Fragment>
        <Loadings/>
      </React.Fragment>
    )}

    <form method="post" name="LGD_PAYINFO" id="LGD_PAYINFO" onSubmit={handleSubmit}> 
    <PayIframe/>
    </form>
    <TopNavBar/>
      <nav className="detail-tab-lists">
      {MAP_DATA_Delivery.map((data) => {
        return (
          <button
            className={
              content === data.name ? 'detail-tab-list-3 active' : 'detail-tab-list-3'
            }
            onClick={buttonValueSetting}
            name={data.name}
            key={data.id}
          >
            {data.text}
          </button>
        );
      })}
      </nav>
      {content && <div className='detail-tab-content' id='delivery-tab'>{selectComponent[content]}


      <div className='toggle' id='del-toggle'>
        <div className="toggle-box">
          <div className="toggle-top">
      <input type="checkbox" className='square-check' checked={isChecked} onChange={handleCheckboxChange} />
      <div className="toggle-header" onClick={toggleContent}>
          <p>  개인정보 수집 및 이용 동의  <span className='select-tip'> (필수)</span></p>
      </div>
      </div>
      <div  onClick={toggleContent}>
      {isContentOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</div></div>
      {isContentOpen && (
        <div className="toggle-body">
    한국정보인증(주)은 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.
        <br />        <br />
        
        개인정보 수집 및 이용에 대한 안내        <br />
        1. 목적 : 결제이력 보관 및 제증명 신청, 발급 장애 등이 발생할 경우 연락하기 위한 목적        <br />
        2. 항목 : 휴대폰 번호        <br />
        3. 보유기간 : 결제일로부터 5년(전자상거래 등에서의 소비자보호에 관한 법률에 따름)        <br />
        <br />
        위 개인정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부 시에는 증명서 발급이 제한될 수 있습니다.
       
        </div>
      )}
    </div>
    
    <ClickButtonBigA  
    onClick={() => handlePaymentClick()} 
    buttonText="결제 및 전송하기" 
    className="big-a-width"
    buttonId={buttonId}  
    disabled={isButtonDisabled} />
    </div>}

    {showPopup && (
      <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
      )}
        {showTwoPopup && (
    <TwoButtonPopup  
     onClick={handleYes}  
     onClick2={handleCancelNo}
     popupText="팩스 전송은 사본 전송(열람용)입니다." 
    //  popupText="전송 후에는 증명서 보관함에서 추가 전송 및 다운로드, 취소 및 환불이 불가하며," 
    // popupText2=" 발급내역에서 이용내역 확인이 가능합니다." 
     popupText2="전송 시, 증명서 취소 및 환불이 불가합니다." 
     buttonText="취소" 
     buttonText2="확인"/>
    )}
  </div>
);
};

export default MMBADEL;