import { useRef, useLayoutEffect } from 'react';

export const useInputLayout = () => {
  const listRef = useRef();

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      if (document.activeElement.tagName === "INPUT") {
        listRef.current.scrollIntoView({ block: "end" });
      }
    }

    window.addEventListener("resize", detectMobileKeyboard);

    return () => {
      window.removeEventListener("resize", detectMobileKeyboard);
    };
  }, []);
};