import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";



const MMBAKKO2 = () => {
  return (
    <div id='screen' className='finish-screen'>
      <React.Fragment>
      <div id='middle-top' className="middle-container">
        <div id='icon-container'>
      <FaCheckCircle size={100} color="#328AE6" id='icon'/>
      </div>
      <h3 className="middle-txt">카카오톡 전송이 완료되었습니다!</h3>
      <p className="small-txt">수신자에게 수신 여부를 확인해 주세요. </p>
      </div>
      <div className="home-button-container">
      <Link to='/main'><button className="home-btn">
            <span>홈으로 가기</span>
        </button></Link>
      <Link to='/basket-list'><button className="submit-btn" >
            <span>증명서 보관함으로 가기</span>
        </button></Link>

      </div>
      </React.Fragment>
    </div>
  );
}


export default MMBAKKO2; 
