import React, { useEffect, useState } from 'react';
import TopNavBar from '../../components/common/TopNavBar';
import { MAP_DATA_GUIDE} from './MapDataGuide';
import { useLocation } from 'react-router';
import MMGUCER from '../../components/views/Customer/MMGUCER';
import MMGUDEL from '../../components/views/Customer/MMGUDEL';


const MMGUINF = () => { 
  const [content, setContent] = useState('certi');
  const { prevContent }       = useLocation().state || {}; 

  const buttonValueSetting = e => {
    const { name } = e.target;
    setContent(name);
  };

  //등록 후 넘어왔을 경우 1:1상담으로 이동
  useEffect(() => {
    if (prevContent !== undefined){
      setContent(prevContent);
    }
  }, []);

  const selectComponent = {
      certi: <MMGUCER /> ,
      delivery: <MMGUDEL />,
  };


    return (
      <div className ="customer-screen">
      <TopNavBar/>
      <nav className="detail-tab-lists">
      {MAP_DATA_GUIDE.map((data) => {
        return (
          <button
            className={
              content === data.name ? 'detail-tab-list active' : 'detail-tab-list'
            }
            onClick={buttonValueSetting}
            name={data.name}
            key={data.id} 
            id='detail-tab-guide'
          >
            {data.text}
          </button>
        );
      }
      )
      }
    </nav>
    {content && <div className='detail-tab-content '>{selectComponent[content]}</div>}
  </div>
);
};

export default MMGUINF;

