import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import TopNavBar from '../../components/common/TopNavBar';
import { connectAxios } from '../../module/connectAxios';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';

const MMVAAUT = () => {
  const navigate                          = useNavigate();
  const [inputValue, setInputValue]       = useState('');
  const [showPopup, setShowPopup]         = useState(false);
  const [verifyDate, setVerifyDate]       = useState('');
  const [isChkGiwan, setIsChkGiwan]       = useState('');
  const [isPageVisible, setIsPageVisible] = useState(false);

  const handleInputChange = (event) => {
  const value = event.target.value;
  
  // 16자 이상 입력 방지
  if (value.length > 16) {
    // 입력값이 16자를 초과하면 아무 작업도 하지 않음
    return;
  }
  
  // 특수문자 체크 정규식
  const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
  
  // 특수문자가 입력되었는지 검사
  if (specialCharRegex.test(value)) {
    // 특수문자가 포함된 경우 아무 작업도 하지 않음
    return;
  }

  // 입력값을 설정
  setInputValue(value);
};

  const handleVerification = async () => {
      const response = await connectAxios.get('/verify',{
        params: {
          minDocNo: inputValue,

        }
      });

      let response_data = response.data;
      if(response_data.resultCode === "200"){
        
        navigate("/aut/early", {
          state : {
            minNo   : response_data.minNo
          }
        });
      } else if(response_data.resultCode === "201"){
        setShowPopup(true);
      }
  };

const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'))

useEffect(() => {
    const getVerifyDate = async () => {
        //M-08. 진위확인 기간 조회
        const response = await connectAxios.get("/verify-date", {
            params: {
              giwanNo : giwan.giwanNo
            }
        });

        if(response.data.resultCode === "200"){
          setVerifyDate(response.data.verifyDate);
        }
    };

    if(giwan){
      getVerifyDate();
    } else {
      setIsPageVisible(true);
    }
}, []);

useEffect(() => {
  if(verifyDate !== null && verifyDate !== '' ){
    setIsChkGiwan(true);
    setIsPageVisible(true);
  }
}, [verifyDate])


  const isButtonDisabled = !inputValue 
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
  };

  return (
    <div>
      <TopNavBar />
      { isPageVisible && (<div>
      <div className="section-title">    
      <h2 className='foam-title' id='margin37a'>증명서 진위 확인</h2>
        </div>
        <div className="pagetip" >
        <p id='left-line'>상단 문서확인번호로 실제 증명서와 대조 가능합니다.</p>
        {isChkGiwan && (<p className='top05' id='left-line'> 증명서 진위확인은 발급일로부터 {verifyDate}일 확인 가능합니다.</p>)}
   
     
        </div>

      <div  className="aut-screen">
      <div className="section-containers" >
        <p className="section-titles">증명서 번호 조회</p>
        <label htmlFor="input31" className='blind'> 증명서 번호 조회</label>
        <input type="text" value={inputValue} onChange={handleInputChange} id='input31' placeholder="증명서 번호를 (-) 없이 입력하세요." />
        
        <div className="guide-line-area">
          <img src="/img/aut/check.gif" alt="check" className='aut-img' />
    {/* <h2 className="guide-txt">[유의사항] </h2>

    <ul className='guide-line-list'>
        <li className='guide-line'><span>•</span><p> 증명서 상단에 기재된 문서확인번호를 입력하여, 실제 증명서와 비교 대조 해볼 수 있습니다.</p></li>
        {isChkGiwan && (<li className='guide-line'><span>•</span> <p> 증명서 진위확인은 발급일로부터 {verifyDate}일까지 가능합니다.</p></li>)}
    </ul> */}
    </div>
</div>

        <ClickButtonBig 
        onClick={handleVerification} 
        buttonText="확인하기"
             disabled={isButtonDisabled} 
             buttonId={buttonId} />
      </div>
      </div>
      )}
      {showPopup && (
      <OneButtonPopup onClick={handleCancel} popupText="잘못된 문서번호입니다." buttonText="확인"/>
      )}
    </div>
  );
};

export default MMVAAUT;