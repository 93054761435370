import axios , { CancelToken } from 'axios';
import { getEncryptParams } from './MAES256';
import { useNavigate } from 'react-router-dom';

export const connectAxios = axios.create({
  baseURL : process.env.REACT_APP_BACK_BASE_URL + '/mobile',
  timeout : 180000, //3분
  withCredentials : true,
  crossDomain : true
});


// 요청 인터셉터
connectAxios.interceptors.request.use(

  function(config){

    // 취소 토큰을 config에 추가
    config.cancelToken = CancelToken.source().token;

    // 요청 파라미터 암호화
    if (config.params !== undefined){
      const encryptParams = getEncryptParams(config.params);
      config.params = encryptParams;
    }

    return config;
  },
  function(error){
    //요청 오류가 있는 경우
    if(process.env.REACT_APP_DEBUG === 'Y'){
      console.log(error);
    }
  },
);

//응답 인터셉터
connectAxios.interceptors.response.use(

  //통신 성공
  function(response){
    //2XX 코드일 경우
    try{
      const resultCd = response.data.resultCode.substring(0, 1);
      if(resultCd === '2'){
        //개발시에 API 호출시 콘솔 확인 가능
        if(process.env.REACT_APP_DEBUG === 'Y'){
          console.log('api 연동');
        }
        return response;
      
      } else if(resultCd === '3'){
        alert('다시 로그인 해주세요.');
        sessionStorage.removeItem("userInfo");
        window.location.href=`${process.env.REACT_APP_FRONT_BASE_URL}/login`;

      //4XX 코드일 경우
      } else if(resultCd === '4'){
        //개발시에만 에러 콘솔 확인 가능
        if(process.env.REACT_APP_DEBUG === 'Y'){
          console.log(response.data);
        }
      } else {
        if(process.env.REACT_APP_DEBUG === 'Y'){
          console.log(response.data);
        }
        
        if(window.location.pathname.includes('/ec-pay/return')){
          alert('오류가 발생했습니다.');
          window.location.href=`${process.env.REACT_APP_FRONT_BASE_URL}/main`;
        } else {
          console.log('서버 오류가 발생했습니다.');
        }
      }
    }catch (error) {
      console.log('서버 오류가 발생했습니다.');
    }
  },

  //통신 실패
  function(error){
    //개발시에만 에러 콘솔 확인 가능  
    if(process.env.REACT_APP_DEBUG === 'Y'){ 
      console.log(error);
    }

    console.log('서버 오류가 발생했습니다.');
    },
  );