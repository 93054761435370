import { useEffect } from "react";

const SecukitIndex = () => {

  function parseURLParameters() {
    var params = window.location.search.substr(window.location.search.indexOf('?') + 1).split('&');
    var res = {};
    var temp;

    for (var i = 0; i < params.length; i++) {
      if (!params[i].indexOf('=')) continue;

      temp = params[i].split('=');
      res[temp[0]] = decodeURI(temp[1]);
    }

    return res;
  }

 useEffect(()=>{
  if(!window.opener) window.opener = window.open("", "secukit-one");
  
  var params = parseURLParameters();
  var code = params.resultCd ? params.resultCd : 'UKNOWN ERROR';
  var message = params.resultMsg ? params.resultMsg : '알 수 없는 오류'  ;

  if (!params.serverTxId){
    window.opener.secukit.context.pro.getResultStatusCallback(undefined, {
      code: decodeURIComponent(code),
      message: decodeURIComponent(message)
    });
  }
  else window.opener.secukit.context.pro.getResultStatusCallback(params);

  window.close();
 });

  <body id="page-top">
  </body>
};

export default SecukitIndex;