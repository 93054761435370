import React, { useEffect, useState } from 'react';
import LogoTop from '../../components/common/TopNavBar/LogoTop';
import ClickButtonSmall from '../../components/common/Buttons/ClickButtonSmall.';
import { useLocation } from 'react-router-dom';
import { connectAxios } from '../../module/connectAxios';
import { useTranslation } from 'react-i18next';


const DownloadLink = () => {
    const [certificate, setCertificate]         = useState({});
    const [isPageVisible, setIsPageVisible]     = useState(false);
    const { t, i18n }                           = useTranslation();
    const { state }                             = useLocation();
    const { minNo, giwanNo, userName }          = state;
    const { lang }                              = state;

    useEffect(()=>{
        if(lang === "en"){
          i18n.changeLanguage("en");
        }
    },[]);

    const handleButtonClick = async () => {
        //B-03. 증명서 다운로드
        const url = `${process.env.REACT_APP_BACK_BASE_URL}/mobile/download?giwanNo=${giwanNo}&userName=${userName}&minNo=${minNo}&korYN=${certificate.korYN}`;
        
        const download = document.createElement('a');
        
        download.href = url;
        download.click();
    };
    
    useEffect(() => {
        const getMinInfo = async () => {
            //B-05. 증명서 정보 조회
            const response = await connectAxios.get("/minInfo", {
                params: {
                    minNo : minNo,
                    lang  : lang
                }
            });

            if(response.data.resultCode === "200"){
                setCertificate(response.data);
                setIsPageVisible(true);
            }
        };

        getMinInfo();
        
    }, []);

    return (
    <div>
        {isPageVisible && (
            <>
            <LogoTop/>
            <div className="down-screen">
            <h2 className='foam-title'>{t("cert.down")}</h2>
            <div className="section-containers">
                <div  
                className="download-card"
                >
                <div className="card-bodys">
                <div className="card-top">
                    <p className="card-text-01">{certificate.jobName}</p>
                    <h5 className="card-tap-btn" id="brue-tap-nomargin">PDF</h5>      
                </div>
                    <p className="card-text-02">{certificate.giwanName}</p>
                    <p className="card-text-02">{certificate.minRegDate}</p>
                    <ClickButtonSmall onClick={handleButtonClick} buttonText={t("down")} />
                </div>
    
            </div>
            <div className="guide-line-area">
            <h2 className="guide-txt">[{t("caution")}] </h2>
    
            <ul className='guide-line-list'>
            <li className='guide-line'><span>•</span><p>{t("cert.msg3")}</p></li>
                <li className='guide-line'><span>•</span> <p>{t("cert.msg4")}</p></li>
            </ul>
            <h2 className="guide-txt">[{t("down.path")}] </h2>
            <ul className='guide-line-list'>
                <li className='guide-line'><span>•</span><p>{t("cert.msg5")}</p></li>
                <li className='guide-line'><span>•</span> <p>{t("cert.msg6")}</p></li>
            </ul>
            </div>
    
            </div>
            <div className="address-containers">
            <address className='company-add'>
            Copyright ⓒ 2023. KICA inc. All rights reserved.
            </address></div>
                </div>
            </>
        )}
        </div>
    );
};

export default DownloadLink;