import React, { useState,useEffect  } from 'react';
import { useNavigate } from "react-router-dom";
import TopNavBar from '../../common/TopNavBar';
import ClickButtonBig from '../../common/Buttons/CllickButtonBig';
import { connectAxios } from '../../../module/connectAxios';
import OneButtonPopup from '../../common/Popup/OneButtonPopup';
import ClickButtonBigA from '../../common/Buttons/CllickButtonBigA';

const MMQAWRT = () => {
  const navigate                              = useNavigate();

  const [title, setTitle]                     = useState('');
  const [category, setCategory]               = useState('1');
  const [subCategory, setSubCategory]         = useState('1');
  const [content, setContent]                 = useState('');
  const [showPopup, setShowPopup]             = useState(false);
  const [scList, setScList]                   = useState([]);
  const [tcList, setTcList]                   = useState([]);
  const [isLoading, setIsLoading]             = useState(true);
  const [error, setError]                     = useState(null);
  const [isPageVisible, setIsPageVisible]     = useState(false);

  const user    = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  useEffect(() => {
    const fetchTcList = async () => {
      try {
        const response = await connectAxios.get('/qna-title');
        const { resultCode, tcList } = response.data;

        if (resultCode === '200') {
          const subCategory = tcList.find(item => item.tcCode === '1');
          setTcList(tcList);
          setScList(subCategory.scList);
          setIsPageVisible(true); //페이지 띄우기
        } else {
          // 실패 처리
          setError('서버 오류가 발생했습니다.');
        }

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setError('데이터를 불러오는 중에 오류가 발생했습니다.');
        setIsLoading(false);
      }
    };

    fetchTcList();
  }, []);

 
  const handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);

    const subCategory = tcList.find(item => item.tcCode === e.target.value);

    setScList(subCategory.scList);
  };

  const handleSubCategoryChange = (e) => {
    setSubCategory(e.target.value);
  };

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    
    // 특수문자와 숫자, 영문자를 모두 제거하여 순수한 한글만 추출
    const sanitizedValue = inputValue.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9!?., ㆍᆢ]/g, '');

  
    // 한글은 최대 11자만 입력되도록 처리
    const maxLength = 40;
  
    // 입력된 글자수가 초과되면 초과된 부분은 자르기
    const truncatedValue = sanitizedValue.length <= maxLength ? sanitizedValue : sanitizedValue.slice(0, maxLength);
  
    // 수정된 값으로 state 업데이트
    setTitle(truncatedValue);
  };

  const handleContentChange = (e) => {
    const inputValue = e.target.value;
    
    // 특수문자와 숫자, 영문자를 모두 제거하여 순수한 한글만 추출
    const sanitizedValue = inputValue.replace(/[^(가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9\-.,!? ㆍᆢ)]/gi, '');


  
    // 한글은 최대 11자만 입력되도록 처리
    const maxLength = 500;
  
    // 입력된 글자수가 초과되면 초과된 부분은 자르기
    const truncatedValue = sanitizedValue.length <= maxLength ? sanitizedValue : sanitizedValue.slice(0, maxLength);
  
    // 수정된 값으로 state 업데이트
    setContent(truncatedValue);
  };


  const handleSubmit = async () => {
    try {
      const response = await connectAxios.post("/qna", {
        tpid      : user.tpid,
        title     : title,
        scCode    : subCategory,
        tcCode    : category,
        contents  : content,
        giwanNo   : giwan.giwanNo,
        giwanName : giwan.giwanName,
        userName  : user.userName,
        userMail  : user.userMail
      })

      if(response.data.resultCode === "200"){
        setShowPopup(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const isButtonDisabled = !title || !content ||!category ||!subCategory;
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';


  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
    navigate('/customer-support', { state : { prevContent : 'consultation'}});
  };
  
  return (
    <div>
      <TopNavBar />
      {isPageVisible && (
        <React.Fragment>
          <div className="customer-screens">
        <div className="section-title">
          <h2 className="foam-title">1:1 상담 작성</h2>
        </div>

        <div className="consult-write">
          <label htmlFor="title" id="middle-label">
            제목
          </label>
          <input type="text"   maxLength={20} id="middle" value={title} onChange={handleTitleChange} />

          <label htmlFor="category">대분류</label>
          <select id="category" className="dropdown option-box" value={category} onChange={handleCategoryChange}>
            {isLoading ? (
              <option></option>
            ) : error ? (
              <option>데이터를 불러오는 중에 오류가 발생했습니다.</option>
            ) : (
              tcList.map((tc) => (
                <option key={tc.tcCode} value={tc.tcCode}>
                  {tc.tcTitle}
                </option>
              ))
            )}
          </select>

          <label htmlFor="subCategory">소분류</label>
          <select id="subCategory" className="dropdown option-box" value={subCategory} onChange={handleSubCategoryChange}>
            {isLoading ? (
              <option>대분류를 선택해주세요.</option>
            ) : error ? (
              <option>대분류를 선택해주세요.</option>
            ) : (
              scList.map((sc) => (
                <option key={sc.scCode} value={sc.scCode}>
                  {sc.scTitle}
                </option>
              ))
            )}
          </select>

          <label htmlFor="content">상세내용</label>
          <textarea
            id="content"
            name="diary"
            maxLength={500}
            value={content}
            onChange={handleContentChange}
            cols="30"
            rows="10"
          ></textarea>
        </div>
       
        <ClickButtonBigA onClick={handleSubmit} 
        buttonText="등록하기" 
        buttonId={buttonId}
        className="change-btn-1"
        disabled={isButtonDisabled}/>

        {showPopup && (
        <OneButtonPopup onClick={handleCancel} popupText="상담글이 성공적으로 등록되었습니다." buttonText="확인"/>
        )}
         </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default MMQAWRT;