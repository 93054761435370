import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import LogoTop from '../../components/common/TopNavBar/LogoTop';
import { connectAxios } from '../../module/connectAxios';
import { useTranslation } from 'react-i18next';
import Loadings from './../../components/common/Loading/index';
import { getDecryptParams } from '../../module/MAES256';

const KkoLink = () => {
  const navigate                    = useNavigate();
  const { t, i18n }                 = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const params      = new URLSearchParams(window.location.search);
  const data        = getDecryptParams(params.get("data"));
  const minNo       = data["minNo"];
  const userName    = decodeURIComponent(data["userName"]);
  const giwanNo     = data["giwanNo"];
  const lang        = data["lang"];

  useEffect(()=>{
    if(lang === "en"){
      i18n.changeLanguage("en");
    }
  },[]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    // 영어와 숫자만 허용하는 정규식
    const regex = /^[a-zA-Z0-9]+$/;
  
    // 입력값이 정규식을 만족하는지 검사
    if (!regex.test(value)) {
      // 정규식을 만족하지 않는 경우, 유효하지 않은 문자를 제거하여 새로운 값을 설정
      const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
      setInputValue(sanitizedValue);
  
      // 팝업 메시지 또는 다른 처리를 원하면 여기에 추가
      // ...
    } else {
      // 정규식을 만족하는 경우, 그대로 입력값을 설정
      setInputValue(value);
    }
  };

  const handleButtonClick = async (page) => {
    const response  = await connectAxios.get("/receivePas", {
      params : {
        receiveCode : inputValue,
        minNo       : minNo
      }
    });

    let resultCode = response.data.resultCode;
    if(resultCode === "200"){
      navigate(`/${page}`, { 
        state : { 
          minNo     : minNo,
          userName  : userName,
          giwanNo   : giwanNo,
          lang      : lang
        } 
      });
    } else if (resultCode === "201"){
      alert(`${t("cert.msg2")}`);
    }
  };

  const isButtonDisabled = !inputValue 
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

  return (
    <div className='hakbun-check'>
        <LogoTop/>
        <div className="down-screen">
   <h2 className='foam-title'>{t("cert.down")}</h2>
      <div className="section-containers">
     <p className="join-form-text">{t("cert.input")}</p>
<label htmlFor="input32" className='blind'>{t("cert.input")}</label>
      <input type="text" value={inputValue}  id='input32' onChange={handleInputChange} />
      <p className='tip-text'>* {t("cert.msg1")}</p>
            
      <ClickButtonBig onClick={()=> handleButtonClick('download/down')} buttonText={t("button.submit")} 
           disabled={isButtonDisabled} 
             buttonId={buttonId}
              />
    </div>
    <div className="address-containers">
      <address className='company-add'>
        ⓒ 2023. KICA inc. All rights reserved.
      </address></div>
    </div>
    </div>
  );
  }


  export default KkoLink;