import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import TopNavBar from '../../components/common/TopNavBar';


const PrivacyPolicy = () => {
    const navigate = useNavigate();
		const [isShow, setIsShow] = useState(false);

	const isAuthenticated    = sessionStorage.getItem("userInfo") !== null && sessionStorage.getItem("giwanInfo") !== null ? true : false;
 
	const handleButtonClick = () => {
		if (isAuthenticated) {
		  // 로그인 상태에서 버튼을 클릭한 경우
		  navigate(-1);
		} else {
		  // 비로그인 상태에서 버튼을 클릭한 경우
		  window.history.back(); // 뒤로 가기
		}
	  };

		useEffect(() => {
			const currentDate   = new Date();
			const targetDate    = new Date(2024,4,1);
			
			if (currentDate < targetDate) {
					setIsShow(true);
			}
	
		},[]);

    return (
    <div>
            <TopNavBar/>
            <div className="section-screen">
                  <h2 className="foam-title" > 개인정보 처리방침</h2>
        <div className="policy">
		<div id="rcontents">
			<ol class="marginTop20">
				<li >
					<p>
						"한국정보인증(주)"은 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
					</p>
				</li>
			</ol>
			<br/><br/>
			<p>
				<span class="named">
					제1조 (개인정보의 처리 목적)
				</span>
			</p>
			<p class="marginTop20">"한국정보인증(주)"은 다음 각 호에서 열거한 목적을 위하여 최소한의 개인정보를 처리하고 있습니다. 
			처리한 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 '개인정보 보호법' 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행하고 있습니다.</p>
		
				
					
					<p class="marginTop10">1. 홈페이지 회원 가입 및 관리</p>
					<p >회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별∙인증, 회원자격 유지∙관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지∙통지, 고충처리 등을 목적으로 개인정보를 처리합니다. <br/> 본인인증 &gt; 회원등록 [필수 수집항목 : 성명, 이메일(=ID), 비밀번호, 연락처(휴대폰)]</p>
					<p class="marginTop10">2. 증명서 서비스 제공</p>
					<p >이용자의 신원 및 민원사항 확인, 사실 조사를 위한 연락, 처리결과 통지, 제증명 신청발급 장애 등이 발생 할 경우 연락, 증명서 전송 서비스 이용시 수신자·송신자 명 기재, 메일/팩스 전송 내역을 SMS로 전송, 증명서 우편발급 서비스 이용시 우편물 전달 주소기록, 문제 사항 발생 시 연락, 수취인 연락 등</p>
					<p class="marginTop10">3. 자동수집</p>
					<p >서비스 이용과정에서 원활한 증명서 발급 및 민원처리를 위해 자동으로 정보 생성되어 수집</p><br />					
			<p>
				<span class="named">
					제2조(처리하는 개인정보 항목)
				</span>
				
			</p>
			
					<p class="marginTop10">"한국정보인증(주)"이 처리하는 개인정보 항목은 다음 각 호와 같습니다.</p>
					
					<dl class="marginTop10">
						<dt>1. 회원 정보 </dt>	
						<dd>- 이름, 생년월일, CI, DI : 본인인증을 통해 수집</dd>					
						<dd>- 비밀번호, 로그인ID(이메일), 연락처 : 사용자 입력을 통해 수집</dd>					
						<dd  >(단, 연락처는 휴대폰 본인인증 이용 시, 본인인증을 통해 수집)</dd>
						<dt>2. 비회원 정보 </dt>	
						<dd>성명·생년월일·본인인증정보(CI/DI)(본인인증을 통하여 자동수집) </dd>					
						<dd  >로그인ID(이메일), 비밀번호, 성명·생년월일·본인인증정보(CI/DI)(본인인증을 통하여 자동수집), 연락처 </dd>
						<dt>3. 본인인증 </dt>
						<dd>가. 회원 등록</dd>					
						<dd  >성명, 이메일(=ID), 비밀번호, 연락처(휴대폰) </dd>
						<dd>나. 비회원</dd>
						<dd  >별도로 저장하지 않음</dd>
						<dd>다. 간편인증</dd>
						<dd  >이름, 생년월일, 휴대폰번호</dd>
						<dt>4. 증명서 전송(메일/팩스) 서비스 </dt>					
						<dd>가. 메일</dd>					
						<dd  >송신자 연락처, 수신자명 또는 수신기관명, 수신자 이메일, 수신자 연락처 </dd>
						<dd>나. 팩스</dd>					
						<dd  >송신자연락처, 수신자 연락처, 수신자 팩스번호</dd>
						<dt>5. 증명서 우편발급 서비스 </dt>	
						<dd>수취인 명, 수취인 연락처, 수령 주소, 긴급연락처 </dd>		
						<dt>6. 번역/공증서비스</dt>	
						<dd>수취인 명, 수취인 연락처, 수령 주소, 긴급연락처</dd>		
						<dt>7. 관리자 서비스 </dt>	
						<dd>가. 대학교 관리자 </dd>
						<dd  >- ID, 비밀번호, 이름, 연락처, 접근IP정보</dd>
						<dd>나. 첨부서비스 관리자 </dd>
						<dd  >- ID, 비밀번호, 이름, 연락처, 접근IP정보</dd>
						<dt>8. 자동 수집항목 </dt>	
						<dd>서비스 이용기록, 접속 로그, 결제기록, 출력IP정보, 세션 </dd>		
					</dl><br />
					
					<p>
					<span class="named">
						제3조 (개인정보의 처리 및 보유 기간)
					</span>
				
					</p>
					
					<p class="marginTop10">"한국정보인증(주)"은 법령의 규정과 정보 주체의 동의에 의해서만 최소한의 개인정보를 수집·보유하며, 또한, 보유하고 있는 회원의 개인정보를 관계 법령에 따라 적법하고 적정하게 처리하여 정보주체의 권익이 부당하게 침해받지 않도록 노력할 것입니다. 개인정보 보유 기간은 다음 각 호와 같습니다.</p>
					
					<dl class="marginTop10">
						<dt>1. 계약 또는 청약철회 등에 관한 기록</dt>	
						<dt>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</dt>					
						<dt>보존 기간 : 5년</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>2. 대금결제 및 재화 등의 공급에 관한 기록</dt>	
						<dt>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</dt>					
						<dt>보존 기간 : 5년</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>3. 전자금융 거래에 관한 기록</dt>	
						<dt>보존 이유 : 전자금융거래법</dt>					
						<dt>보존 기간 : 5년</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>4. 소비자의 불만 또는 분쟁처리에 관한 기록</dt>	
						<dt>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</dt>					
						<dt>보존 기간 : 3년</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>5. 회원가입에 관한 개인정보 기록</dt>	
						<dt>보존 이유 : 회원가입, 회원제 서비스 이용 및 제한적 본인확인 절차에 따른 본인확인, 가입 의사 확인, 원활한 고객 상담, 회원 자격 유지 및 관리, 분쟁 조정을 위한 기록보존, 각종 고지 및 통지 등</dt>					
						<dt>보존 기간 : 회원 탈퇴시 까지</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>6. 증명서 서비스에 관한 개인정보 기록</dt>	
						<dt>보존 이유 : 이용자의 신원 및 민원사항 확인, 사실 조사를 위한 연락, 처리결과 통지, 제증명 신청·발급 장애 등이 발생 할 경우 연락, 증명서 전송 서비스 이용시 수신자·송신자 명 기재, 메일/팩스 전송 내역을 SMS로 전송, 증명서 우편발급 서비스 이용시 우편물 전달 주소기록, 문제 사항 발생 시 연락, 수취인 연락 등</dt>					
						<dt>보존 기간 : 1년</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>7. 서비스 이용기록</dt>	
						<dt>보존 이유 : 원본 문서 인증 효력기간</dt>					
						<dt>보존 기간 : 3년</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>8. 로그인 기록</dt>	
						<dt>보존이유 : 통신비밀보호법</dt>					
						<dt>보존 기간 : 3개월</dt>
						
					</dl>
					
					<dl class="marginTop10">
						<dt>9. 원본 증명서 파일</dt>	
						<dt>보존 이유 : 증명서 유효기간 동안 진위확인 서비스 제공</dt>					
						<dt>보존 기간 : 6개월</dt>
						
					</dl><br/>
{/* 					
<!-- 					<dl class="marginTop10"> -->
<!-- 						<dt>8. ㈜디지털존 회원정보</dt>	 -->
<!-- 						<dt>보존이유: 회원가입 및 관리</dt>					 -->
<!-- 						<dt>보존 기간: 회원 탈퇴시까지</dt> -->
						
<!-- 					</dl> --> */}

					<p>
					<span class="named">
						제4조(개인정보의 제3자 제공에 관한 사항)
					</span>
				
					</p>
					<p class="marginTop10">“한국정보인증(주)”은 이용자의 개인정보를 “제1조(개인정보의 처리목적)”에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
					
					<dl class="marginTop10">
						<dt>- 이용자들이 사전에 동의한 경우</dt>	
						<dt>- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</dt>	
					</dl>
					
					<dl class="marginTop10">
						<dt>보존 항목 : 이름, 비밀번호, 연락처, 이메일, 생년월일</dt>	
						<dt>보존 근거 : 제증명 발급의 지속적인 서비스를 위해</dt>					
						<dt>보존 기간 : 직접 탈퇴 시 까지 보존</dt>
						
					</dl><br />
					
					<p>
					<span class="named">
						제5조(개인정보 처리의 위탁에 관한 사항)
					</span>
				
					</p>
					<p class="marginTop10">"한국정보인증(주)"은 원활한 개인정보 업무처리 서비스를 위해 다음과 같이 개인정보 처리업무를 위탁 운영하고 있으며, 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적ㆍ관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리ㆍ감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 또한 위탁업무의 내용이나 수탁자가 변경될 경우에는 본 개인정보 처리방침을 통하여 공개하고 있습니다. </p>
					
	<br /><br />				
<table className='table-css' >

<tr className='table-css-tr'> 
		<td className='table-css-td'>수탁업체명</td> 
		<td className='table-css-td' >위탁 업무 내용</td> 
	</tr> 
<tr>
	<td>NICE 평가정보(주)</td>
	<td >- 아이핀 인증<br />- 휴대폰 인증</td>
</tr>
<tr>
	<td>토스페이먼츠(주)</td>
	<td>- 휴대폰/신용카드 결제처리</td>
	

</tr>
 <tr>
	<td>(주)KG INICIS</td>
	<td>- 휴대폰/신용카드 결제처리<br/>- 카카오 알림톡 발송 </td>

</tr>
{isShow && (
		<>
		<tr> 
			<td>네이버</td> 
			<td>- 간편인증</td> 
		</tr>

		<tr> 
		<td>토스</td> 
		<td>- 간편인증</td> 
		</tr> 
		<tr> 
		<td>페이코</td> 
		<td>- 간편인증</td>
		</tr> 
	</>
	)}
	 
	 <tr> 
		<td>카카오톡</td> 
		<td>- 간편인증</td> 
	</tr> 
<tr>
	<td>LG U+</td>
	<td>- SMS 발송업무</td>

</tr>
{/* <!--  <tr> -->
<!-- 	<td>(주)한국데이터팜</td> -->
<!-- 	<td>-증명서 출력 및 우편 발송</td> -->
<!-- 	<td>위탁계약 종료시까지</td> -->
<!-- </tr> --> */}
 <tr>
	<td>KG모빌리언스</td>
	<td>- Mcash 결제서비스</td>
</tr>
 <tr>
	<td>마이더스소프트</td>
	<td>- 원격지원서비스(이지챗, 이지헬프)</td>
</tr>
 <tr>
	<td>후엠아이글로벌 주식회사</td>
	<td>- 번역/공증 업무</td>
</tr>
<tr>
	<td>오픈텍스트코리아</td>
	<td>- 팩스 발송업무</td>
</tr>
<tr>
	<td>김앤김콜센터</td>
	<td>- 콜센터 담당업무</td>
</tr>
{/* <!-- <tr> -->
<!-- 	<td>라온화이트햇</td> -->
<!-- 	<td>-간편인증</td> -->
<!-- </tr> --> */}

</table>
	<br /><br />				
					
				<p>
					<span class="named">
						제6조(정보주체/이용자 및 그 행사방법에 관한 사항)
					</span>
				
				</p>					
					
				<p class="marginTop10">"이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며, 
				회사의 개인정보 처리에 동의하지 않는 경우 가입 해지(회원탈퇴)를 요청하실 수 있습니다. 
				다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다. 
				이용자의 개인정보 조회 및 수정을 위해서는 ‘정보변경’을 클릭하여 직접 열람, 정정 또는 탈퇴가 가능합니다.
				<br/> 
				- 홈페이지 탈퇴 : Home  &gt;  회원정보 수정 &gt;  비밀번호 확인  &gt;  탈퇴버튼 클릭 &gt;  즉시 탈퇴 처리<br/>
				- 이용자의 개인정보 조회 및 수정 : Home  &gt;  회원정보 수정 &gt;  비밀번호 확인 &gt;  수정 완료<br/>
				혹은 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다. 
				이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 
				또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
				 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "제3조 (개인정보의 처리 및 보유 기간)”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>	
				<br />	
				
				<p>
					<span class="named">
						제7조(개인정보 자동 수집 장치의 설치·운영 및 거부)
					</span>
				
				</p>	
					
				<dl class="marginTop10">
					<dt>① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</dt>
					<dt>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</dt><br/>
					<dt>1. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</dt>
					<dt>2. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</dt>
					<dt>3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</dt>
				</dl>
				<br />	
				<p>
					<span class="named">
						제8조(개인정보의 파기 절차 및 방법) 
					</span>
				
				</p>	
					
				<p class="marginTop10">
				"한국정보인증(주)"은 원칙적으로 개인정보 보존기간이 경과하거나, 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 
				다만, 다른 법령에 따라 보존하여야 하는 경우에는 그러하지 않을 수 있습니다. 파기 절차, 기한 및 방법은 다음과 같습니다. 
				
				</p>		
					
					
				<dl class="marginTop10">
						<dt>1. 파기절차</dt>
						<dt>이용자가 입력한 정보는 회원탈퇴 등 목적 달성 후 내부방침 및 기타 관련 법령에 따라 일정기간 저장된 후 파기됩니다. 
						이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아닌 다른 목적으로 이용되지 않습니다.</dt>
						<dd>가. 개인정보의 파기</dd>
						<dd>보유기간이 경과한 개인정보는 종료일로부터 지체 없이 파기합니다.</dd>
						<dd>나. 개인정보파일의 파기</dd>
						<dd>개인정보파일의 처리 목적 달성, 해당 서비스의 폐지, 
						사업의 종료 등 그 개인정보파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 그 개인정보파일을 파기합니다.</dd>
						<br />
						<dt>2. 파기방법</dt>
						<dt>전자적 파일 형태로 저장된 개인정보는 복구 또는 재생이 불가능하도록 기술적 방법으로 파기합니다.</dt>	
				
				</dl>	
					
				<p class="marginTop10">
				또한, 한국정보인증(주)은 '개인정보 유효기간제 '에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하고 있습니다. 
분리보관된 개인정보는 3년간 보관 후 지체없이 파기합니다.
				
				</p><br />	
					
					<span class="named">
						제9조(개인정보의 안전성 확보조치에 관한 사항)
					</span>	
					<p class="marginTop10">
				"한국정보인증(주)"은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. 
				
				</p><br />
				<dl class="marginTop10">
				<dt>1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등 </dt>
				<dt>2. 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 </dt>
				<dt>3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제  </dt>	
				</dl><br />	
					
				<span class="named">
						제10조(개인정보 보호책임자에 관한 사항)
					</span>	
					
				<p class="marginTop10">
				"한국정보인증(주)"은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다. 
				개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호 책임자 또는 개인정보보호 관리자에게 연락하여 주시기 바랍니다. 
				
				</p><br />	
				
				<dl class="marginTop10"> 
					<dt>▶ 개인정보 보호책임자</dt> 
					<dt>성명 : 김윤규 이사</dt> 
					<dt>직위 : 이사 (CISO, CPO)</dt> 
					<dt>연락처 : 02-360-3116</dt> 
					<dt>메일 : privacy@signgate.com</dt> 
					 
					<dt>※ 개인정보 보호 담당부서로 연결됩니다.  </dt>	 
					</dl> 
					 
					<dl class="marginTop10"> 
					<dt>▶개인정보 보호관리자</dt>
					<dt>성명 : 김성훈 팀장</dt> 
					<dt>소속 : 정보보호팀</dt> 
					<dt>연락처 : 02-360-3116</dt> 
					<dt>메일 : privacy@signgate.com</dt> 

					<dt>※ 개인정보 보호 담당부서로 연결됩니다.  </dt>	 
					</dl>	 

					<dl class="marginTop10"> 
					<dt>▶개인정보 열람청구 접수/처리</dt>
					<dt>성명 : 최승원 팀장</dt> 
					<dt>소속 : 증명사업팀</dt> 
					<dt>연락처 : 02-360-3196</dt> 
					<dt>메일 : csw@signgate.com</dt> 
					</dl>
					
					
				<p class="marginTop20"><span>회사의 귀책사유에 의하여 고객의 개인정보에 대한 침해사고가 발생하는 경우 회사는 관련 법령에 따라 책임을 부담합니다. 다만, 기술적인 보안조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 고객이 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다.</span></p>
					
				<p class="marginTop10">
				기타 개인정보 침해에 대한 신고나 상담이 필요한 경우 다음 각 호의 기관에 문의하시기 바랍니다.
				
				</p>	
					
				<dl class="marginTop10">
				<dt style={{  fontSize: '14px',  fontWeight: 'bold'}} >대검찰청<br /> (www.spo.go.kr / 국번없이 1301) </dt>
				<dt style={{  fontSize: '14px',  fontWeight: 'bold'}} >개인정보침해신고센터 <br />(http://privacy.kisa.or.kr / 국번없이 118) </dt>
				<dt style={{  fontSize: '14px',  fontWeight: 'bold'}} >개인정보분쟁조정위원회 <br />(http://www.kopico.go.kr / 1833-6972) </dt>
				<dt style={{  fontSize: '14px',  fontWeight: 'bold'}} >경찰청 사이버수사국 <br />(ecrm.police.go.kr / 국번없이 182) </dt>
		
				</dl>	
				<br />	
					
					<span class="named">
					제11조(개인정보 처리방침 변경)
					</span>		
					
				<p class="marginTop20">본 「개인정보 처리방침」은 2024.05.01부터 적용됩니다.</p>	
				<p class="marginTop20">개인정보 처리방침 버전 번호 : ver 10.0</p>		
				<p class="marginTop20">이전의 개인정보 처리방침은  PC 웹사이트(www.webminwon.com)에서 확인하실 수 있습니다. </p>		
				{/* <p class="marginTop20">▶ 이전 개인정보 처리방침은 ) (2007년 11월 09일 ~ 2023년 07월 03일)</p>		
					
			<a href="/wm1.0/jsp/function/etc/personal6.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 8.0 (2023.03.10 ~ 2023.07.03 적용)</a><br />					
			<a href="/wm1.0/jsp/function/etc/personal7_1.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 7.0 (2019.12.01 ~ 2023.03.10 적용)</a><br />
			<a href="/wm1.0/jsp/function/etc/personal6_1.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 6.0 (2015.01.19 ~ 2019.12.01 적용)</a><br />
			<a href="/wm1.0/jsp/function/etc/personal5.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 5.0 (2013.12.26 ~ 2015.01.19 적용)</a><br />
			<a href="/wm1.0/jsp/function/etc/personal4.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 4.0 (2013.02.01 ~ 2013.12.26 적용)</a><br />
			<a href="/wm1.0/jsp/function/etc/personal3.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 3.0 (2012.05.01 ~ 2013.02.01 적용)</a><br />
			<a href="/wm1.0/jsp/function/etc/personal2.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 2.0 (2012.01.25 ~ 2012.05.01 적용)</a><br />
			<a href="/wm1.0/jsp/function/etc/personal.html?ver=5" style={{
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0072bc',
    textDecoration: 'underline',
  }}>ver 1.0 (2007.11.09 ~ 2012.01.25 적용)</a>
			
 */}

			
		
					
				
		</div>
        </div>
        <ClickButtonBig 
		onClick={() => handleButtonClick()} 
		buttonText="닫기"
		buttonId="zindex" />
    </div>
    </div>


    );
};

export default PrivacyPolicy;