import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ClickButtonBig from '../../components/common/Buttons/CllickButtonBig';
import TopNavBar from '../../components/common/TopNavBar';
import ViewPDF from '../../components/views/Basket/ViewPDF';


const MMVAEAL = () => {
    const navigate          = useNavigate();
    const {minNo}           = useLocation().state;

    const handleButtonClick = () => {
        navigate(`/main`);
      };

    const user    = JSON.parse(sessionStorage.getItem('userInfo'));
    let urlTpid   = user ? user.tpid : "null";

    return (
    <div >
        <TopNavBar/>
        <div className="aut-screen">
        <h2 className="foam-title" id='margin37'>증명서 확인하기</h2>

        <div className="aut-area">
            <ViewPDF minNo={minNo} tpid={urlTpid}/>
        </div>
        <ClickButtonBig onClick={() => handleButtonClick()} buttonText="홈으로 가기" />
    </div>   
     </div>
    );
};

export default MMVAEAL;