import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { connectAxios } from '../../../module/connectAxios';
import ClickButtonBig from '../../common/Buttons/CllickButtonBig';
import OneButtonPopup from '../../common/Popup/OneButtonPopup';
import { useRecoilState } from 'recoil';
import { memberState } from '../../../recoil/memberState';
Modal.setAppElement('#root');

const MemberIdSearch = () => {
  const [showPopup, setShowPopup]       = useState(false);
  const [popupText, setPopupText]       = useState(null);
  const inputRef                        = useRef(null);
  const [searchInfo, setSearchInfo] = useState({
    name      : '',
    birthdate : ''
  });

  // const [content, setMemberState] = useRecoilState(memberState);


  //     // 새로고침시 다시 랜더링
  //     useEffect(() => {
  //       // 브라우저 새로고침 이벤트 핸들러
  //       const handleBeforeUnload = (event) => {
  //         event.preventDefault();
  //         event.returnValue = ''; // 이 부분은 브라우저마다 다르게 동작할 수 있습니다.
  //         // 컴포넌트 상태 초기화
  //         setMemberState('idsearch');
  //       };
    
  //       // 이벤트 핸들러 등록
  //       window.addEventListener('beforeunload', handleBeforeUnload);
    
  //       return () => {
  //         // 컴포넌트 언마운트 시 이벤트 핸들러 제거
  //         window.removeEventListener('beforeunload', handleBeforeUnload);
  //       };
  //     }, [setMemberState]); // setContent을 의존성 배열에 추가
    
  
  const handleSubmit = async () => {
    // Validations
    if (/[~!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]/.test(searchInfo.name)) {
      setPopupText('이름에 특수문자는 사용할 수 없습니다.');
      setShowPopup(true);
      return;
    }

    if (searchInfo.birthdate.length !== 6) {
      setPopupText('생년월일은 6자리를 입력해주세요.');
      setShowPopup(true);
      return;
    }
    
    let response = await connectAxios.get('/search-id-pw', {
      params : {
        type: 'I',
        userName: searchInfo.name,
        userBirth: searchInfo.birthdate
      }
    });
    
    let { resultCode, userMail } = response.data;

    if (resultCode === '200') {
      inputRef.current.focus();
      setPopupText(`ID : ${userMail}`);
    } else {
      setPopupText('일치하는 회원정보가 없습니다.');
    }
    setShowPopup(true);

  };

  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
  };

  const isButtonDisabled = !searchInfo.name || !searchInfo.birthdate;
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

  const handleSearchInfoChange = (e) => {
    const { name, value } = e.target;
    if (name === 'birthdate') {
      // 생년월일 입력값에서 숫자 이외의 문자를 제거하고, 최대 6글자로 제한합니다.
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
      setSearchInfo((prevInfo) => ({ ...prevInfo, [name]: sanitizedValue }));
    } else {
      setSearchInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
    }
  };

  return (
    <div className="search-container">
        <label htmlFor="input4">
          이름        </label>
          <input 
          id='input4'
          type="text" 
          className='search-input' 
          name='name'
           value={searchInfo.name} 
           onChange={(e) => handleSearchInfoChange(e)} 
           placeholder='홍길동'
           ref={inputRef}
           />

        <label htmlFor="input5">
          생년월일 </label>
          <input type="tel"
          id='input5' 
          name="birthdate" 
          value={searchInfo.birthdate} 
          onChange={(e) => handleSearchInfoChange(e)}  
          placeholder='YYMMDD' 
          ref={inputRef}

          />
       
        <ClickButtonBig 
        onClick={() => handleSubmit()} 
        buttonText="아이디 찾기" 
        disabled={isButtonDisabled} 
        buttonId={buttonId}
        />

        {showPopup && (
        <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
        )}
    </div>
  );
};

export default MemberIdSearch;